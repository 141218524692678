import type { OperationFilterParams } from "../interface"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

type SelectListAction =
  | {
      exclude_ids: Array<number>
      filters:
        | null
        | ({
            query?: string
          } & OperationFilterParams)
      edit: Record<string, string | number>
    }
  | {
      include_ids: Array<number>
      edit: Record<string, string | number>
    }
export const useOperationListEdit = () => {
  const { data, error, execute, isLoading } = useAxios(
    "/operations/batch-update",
    { method: "PATCH" },
    client,
    {
      immediate: false
    }
  )

  const editListOperation = (data: SelectListAction) =>
    execute("/operations/batch-update", { data })

  return {
    data,
    isLoadingUpdate: isLoading,
    error: useApiError(error),
    editListOperation
  }
}
