import { AxiosError, HttpStatusCode } from "axios"

export class ApiBadReqResponse extends Error {
  public static readonly STATUS = HttpStatusCode.BadRequest
  public readonly STATUS = HttpStatusCode.BadRequest

  constructor(
    public message: string,
    public errorList?: Record<string, Array<string>>,
    public errorArray?: Array<string>
  ) {
    super(message)
    this.name = "ApiBadReqResponse"
  }
}

export class ApiNotFoundResponse extends Error {
  public static readonly STATUS = HttpStatusCode.NotFound
  public readonly STATUS = HttpStatusCode.NotFound

  constructor(public message: string) {
    super(message)
    this.name = "ApiNotFoundResponse"
  }
}

export class ApiForbiddenResponse<T = unknown> extends Error {
  public static readonly STATUS = HttpStatusCode.Forbidden
  public readonly STATUS = HttpStatusCode.Forbidden

  constructor(
    public message: string,
    public readonly payload?: T
  ) {
    super(message)
    this.name = "ApiForbiddenResponse"
  }
}

export class ApiPayloadTooLargeResponse<T = unknown> extends Error {
  public static readonly STATUS = HttpStatusCode.PayloadTooLarge
  public readonly STATUS = HttpStatusCode.PayloadTooLarge

  constructor(
    public message: string = "Превышен допустимый размер файла",
    public readonly payload?: T
  ) {
    super(message)
    this.name = "ApiPayloadTooLargeResponse"
  }
}

export interface ApiErrorResponse extends Error {
  errors?: Record<string, Array<string>>
  message: string
  success: false
}

export const isApiError = (error: unknown): error is AxiosError =>
  typeof error === "object" && error !== null && "response" in error

export class ApiUnprocessableEntityResponse extends Error {
  public static readonly STATUS = HttpStatusCode.UnprocessableEntity
  public readonly STATUS = HttpStatusCode.UnprocessableEntity

  constructor(public message: string) {
    super(message)
    this.name = "ApiUnprocessableEntityResponse"
  }
}
