<script setup lang="ts">
import { computed, h, type PropType } from "vue"
import {
  DashOutlined,
  DeleteOutlined,
  EditOutlined,
  HolderOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined
} from "@ant-design/icons-vue"
import { noop } from "@vueuse/core"
import { push } from "notivue"

import { type OperationCategory } from "@/modules/operation-category"
import { DeleteConfirm, useAdoptable } from "@/package/ui-kit"
import { useOperationCategoriesStore, OperationCategoryTree } from "@/modules/operation-category"

import { useColumns } from "./use-columns"

const props = defineProps({
  element: {
    type: Object as PropType<OperationCategory>,
    required: true
  },

  expandedKeys: {
    type: Array as PropType<Array<number>>,
    required: true
  },

  selectedIdList: {
    type: Array as PropType<Array<number>>,
    required: true
  },

  group: {
    type: String,
    required: true
  }
})
const emit = defineEmits(["openDetail", "openEdit", "toggleExpand", "changeOrder", "changeSelect"])
const isAdoptable = useAdoptable()
const { columns } = useColumns()

const store = useOperationCategoriesStore()
const isExpanded = computed(() =>
  props.element.children?.length > 0 ? props.expandedKeys.includes(props.element.id) : true
)
const toggleExpand = () => {
  emit("toggleExpand", props.element.id)
}

const deleteAction = (record: OperationCategory) => {
  DeleteConfirm({
    content: "Вы действительно хотите безвозвратно удалить выбранные записи?",
    centered: true,
    onOk: () => {
      store
        .deleteOperationCategories(record)
        .then(() => {
          push.success({ message: "Удалено" })
        })
        .catch(noop)
    }
  })
}
</script>

<template>
  <div class="table-item">
    <div class="table-row" :class="{ selected: selectedIdList.includes(element.id) }">
      <div class="table-cell">
        <ACheckbox
          :checked="selectedIdList.includes(element.id)"
          @change="emit('changeSelect', element.id)"
        />
      </div>
      <template v-for="col in columns" :key="col.key">
        <div v-if="col.key === 'action'" class="table-cell">
          <ADropdown
            :overlay-style="{ zIndex: 100 }"
            placement="bottomRight"
            arrow
            :trigger="['hover']"
          >
            <AButton :icon="h(DashOutlined)" type="text" @click.stop />
            <template #overlay>
              <AMenu>
                <AMenuItem
                  :icon="h(EditOutlined)"
                  @click="() => emit('openEdit', (element as OperationCategory).id)"
                >
                  Редактировать
                </AMenuItem>
                <AMenuItem
                  danger
                  :disabled="store.isListDeleting"
                  :icon="h(DeleteOutlined)"
                  @click="() => deleteAction(element as OperationCategory)"
                >
                  Удалить
                </AMenuItem>
              </AMenu>
            </template>
          </ADropdown>
        </div>
        <div
          v-else
          class="table-cell"
          :class="{ 'table-cell--name': col.key === 'name' || col.key === 'id' }"
          @click="emit('openDetail', element.id)"
        >
          <div class="table-cell-drag drag-handle" :class="{ visible: isAdoptable }">
            <HolderOutlined />
          </div>
          <div class="table-cell-expand">
            <PlusSquareOutlined
              v-if="!isExpanded && element.children?.length > 0"
              @click.stop="toggleExpand"
            />
            <MinusSquareOutlined
              v-if="isExpanded && element.children?.length > 0"
              @click.stop="toggleExpand"
            />
          </div>
          <div class="table-text">{{ element.name }}</div>
        </div>
      </template>
    </div>
    <template v-if="isExpanded">
      <OperationCategoryTree
        class="operations-tree__child"
        :list="element.children"
        :expanded-keys="expandedKeys"
        :selected-id-list="selectedIdList"
        :group="group"
        @toggleExpand="emit('toggleExpand', $event)"
        @openDetail="emit('openDetail', $event)"
        @openEdit="emit('openEdit', $event)"
        @changeOrder="emit('changeOrder')"
        @changeSelect="emit('changeSelect', $event)"
      />
    </template>
  </div>
</template>

<style scoped>
.table-item {
  & .table-item {
    padding-left: 30px;
  }
}
.table-row {
  display: grid;
  grid-template-columns: 32px calc(100% - 32px);
  position: relative;

  @media (min-width: 1024px) {
    grid-template-columns: 32px calc(100% - 32px - 50px) 50px;
  }

  &:hover {
    background: var(--table-cell-background);

    .drag-handle * {
      opacity: 1;
      visibility: visible;
    }
  }

  &.selected {
    & > .table-cell {
      background-color: var(--table-cell-background2);
    }
    &:hover > .table-cell {
      background-color: var(--table-cell-background3);
    }
  }
}

.table-cell {
  display: flex;
  align-items: center;
  padding: 16px 8px;
  border-bottom: 1px solid var(--table-cell-border);
  position: relative;

  &.table-cell--name {
    padding-left: 50px;
    padding-right: 16px;
  }
}

.table-cell-empty {
  padding: 16px;
  border-bottom: 1px solid var(--table-cell-border);
}

.table-cell-drag {
  position: absolute;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
  z-index: 2;
}
.table-cell-expand {
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
}

.table-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.drag-handle {
  & * {
    opacity: 0;
    visibility: hidden;
  }

  &.visible * {
    opacity: 1;
    visibility: visible;
  }
}
</style>
