<script setup lang="ts">
import { useRouter } from "vue-router"
import { computed, ref, watch } from "vue"
import { useToggle } from "@vueuse/core"
import { useRouteParams } from "@vueuse/router"
import { push } from "notivue"

import { AdminPaymentsRouteName } from "@/router"
import {
  type UserDetail,
  UserForm,
  useUserDetail,
  useUserStore,
  DETAIL_USER_REQUEST_ERROR_MESSAGE
} from "@/modules/admin"
import { APageDrawer, SaveConfirm } from "@/package/ui-kit"

const router = useRouter()
const userId = useRouteParams<string>("id")
const [isOpened, toggleOpened] = useToggle(true)

const callCloseAction = () => toggleOpened(false)
const { userDetailResponse, isUserDetailLoading, userDetailError } = useUserDetail(userId)
watch(
  userDetailError,
  async (err) => {
    if (err) {
      push.warning({ message: err.message ?? DETAIL_USER_REQUEST_ERROR_MESSAGE })
      await router.push({ name: AdminPaymentsRouteName })
    }
  },
  { immediate: true }
)

const store = useUserStore()
const isSubscriptionsLoaded = ref(false)
const changeLoaded = () => {
  isSubscriptionsLoaded.value = true
}

const formRef = ref<InstanceType<typeof UserForm> | null>(null)
const submitAction = async (dto: UserDetail) => {
  await store.editOne(userId, dto)
  push.success({ message: "Пользователь изменен" })
  toggleOpened(false)
}
watch(
  () => store.userEditErrorMessage,
  (message) => {
    if (message) {
      push.error({ message })
    }
  }
)

const confirmAction = () => {
  SaveConfirm({
    onOk: () => formRef.value?.submitAction()
  })
}
const isLoading = computed(() => {
  return !isSubscriptionsLoaded.value || isUserDetailLoading.value || store.isUserEditing
})
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    title="Редактирование пользователя"
    @onClose="() => router.push({ name: AdminPaymentsRouteName })"
  >
    <ASpin :spinning="isLoading">
      <UserForm
        ref="formRef"
        :user="userDetailResponse"
        @submit="submitAction"
        @isSubscriptionsLoaded="changeLoaded"
      />
    </ASpin>

    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton :disabled="isLoading" block size="large" @click="callCloseAction">
            Отмена
          </AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            block
            type="primary"
            size="large"
            :disabled="isLoading"
            :loading="isLoading"
            @click="confirmAction"
          >
            Сохранить
          </AButton>
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
