<script setup lang="ts">
import { type PropType } from "vue"
import Draggable from "vuedraggable"

import { OperationCategoryElement, type OperationCategory } from "@/modules/operation-category"

defineProps({
  list: {
    type: Array as PropType<Array<OperationCategory>>,
    required: true
  },

  expandedKeys: {
    type: Array as PropType<Array<number>>,
    required: true
  },

  selectedIdList: {
    type: Array as PropType<Array<number>>,
    required: true
  },

  group: {
    type: String,
    required: true
  }
})
const emit = defineEmits(["openDetail", "openEdit", "toggleExpand", "changeOrder", "changeSelect"])
</script>

<template>
  <Draggable
    animation="150"
    chosen-class="table-item--active"
    tag="div"
    handle=".drag-handle"
    draggable=".table-item"
    :list="list"
    :group="group"
    @change="emit('changeOrder')"
  >
    <template #item="{ element }">
      <OperationCategoryElement
        :element="element"
        :expanded-keys="expandedKeys"
        :group="group"
        :selected-id-list="selectedIdList"
        @toggleExpand="emit('toggleExpand', $event)"
        @openDetail="emit('openDetail', $event)"
        @openEdit="emit('openEdit', $event)"
        @changeOrder="emit('changeOrder')"
        @changeSelect="emit('changeSelect', $event)"
      />
    </template>
  </Draggable>
</template>

<style scoped></style>
