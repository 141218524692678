import { computed, reactive } from "vue"
import dayjs from "dayjs"

import type { LegalEntityCreate } from "@/modules/legal-entity"
interface CreateFormState extends Omit<LegalEntityCreate, "monthly_turnover_rate" | "okved_id"> {
  monthly_turnover_rate?: number
  okved_id?: number
  bank_account_id?: number
}

export const useFormState = () => {
  const formState = reactive<CreateFormState>({
    itn: "",
    organization_name: "",
    legal_address: "",
    okved_id: undefined,
    okved_code: "",
    head_fullname: "",
    bank_account_id: undefined,
    bic: "",
    bank_name: "",
    account_number: "",
    correspondent_account_number: "",
    monthly_turnover_rate: undefined,
    birth_date: "",
    gender: undefined,
    citizenship_code: undefined,
    kbk: "",
    kpp: "",
    ogrnip: "",
    oktmo: "",
    document_type_code: undefined,
    document_series: "",
    document_number: "",
    taxpayer_category_code: undefined
  })
  const requestData = computed<LegalEntityCreate>(() => {
    const {
      itn,
      organization_name,
      legal_address,
      okved_id,
      okved_code,
      head_fullname,
      bic,
      bank_name,
      account_number,
      bank_account_id,
      correspondent_account_number,
      monthly_turnover_rate,
      birth_date,
      gender,
      citizenship_code,
      kbk,
      kpp,
      ogrnip,
      oktmo,
      document_type_code,
      document_series,
      document_number,
      taxpayer_category_code
    } = formState

    return {
      itn,
      organization_name,
      legal_address,
      okved_id: okved_id ? okved_id : 0,
      okved_code,
      head_fullname,
      bic,
      bank_name,
      account_number,
      bank_account_id,
      correspondent_account_number,
      monthly_turnover_rate: monthly_turnover_rate ? monthly_turnover_rate : 0,
      birth_date: birth_date ? dayjs(birth_date).format("YYYY-MM-DD") : null,
      gender,
      citizenship_code,
      kbk,
      kpp,
      ogrnip,
      oktmo,
      document_type_code,
      document_series,
      document_number,
      taxpayer_category_code
    }
  })

  return {
    formState,
    requestData
  }
}
