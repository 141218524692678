import { ref, computed, watch } from "vue"
import { get, set } from "@vueuse/core"
import { defineStore } from "pinia"

import type { FlatOperationCategory } from "@/modules/operation-category"
import { useOperationCategoriesSelectList } from "@/modules/operation-category"
import { useListBufferApi } from "@/package/hooks"
import { PER_PAGE_DEFAULT } from "@/interfaces"

import { operationCategoryDetailGet } from "../../api/operation-category-detail.get"

export const useOperationCategoriesSelectStore = defineStore(
  "operationCategoriesSelectStore",
  () => {
    const pagination = ref<{ total: number; count: number; limit: number; offset: number }>({
      total: 0,
      count: 0,
      limit: 0,
      offset: 0
    })
    const actionTypeSrc = ref<string | undefined>()
    const excludeCategory = ref<string | number | undefined>()
    const isStatisticsCategory = ref<boolean>(false)
    const listingBuffer = ref<Array<FlatOperationCategory>>([])
    const searchQuery = ref<string>()
    const actionType = ref(actionTypeSrc)
    const bufferMap = computed(() => {
      const result = new Map()
      for (const dto of listingBuffer.value) {
        if (result.has(dto.id)) {
          continue
        }
        result.set(dto.id, dto)
      }
      return result
    })

    const setStoreParams = (
      actionType: string | undefined,
      excludeCategorySrc: string | number | undefined,
      isStatisticsCategorySrc: boolean
    ) => {
      actionTypeSrc.value = actionType
      excludeCategory.value = excludeCategorySrc
      isStatisticsCategory.value = isStatisticsCategorySrc
    }

    const isLoadedFull = computed(
      () => pagination.value.total !== null && pagination.value.total <= bufferMap.value.size
    )

    const { bufferConcat, bufferRewrite, setUpdateFn, updateBufferFn } =
      useListBufferApi(listingBuffer)

    const { listingResponse, requestListing, isListingLoading, selectError } =
      useOperationCategoriesSelectList()
    watch(listingResponse, (response) => {
      if (response) {
        set(pagination, response.pagination)
        updateBufferFn(response.data)
      }
    })
    const loadNextPart = async () => {
      setUpdateFn(bufferConcat)
      await requestListing({
        offset: get(listingBuffer, "length"),
        limit: PER_PAGE_DEFAULT,
        query: get(searchQuery),
        action_type: get(actionType),
        operation_category_id: get(excludeCategory),
        statistics: isStatisticsCategory.value || undefined
      })
    }

    const searchListing = async (query?: string) => {
      setUpdateFn(bufferRewrite)
      set(searchQuery, query?.length ? query : undefined)
      await requestListing({
        offset: 0,
        limit: PER_PAGE_DEFAULT,
        query: get(searchQuery),
        action_type: get(actionType),
        operation_category_id: get(excludeCategory),
        statistics: isStatisticsCategory.value || undefined
      })
    }

    const $reset = (isSelectOptionsReset: boolean = true) => {
      if (isSelectOptionsReset) {
        set(listingBuffer, [])
        set(pagination, {
          total: 0,
          count: 0,
          limit: 0,
          offset: 0
        })
      }
      set(searchQuery, "")
    }

    const setActionType = (type: string) => {
      set(actionType, type)
    }
    const $resetActionType = () => {
      set(actionType, undefined)
    }

    const loadOne = async (id: string | number) => {
      const res = await operationCategoryDetailGet(id)
      if (res) {
        bufferConcat({ ...res, type: res.type ?? "", comment: res.comment ?? "" })
      }
    }

    return {
      pagination,
      bufferMap,
      selectError,
      isListingLoading,
      searchListing,
      loadNextPart,
      isLoadedFull,
      loadOne,
      setStoreParams,
      $reset,
      $resetActionType,

      setActionType
    }
  }
)
