import type {
  BankData,
  OperationFilterParams,
  OperationExtraParams,
  OperationListElementDTO
} from "./interface"
import type { ImportEvents } from "../centrifugo/hooks/use-import-bus"
import type { OperationsCreateEvents } from "../centrifugo/hooks/use-operations-create-bus"

import { defineStore } from "pinia"
import { computed, type MaybeRef, readonly, ref, watch } from "vue"
import { get, noop, set } from "@vueuse/core"
import { push } from "notivue"
import { HttpStatusCode } from "axios"

import { useListBufferApi, usePagination } from "@/package/hooks"

import { useGetFilters, useOperationCopy, useOperationListDelete } from "./hooks"
import { useOperationList } from "./hooks/use-operation-list"
import { useSaveFilters } from "./hooks/use-save-filters"

export const useOperationStore = defineStore("Operation", () => {
  const { pagination, paginationTotal, decrementTotal, setPagination } = usePagination()
  const listingBuffer = ref(new Array<OperationListElementDTO>())
  const transactionsCount = computed(() => {
    let res = 0
    for (let i = 0; i < listingBuffer.value.length; i++) {
      res++
      if ((get(listingBuffer, i).transactions ?? []).length > 1) {
        res += (get(listingBuffer, i).transactions ?? []).length
      }
    }
    return res
  })
  const operationsIdList = computed(() => get(listingBuffer).map(({ id }) => id))

  const getBufferLength = () => get(listingBuffer, "length")

  const searchQuery = ref<string>()
  const searchQueryRO = readonly(searchQuery)
  const filters = ref<OperationFilterParams>({})
  const isFilterExists = computed(() => Object.keys(get(filters)).length > 0)
  const extraParams = ref<OperationExtraParams>({})

  const {
    bufferRO,
    updateBufferFn,
    setUpdateFn,
    bufferConcat,
    bufferRewrite,
    bufferFilterOneConcat
  } = useListBufferApi(listingBuffer)
  const {
    requestListing,
    isListingLoading,
    operationListingResponse,
    listingApiError,
    responseStatus
  } = useOperationList()

  const isResourceEmpty = computed(
    () => get(responseStatus) !== null && get(responseStatus) === HttpStatusCode.NoContent
  )
  const isLoadedFull = computed(() => {
    const total = get(pagination)?.total
    return (total !== null && total <= get(listingBuffer).length) || get(isResourceEmpty)
  })

  watch(listingApiError, (error) => {
    if (error && error.status !== 404) {
      push.error({ message: "Неизвестная ошибка, пожалуйста сообщите поддержке!" })
    }
  })
  watch(operationListingResponse, (listing) => {
    if (listing && listing.data.length > 0) {
      setPagination(listing.pagination)
      updateBufferFn(listing.data)
    } else {
      updateBufferFn([])
      setPagination({ ...get(pagination), total: 0 })
    }
  })

  const loadNextPart = async () => {
    setUpdateFn(bufferConcat)
    await requestListing({
      offset: get(listingBuffer, "length"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      ...get(filters),
      ...get(extraParams)
    })
  }
  const reloadBuffer = async () => {
    setUpdateFn(bufferRewrite)
    await requestListing({
      offset: 0,
      limit: get(listingBuffer, "length"),
      query: get(searchQuery) || undefined,
      ...get(filters),
      ...get(extraParams)
    })
  }
  const updateBuffer = async () => {
    const bufferLengthBeforeRewrite = getBufferLength()

    setUpdateFn(bufferRewrite)
    await requestListing({
      offset: 0,
      limit: 100,
      query: get(searchQuery) || undefined,
      ...get(filters),
      ...get(extraParams)
    }).catch(noop)

    setUpdateFn(bufferConcat)
    while (
      getBufferLength() < bufferLengthBeforeRewrite &&
      getBufferLength() !== 0 &&
      !isLoadedFull.value
    ) {
      const limit =
        bufferLengthBeforeRewrite - getBufferLength() > 100
          ? 100
          : bufferLengthBeforeRewrite - getBufferLength()

      await requestListing({
        offset: getBufferLength(),
        limit,
        query: get(searchQuery) || undefined,
        ...get(filters),
        ...get(extraParams)
      }).catch(noop)
    }
  }

  const { executeListDelete, isDeleting, operationDeleteError } = useOperationListDelete()
  const deleteByExcludedIdList = async (idList: MaybeRef<Array<number>>) => {
    const query = get(searchQuery)
    const listingFilters = get(filters)
    const requestFilters =
      Object.keys(listingFilters).length > 0 || query
        ? {
            query,
            ...listingFilters
          }
        : null

    await executeListDelete({ exclude_ids: get(idList), filters: requestFilters })
    await reloadBuffer().catch(noop)
  }
  const deleteBySelectedIdList = async (idList: MaybeRef<Array<number>>) => {
    await executeListDelete({ include_ids: get(idList) })
    await reloadBuffer().catch(noop)
  }
  const deleteOperation = async (ID: MaybeRef<number>) => {
    const id = get(ID)
    if (isLoadedFull.value) {
      await executeListDelete({ include_ids: [id] })
      bufferFilterOneConcat(id, [])
      decrementTotal()
    } else {
      await executeListDelete({ include_ids: [id] })
      setUpdateFn(bufferFilterOneConcat.bind(null, id))
      await requestListing({
        offset: getBufferLength(),
        limit: 1,
        query: get(searchQuery) || undefined,
        ...get(filters),
        ...get(extraParams)
      })
    }
  }

  const { executeCopy, isCopying, operationCopyError } = useOperationCopy()

  const copyOperation = async (id: number) => {
    await executeCopy(id)
    await updateBuffer()
  }

  const searchListing = async (query: MaybeRef<string>) => {
    set(searchQuery, get(query))
    setPagination()
    setUpdateFn(bufferRewrite)
    await requestListing({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      ...get(filters),
      ...get(extraParams)
    })
  }

  const { executeSave } = useSaveFilters()

  const saveFilters = async (payload: Partial<OperationFilterParams> | null, asAdmin: boolean) => {
    if (!asAdmin) {
      if (payload) {
        await executeSave({
          ...payload
        })
      } else {
        await executeSave({})
      }
    }
  }

  const { getFilters, isGettingFilters } = useGetFilters()

  const isFinishedGettingFilters = ref(false)

  const isOperationListingDataLoading = computed(
    () => isGettingFilters.value || isListingLoading.value
  )

  const getSavedFilters = async () => {
    try {
      const response = await getFilters()
      await applyFilters(response.data.value?.data ? response.data.value?.data : {})
    } finally {
      isFinishedGettingFilters.value = true
    }
  }

  const applyFilters = async (filersData: MaybeRef<OperationFilterParams> | null) => {
    if (filersData) {
      set(filters, { ...get(filersData) })
    } else {
      set(filters, {})
    }
    setPagination()
    setUpdateFn(bufferRewrite)
    await requestListing({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      ...get(filters),
      ...get(extraParams)
    })
  }

  const addExtraParams = async (params: MaybeRef<OperationExtraParams> | null) => {
    if (params) {
      set(extraParams, { ...get(params) })
    } else {
      set(extraParams, {})
    }
  }

  const $reset = () => {
    set(filters, {})
    setPagination()
    set(listingBuffer, [])
    set(searchQuery, undefined)
  }

  const $resetExtra = () => {
    set(extraParams, {})
  }

  const busEventsHandler = async (
    successMessage: string | null,
    event: ImportEvents | OperationsCreateEvents
  ) => {
    switch (event) {
      case "error":
        break
      case "success":
        if (successMessage !== null) {
          push.success({ message: successMessage })
        }
        await updateBuffer()
    }
  }

  const banksList = ref<BankData[]>([])
  // @ts-ignoreBankData
  const updateBanksList = (data: any) => (banksList.value = data)

  // @ts-ignore
  // @ts-ignore
  return {
    isLoadedFull,
    isResourceEmpty,
    listingApiError,
    paginationTotal,
    pagination,
    filters,
    isFilterExists,
    applyFilters,
    getSavedFilters,
    searchQuery: searchQueryRO,
    isFinishedGettingFilters,
    searchListing,
    saveFilters,
    transactionsCount,
    operationsIdList,
    listing: bufferRO,
    isListingLoading: isOperationListingDataLoading,
    loadNextPart,
    copyOperation,
    isCopying,
    operationCopyError,
    isDeleting,
    deleteByExcludedIdList,
    deleteBySelectedIdList,
    deleteOperation,
    operationDeleteError,
    updateBuffer,
    busEventsHandler,
    banksList,
    updateBanksList,
    $reset,
    $resetExtra,

    addExtraParams
  }
})
