<script setup lang="ts">
import type { ProductDetail } from "../../interfaces"
import type { Form } from "ant-design-vue"

import { computed, onUnmounted, type PropType, ref } from "vue"
import { useDebounceFn } from "@vueuse/core"
import { onMounted, watch } from "vue"
import { push } from "notivue"
import { storeToRefs } from "pinia"

import { CounterpartiesSelect } from "@/modules/counterparty"
import { usePopupContainerGetter } from "@/package/ui-kit"
import { useForm } from "@/package/hooks"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { isValidateError } from "@/package/util"

import { useProductsStore } from "../../store"
import UnitsTypeSelect from "../UnitsTypeSelect.vue"
import TaxTypeSelect from "../TaxTypeSelect.vue"
import ProductTypeSelect from "../ProductTypeSelect.vue"
import NdsTypeSelect from "../ProductListing/NdsTypeSelect.vue"

import { useFormState } from "./use-form-state"
import { useRuleset } from "./use-ruleset"

const props = defineProps({
  product: {
    type: [Object, undefined] as PropType<ProductDetail | undefined>,
    default: () => undefined
  }
})

const emit = defineEmits(["success"])

const { formState, requestData } = useFormState()
const ruleset = useRuleset()
const resetAction = useDebounceFn(() => {
  if (props.product) {
    Object.assign(formState, {
      ...props.product,
      counterparty_id: props.product.counterparty.id,
      counterparty_name: props.product.counterparty.name
    })
  }
}, 100)
watch(() => props.product, resetAction)
onMounted(resetAction)

const { validate, validateInfos, isValidating } = useForm(formState, ruleset)
const productsStore = useProductsStore()
const { editError, isEditing, isEditingFinished } = storeToRefs(productsStore)

watch([editError, isEditingFinished], ([err, finishedStatus]) => {
  if (err && finishedStatus) {
    isEditingFinished.value = false
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const formRef = ref<typeof Form | null>(null)

const submitAction = async () => {
  try {
    await validate()
    if (props.product) {
      await productsStore.editProduct(requestData.value)
    } else {
      throw new Error("Cannot save product")
    }
    push.success({
      message: "Успешно изменено"
    })
    emit("success")
    await productsStore.updateBufferAfterEdit(formState)
  } catch (err) {
    if (isValidateError(err)) {
      formRef.value?.scrollToField(err.errorFields[0].name, {
        block: "start",
        boundary: (parent: HTMLElement) => {
          parent.focus()
          return parent.classList.contains("ant-row")
        }
      })
    }
  }
}

defineExpose({
  submitAction,
  isLoading: computed(() => isEditing.value || isEditingFinished.value || isValidating.value),
  resetAction
})
const popupContainerGetter = usePopupContainerGetter()

onUnmounted(() => {
  isEditingFinished.value = false
})
</script>

<template>
  <AForm
    ref="formRef"
    name="edit-product-form"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    scroll-to-first-error
    @submit.prevent="submitAction"
    @keydown.enter.prevent
  >
    <AFormItem label="Тип" name="type" v-bind="validateInfos?.type">
      <ProductTypeSelect
        v-model:value="formState.type"
        :get-popup-container="popupContainerGetter"
        size="large"
      />
    </AFormItem>
    <AFormItem label="Название" name="name" v-bind="validateInfos?.name">
      <AInput v-model="formState.name" size="large" placeholder="Введите название" />
    </AFormItem>
    <AFormItem label="Артикул" name="article" v-bind="validateInfos?.article">
      <AInput v-model="formState.article" size="large" placeholder="Введите название" />
    </AFormItem>
    <AFormItem label="Контрагент" name="counterparty_id" v-bind="validateInfos?.counterparty_id">
      <CounterpartiesSelect
        v-model:selectedId="formState.counterparty_id"
        v-model:selectedCounterPartyLabel="formState.counterparty_name"
        :get-popup-container="popupContainerGetter"
        size="large"
        placeholder="Найти контрагента"
      />
    </AFormItem>
    <AFormItem label="Система налогообложения" name="tax_type" v-bind="validateInfos?.tax_type">
      <TaxTypeSelect
        v-model:value="formState.tax_type"
        :get-popup-container="popupContainerGetter"
        size="large"
      />
    </AFormItem>
    <AFormItem label="НДС" name="nds_type" v-bind="validateInfos?.nds_type">
      <NdsTypeSelect
        v-model:value="formState.nds"
        :get-popup-container="popupContainerGetter"
        size="large"
      />
    </AFormItem>
    <AFormItem label="Единица измерения" name="unit_type" v-bind="validateInfos?.unit_type">
      <UnitsTypeSelect
        v-model:value="formState.unit_type"
        :get-popup-container="popupContainerGetter"
        size="large"
      />
    </AFormItem>
    <AFormItem label="На складе" name="quantity" v-bind="validateInfos?.quantity">
      <AInput
        v-model="formState.quantity"
        size="large"
        placeholder="Введите колличество на складе"
      />
    </AFormItem>
    <AFormItem label="Себестоимость" name="self_cost" v-bind="validateInfos?.self_cost">
      <AInputPrice v-model="formState.self_cost" size="large" placeholder="Введите сумму" />
    </AFormItem>
    <AFormItem label="Стоимость" name="cost" v-bind="validateInfos?.cost">
      <AInputPrice v-model="formState.cost" size="large" placeholder="Введите сумму" />
    </AFormItem>
    <AFormItem label="Описание" name="description" v-bind="validateInfos?.description">
      <ATextarea
        v-model:value="formState.description"
        size="large"
        placeholder="Введите название"
        :rows="3"
      />
    </AFormItem>
  </AForm>
</template>
