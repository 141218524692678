<script setup lang="ts">
import { h, onUnmounted, ref, computed, watch } from "vue"
import { InfoCircleOutlined, DeleteOutlined } from "@ant-design/icons-vue"
import { Divider, Form, theme } from "ant-design-vue"

import { ADatePicker, AInput, useMobileBp } from "@/package/ui-kit"
import { OperationCategoryTreeSelect } from "@/modules/operation-category"
import { CounterpartiesSelect } from "@/modules/counterparty"
import { ProjectSelect } from "@/modules/project"
import { useForm } from "@/package/hooks"
import { isValidateError } from "@/package/util"
import { BankAccountSelect } from "@/modules/bank-account"
import { OperationActionForCategoryList } from "@/modules/operation"

import OperationTypeSelect from "../OperationTypeSelect.vue"
import OperationActionSelect from "../OperationActionSelect.vue"

import { useOperationListEditStore } from "./store"
import { useRuleset } from "./use-ruleset"
import { DEFAULT_VALIDATION_FORM_STATE } from "./constants"
const props = defineProps({
  actionTypeDefault: {
    type: String,
    default: undefined
  }
})
const emit = defineEmits(["submit"])
const store = useOperationListEditStore()
const inputs = {
  type: h(OperationTypeSelect),
  purpose: h(AInput, { placeholder: "Укажите назначение платежа" }),
  date: h(ADatePicker),
  operation_category_id: h(OperationCategoryTreeSelect, { hideLink: true, multiple: false }),
  counterparty_id: h(CounterpartiesSelect, { placeholder: "Выберите контрагента", hideLink: true }),
  project_id: h(ProjectSelect, { placeholder: "Выберите проект", hideLink: true }),
  bank_account_id: h(BankAccountSelect, { placeholder: "Выберите счет", hideLink: true }),
  action: h(OperationActionSelect, { placeholder: "Выберите тип", hideLink: true })
}

const { token } = theme.useToken()

const ruleset = useRuleset(store.formFields)

const { validate, validateInfos } = useForm(ref(DEFAULT_VALIDATION_FORM_STATE), ruleset)

const formRef = ref<typeof Form | null>(null)

const actionType = computed(
  () => store.formFields?.find((item) => item.operationKey === "action")?.value || null
)

const type = computed(() => {
  if (!actionType.value) return
  const type = actionType.value as string
  return OperationActionForCategoryList[type] || null
})
watch(
  () => actionType.value,
  (newValue) => {
    const operationCategoryId = store.formFields?.find(
      (item) => item.operationKey === "operation_category_id"
    )
    if (newValue && operationCategoryId) {
      operationCategoryId.value = null
    }
  },
  { deep: true }
)

const submitAction = () => {
  validate()
    .then(() => {
      emit("submit", store.requestData)
    })
    .catch((err) => {
      if (isValidateError(err)) {
        formRef.value?.scrollToField(err.errorFields[0].name, {
          block: "start",
          boundary: (parent: HTMLElement) => {
            parent.focus()
            return parent.classList.contains("ant-row")
          }
        })
      }
    })
}

defineExpose({ submitAction })

onUnmounted(store.$reset)
const isMobile = useMobileBp()
</script>

<template>
  <AForm
    ref="formRef"
    name="mass-edit-form"
    class="mass-edit-form"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    scroll-to-first-error
    @submit.prevent="submitAction"
  >
    <ARow v-if="store.hasTransfer" class="mass-edit-form__item">
      <ACol>
        <ATypographyText type="secondary"
          ><InfoCircleOutlined :style="{ marginRight: '8px' }" />Поля, которые вы можете
          одновременно редактировать в доходных/расходных операциях и переводах, ограничены.
          Рекомендуем вам отдельно редактировать доходы с расходами или переводы.</ATypographyText
        >
        <ADivider :style="{ width: '100%', marginTop: '16px' }" />
      </ACol>
    </ARow>
    <template v-for="(formField, key) in store.formFields" :key="formField.id">
      <ARow
        :gutter="16"
        :wrap="!!isMobile"
        class="mass-edit-form__item"
        :class="{ 'mass-edit-form__item--adopted': isMobile }"
      >
        <ACol :flex="isMobile ? '1 1 auto' : '1 1 50%'">
          <ASelect
            placeholder="Выберите поле"
            size="large"
            :options="store.filteredOptions"
            style="width: 100%"
            @select="
              (value: string) => {
                formField.operationKey = value
                formField.value = null
              }
            "
          />
        </ACol>
        <ACol :flex="isMobile ? '1 1 100%' : '1 1 50%'" :order="isMobile ? 3 : 2">
          <AFormItem
            :class="{ 'form-item--adopted': isMobile }"
            :name="formField.operationKey"
            v-bind="formField.operationKey ? validateInfos[formField.operationKey] : {}"
          >
            <component
              :is="inputs[formField.operationKey as keyof typeof inputs]"
              v-model:value="formField.value"
              v-model:selectedId="formField.value"
              :action-type="type ? type : props.actionTypeDefault"
              hide-link
              size="large"
              style="width: 100%"
            />
          </AFormItem>
        </ACol>
        <ACol v-if="key !== 0 || !isMobile" flex="0 0 56px" :order="isMobile ? 2 : 3">
          <AButton
            v-if="key !== 0 || store.formFields.length > 1"
            size="large"
            type="primary"
            danger
            :icon="h(DeleteOutlined)"
            @click="() => store.deleteField(formField.id)"
          />
        </ACol>
      </ARow>
      <ARow
        v-if="formField.operationKey === 'action'"
        class="mass-edit-form__item mass-edit-form__item--warning"
        :class="{ 'mass-edit-form__item--adopted': isMobile }"
      >
        <ACol :style="{ marginBottom: isMobile ? '8px' : 0 }">
          <AText>При изменении типа операции, поле "Статья дохода/расхода" будет очищено</AText>
        </ACol>
      </ARow>
      <template v-if="isMobile && key !== store.formFields.length - 1">
        <Divider :style="{ margin: 0 }" class="mass-edit-form__divider" />
      </template>
    </template>
    <AButton
      v-if="store.formFields.length < (store.hasTransfer ? 2 : 8)"
      class="mass-edit-form__item add-button"
      type="dashed"
      size="large"
      @click="store.addField"
    >
      Добавить поле
    </AButton>
    <template
      v-if="
        validateInfos.fields.validateStatus === 'error' ||
        validateInfos.fields.validateStatus === 'validating'
      "
    >
      <div class="form-fields-global-error">Заполните все добавленные поля</div>
    </template>
  </AForm>
</template>

<style scoped>
:deep(.ant-checkbox-group) {
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.hidden-upload:deep(.ant-upload) {
  display: none;
}

.add-button {
  width: 100%;
  margin-top: 28px;
}

.form-fields-global-error {
  text-align: right;
  margin-top: 8px;
  gap: 8px;
  justify-items: end;
  color: v-bind("token.colorErrorText");
  grid-column: 2/3;
}

:deep(.ant-form-item .ant-form-row) {
  min-height: 40px;
}

.ant-form-item {
  margin-bottom: 16px;
}
.form-item--adopted {
  margin-top: 16px;
}

.mass-edit-form {
  display: grid;
  grid-template-columns: 24px calc(100% - 48px) 24px;
}

.mass-edit-form__divider {
  grid-column: 1/-1;
}

.mass-edit-form__item {
  grid-column: 2/3;
  margin-top: 28px;
}
.mass-edit-form__item:first-of-type {
  margin-top: 16px;
}
.mass-edit-form__item.mass-edit-form__item--warning {
  margin-top: -8px;
}
.mass-edit-form__item--adopted {
  margin-top: 16px;
}
</style>
