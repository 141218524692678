<script setup lang="ts">
import { computed, onUnmounted, onBeforeMount, ref } from "vue"
import { useInfiniteScroll } from "@vueuse/core"
import { storeToRefs } from "pinia"
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons-vue"

import {
  CreateBtnActionText,
  CreateBtnActionTextDefault,
  useOperationCategoriesStore,
  OperationCategoryTree,
  type FlatOperationCategory
} from "@/modules/operation-category"
import { SortOrder } from "@/interfaces"
import { useAdoptable, SaveConfirm } from "@/package/ui-kit"
import { useBufferSelection } from "@/package/hooks"

import { useColumns } from "./use-columns"

const emit = defineEmits(["openDetail", "openEdit", "openCreate"])
const isAdoptable = useAdoptable()
const { columns } = useColumns()

const store = useOperationCategoriesStore()
const { flatListingBuffer, idList, paginationTotalAll, actionType, sortBy, sortOrder } =
  storeToRefs(store)
const {
  isAllSelected,
  selectedIdList,
  selectedCount,
  onSelect,
  toggleAllSelected,
  unselectAllAction,
  excludeKeysMode,
  currentModeKeys
} = useBufferSelection(idList, paginationTotalAll)
const allChecked = computed({
  get: () => isAllSelected,
  set: (value) => {
    toggleAllSelected(value)
  }
})

const expandedKeys = ref(new Array<number>())
const expandAll = () => {
  expandedKeys.value = Array.from(store.idList)
}
const shrinkAll = () => {
  expandedKeys.value = []
}
const isExpanded = computed(() => expandedKeys.value.length > 0)
const expandAllToggle = () => {
  if (isExpanded.value) {
    shrinkAll()
  } else {
    expandAll()
  }
}
const toggleExpandAction = (id: number) => {
  if (expandedKeys.value.includes(id)) {
    expandedKeys.value = expandedKeys.value.filter((key) => key !== id)
  } else {
    expandedKeys.value.push(id)
  }
}

const changeOrderAction = () => {
  store.updateListingOperation(store.listingBuffer, actionType.value)
}
const getChildElements = (items: Array<FlatOperationCategory>, parentId: number): Array<number> => {
  let result = new Array<number>()
  const children = items.filter((item) => item.parent_id === parentId)
  if (children && children.length > 0) {
    for (const child of children) {
      result.push(child.id)
      result = result.concat(getChildElements(items, child.id))
    }
  }
  return result
}
const getParentElements = (items: Array<FlatOperationCategory>, childId: number): Array<number> => {
  let result = new Array<number>()
  const parent = items.find((item) => item.id === childId)
  if (parent && parent.parent_id !== null) {
    result.push(parent.parent_id)
    result = result.concat(getParentElements(items, parent.parent_id))
  }
  return result
}

const changeSelectAction = (id: number) => {
  let selectedList = selectedIdList.value
  const childElements = getChildElements(flatListingBuffer.value, id)
  const parentElements = getParentElements(flatListingBuffer.value, id)
  const elementsToProcess = [id, ...childElements]
  if (selectedList.includes(id)) {
    selectedList = selectedList.filter((el) => !elementsToProcess.includes(el))
    parentElements.forEach((parentId) => {
      if (selectedList.includes(parentId)) {
        selectedList = selectedList.filter((el) => !parentElements.includes(el))
      }
    })
  } else {
    selectedList = Array.from(new Set([...selectedList, ...elementsToProcess]))
  }
  onSelect(selectedList)
}

onBeforeMount(async () => {
  await store.getOperationsSorting()
})

const tableWrapper = ref<HTMLElement | null>(null)

useInfiniteScroll(tableWrapper, () => store.loadNextPart(), {
  distance: 1000,
  interval: 2000,
  canLoadMore: () =>
    !store.isLoadedFull && store.listingApiError === null && !store.isSortingLoading
})

const sortStatus = computed(() => {
  if (sortBy.value && sortOrder.value === "asc") {
    return 1
  } else if (sortBy.value && sortOrder.value === "desc") {
    return 2
  } else {
    return 0
  }
})

const tooltipText = [
  "Нажмите для сортировки по возрастанию",
  "Нажмите для сортировки по убыванию",
  "Нажмите, чтобы отменить сортировку"
]
const ConfirmTtitle = [
  "Вы действительно хотите отсортировать все статьи по наименованию?",
  "Вы действительно хотите отсортировать все статьи по наименованию?",
  "Вы действительно хотите отменить сортировку по наименованию?"
]

const changeSort = async (key: string) => {
  const newSort = [SortOrder.ASC, SortOrder.DESC]
  SaveConfirm({
    content: "",
    title: ConfirmTtitle[sortStatus.value],
    onOk: async () => {
      if (newSort[sortStatus.value]) {
        await store.setSortOrder(key, newSort[sortStatus.value])
      } else {
        await store.resetSortOrder()
      }
      await store.saveOperationSorting()
    }
  })
}

onUnmounted(store.$reset)
defineExpose({
  expandAll,
  shrinkAll,
  expandAllToggle,
  isExpanded,
  isAllSelected,
  selectedCount,
  toggleAllSelected,
  unselectAllAction,
  excludeKeysMode,
  currentModeKeys
})
</script>

<template>
  <div ref="tableWrapper" :style="{ overflow: 'auto', height: '100%' }">
    <div v-if="!isAdoptable" class="table-row table-row--head">
      <div class="table-cell">
        <ACheckbox v-model:checked="allChecked" :indeterminate="selectedCount > 1 && !allChecked" />
      </div>
      <template v-for="col in columns" :key="col.key">
        <ATooltip
          v-if="col.key === 'name' || col.key === 'id'"
          :title="tooltipText[sortStatus]"
          class="table-cell table-cell--name"
          @click.stop="changeSort(col.key)"
        >
          <div class="table-text">Название</div>
          <span class="ant-table-column-sorter ant-table-column-sorter-full">
            <span class="ant-table-column-sorter-inner">
              <span
                role="presentation"
                aria-label="caret-up"
                class="anticon anticon-caret-up ant-table-column-sorter-up"
                :class="{ active: sortBy && sortOrder === 'asc' }"
              >
                <CaretUpOutlined />
              </span>
              <span
                role="presentation"
                aria-label="caret-down"
                class="anticon anticon-caret-down ant-table-column-sorter-down"
                :class="{ active: sortBy && sortOrder === 'desc' }"
              >
                <CaretDownOutlined />
              </span>
            </span>
          </span>
        </ATooltip>
        <div v-else class="table-cell"></div>
      </template>
    </div>
    <div v-if="!store.listingBuffer || store.listingBuffer.length === 0" class="table-cell-empty">
      <AEmptyListingPlaceholder
        :create-text="CreateBtnActionText[store.actionType] ?? CreateBtnActionTextDefault"
        show-create-button
        @openCreate="() => emit('openCreate')"
      />
    </div>
    <OperationCategoryTree
      v-else
      class="operations-tree"
      :list="store.listingBuffer"
      group="operations-tree"
      :expanded-keys="expandedKeys"
      :selected-id-list="selectedIdList"
      @toggleExpand="toggleExpandAction"
      @openDetail="emit('openDetail', $event)"
      @openEdit="emit('openEdit', $event)"
      @changeOrder="changeOrderAction"
      @changeSelect="changeSelectAction"
    />
  </div>
</template>

<style scoped>
.table-row {
  display: grid;
  grid-template-columns: 100%;

  @media (min-width: 1024px) {
    grid-template-columns: 32px calc(100% - 32px - 50px) 50px;
  }

  &.table-row--head {
    position: sticky;
    top: 0;
    display: none;
    z-index: 1;

    @media (min-width: 1024px) {
      display: grid;
    }

    &:deep(.table-cell),
    .table-cell {
      font-weight: 600;
      background: var(--table-cell-background);
    }

    &:deep(.table-cell--name:after) {
      content: "";
      position: absolute;
      top: 50%;
      inset-inline-end: 0;
      width: 1px;
      height: 1.6em;
      background-color: var(--table-cell-border);
      transform: translateY(-50%);
      transition: background-color 0.2s;
    }
  }
}
:deep(.table-cell),
.table-cell {
  display: flex;
  align-items: center;
  padding: 16px 8px;
  border-bottom: 1px solid var(--table-cell-border);
  position: relative;

  :deep(&.table-cell--name),
  &.table-cell--name {
    padding-left: 36px;
    padding-right: 16px;
    position: relative;

    .table-text {
      width: 100%;
    }
  }
}
.ant-table-column-sorter-inner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  color: var(--table-arrow);
}
.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  font-size: 12px;

  &.active {
    color: var(--transfer-operation-color);
  }
}
.ant-table-column-sorter-down {
  margin-top: -0.3em;
}
</style>
