import { useAxios } from "@vueuse/integrations/useAxios"

import type { OperationCategory } from "@/modules/operation-category"
import { client, useApiError } from "@/package/api-client"

export const useOperationUpdateListing = () => {
  const { execute, isLoading, error, isFinished } = useAxios<
    { success: boolean },
    { data: Array<OperationCategory>; action_type: string }
  >("/operation-categories/update-listing", { method: "POST" }, client, { immediate: false })

  const updateListingOperation = async (data: Array<OperationCategory>, actionType: string) => {
    await execute("/operation-categories/update-listing", {
      data: { data, action_type: actionType }
    })
  }

  return {
    updateListingOperation,
    isChanging: isLoading,
    changeOrderError: useApiError(error),
    isChangingFinished: isFinished
  }
}
