<script setup lang="ts">
import { computed, h, onBeforeUnmount, onMounted, type PropType, ref, watch } from "vue"
import { get, useDebounceFn, useToggle } from "@vueuse/core"
import { TransitionFade } from "@morev/vue-transitions"
import { push } from "notivue"
import { isArray } from "lodash-es"
import { storeToRefs } from "pinia"

import { AFakeOption, VNodeRender } from "@/package/ui-kit"
import { DETAIL_REQUEST_ERROR_MESSAGE } from "@/modules/operation"
import { ApiNotFoundResponse } from "@/package/api-client"

import { CreateOperationCategoryForm } from "../CreateOperationCategoryForm"

import { useOperationCategoriesSelectStore } from "./store"

const props = defineProps({
  id: { type: Number, default: null },
  hasNoneOption: { type: Boolean, default: false },

  hideLink: {
    type: Boolean,
    default: false
  },

  excludedList: {
    type: Array as PropType<Array<number | string>>,
    default: () => []
  },

  excludedParent: {
    type: [String, Number] as PropType<number | string>,
    default: () => undefined
  },

  actionType: {
    type: String,
    default: undefined
  },

  isStatisticsCategory: {
    type: Boolean,
    default: false
  },

  isSelectOptionsReset: {
    type: Boolean,
    default: true
  },

  page: {
    type: String,
    default: undefined
  }
})

const selectedId = defineModel({
  type: [Number, Array] as PropType<number | Array<number>>,
  get(v) {
    if (get(isListingLoading) && get(bufferMap).size === 0) {
      return -1
    }
    return v
  }
})

const [isSelectOpen, toggleSelectOpen] = useToggle(false)

const [isFormOpen, toggleFormOpen] = useToggle(false)
const formRef = ref<InstanceType<typeof CreateOperationCategoryForm> | null>(null)

const createOperationCategory = () => {
  formRef?.value?.submitAction()
}

const cancelCreateForm = () => {
  toggleFormOpen(false)
}

const selectNewLegalOperationCategory = async (newId: number) => {
  await store.loadOne(newId)
  selectedId.value = newId
  toggleFormOpen(false)
}

const getContainer = (): HTMLElement | null =>
  document.querySelector(
    ".operation-category-drawer .ant-drawer-body div[data-overlayscrollbars-contents]"
  )
const openForm = () => {
  toggleSelectOpen(false)
  toggleFormOpen(true)
}

const store = useOperationCategoriesSelectStore()

const { pagination, bufferMap, isListingLoading, isLoadedFull, selectError } = storeToRefs(store)

watch(
  () => props.excludedParent,
  async (newVal, oldVal) => {
    if (newVal !== oldVal) {
      store.setStoreParams(props.actionType, newVal, props.isStatisticsCategory as boolean)
      store.$reset()
      await store.loadNextPart()
    }
  },
  { immediate: true }
)

watch(
  selectError,
  async (err) => {
    if (err) {
      push.error({ message: err.message ?? DETAIL_REQUEST_ERROR_MESSAGE })
    }
  },
  { immediate: true }
)

watch(
  () => props.actionType,
  async (value) => {
    if (value) {
      store.setActionType(value)
      selectedId.value = undefined
    } else {
      store.$resetActionType()
    }
    store.$reset(props.isSelectOptionsReset)
    await store.loadNextPart()
  },
  { deep: true }
)

onMounted(async () => {
  store.setStoreParams(
    props.actionType,
    props.excludedParent,
    props.isStatisticsCategory as boolean
  )
  if (!bufferMap.value.size && !isListingLoading.value && props.page !== "edit") {
    await store.loadNextPart()
  }
})

watch(
  () => [selectedId.value, bufferMap.value.size],
  async ([newId, oldId]) => {
    if (isArray(newId)) {
      for (const singleId of newId) {
        if (
          singleId &&
          !bufferMap.value.has(singleId) &&
          (bufferMap.value.size || isLoadedFull.value)
        ) {
          try {
            await store.loadOne(singleId)
          } catch (e) {
            if (e instanceof ApiNotFoundResponse) {
              selectedId.value = (selectedId.value as Array<number>).filter((currentId) => {
                return currentId !== singleId
              })
            }
          }
        }
      }
      return
    }

    if (newId && newId !== oldId && !bufferMap.value.has(newId) && bufferMap.value.size) {
      try {
        await store.loadOne(newId)
      } catch (e) {
        if (e instanceof ApiNotFoundResponse) {
          selectedId.value = undefined
        } else {
          push.error({ message: "Не удалось загрузить информацию о статье" })
        }
      }
    }
  }
)

const options = computed(() => {
  if (get(isListingLoading) && get(bufferMap).size === 0) {
    return [{ label: "Загрузка...", value: -1 }]
  }
  const result = props.hasNoneOption ? [{ label: "Без статьи", value: null }] : []
  return result.concat(
    Array.from(bufferMap.value.values())
      .filter(({ id }) => !props.excludedList.includes(id))
      .map(({ id, name }) => ({ key: id, value: id, label: name }))
  )
})

const scrollAction = async (e: any) => {
  if (e?.target?.scrollTop && e?.target?.scrollHeight && !get(isListingLoading)) {
    const scrolled = e.target.scrollHeight - e.target.scrollTop
    const scrollHalf = e.target.scrollHeight / 2
    if (scrolled <= scrollHalf) {
      if (pagination.value.total > options.value.length) {
        await store.loadNextPart()
      }
    }
  }
}

const searchAction = useDebounceFn((searchQuery: string) => store.searchListing(searchQuery), 1000)

const LoadingOption = () =>
  h(AFakeOption, { disabled: true }, () =>
    pagination.value.total
      ? `Список статей загружается, ${pagination.value.offset} из ${pagination.value.total}`
      : "Список статей загружается"
  )

const resetSearch = (ids?: Array<number> | number) => {
  if (Array.isArray(ids) && ids.length === 0) {
    store.searchListing("")
  } else if (!ids) {
    store.searchListing("")
  }
}

onBeforeUnmount(() => store.$reset(props.isSelectOptionsReset))
</script>

<template>
  <ASelect
    v-model:value="selectedId"
    class="operation-category-select"
    :open="isSelectOpen"
    placeholder="Выберите статью"
    size="large"
    show-search
    show-arrow
    allow-clear
    virtual
    :filter-option="false"
    :options="options"
    :loading="isListingLoading"
    @search="searchAction"
    @popupScroll="scrollAction"
    @dropdownVisibleChange="toggleSelectOpen"
    @focus="() => toggleSelectOpen(true)"
    @change="resetSearch"
  >
    <template #dropdownRender="{ menuNode }">
      <APageDrawer
        v-if="isFormOpen"
        v-model:open="isFormOpen"
        title="Создание статьи"
        class-name="operation-category-drawer"
      >
        <CreateOperationCategoryForm
          ref="formRef"
          :get-container="getContainer"
          :action-type="actionType"
          @success="selectNewLegalOperationCategory"
        />
        <template #footer>
          <ARow :gutter="[8, 8]">
            <ACol :sm="24" :md="12" flex="1 1 50%">
              <AButton size="large" block @click="cancelCreateForm">Отмена</AButton>
            </ACol>
            <ACol :sm="24" :md="12" flex="1 1 50%">
              <AButton
                size="large"
                block
                type="primary"
                :loading="formRef?.isCreating"
                @click="createOperationCategory"
                >Создать</AButton
              >
            </ACol>
          </ARow>
        </template>
      </APageDrawer>
      <AButton v-if="!hideLink" type="link" @click="openForm">Создать новую статью</AButton>
      <VNodeRender :node="menuNode" />
      <TransitionFade :easing="{ enter: '1s', leave: '1s' }">
        <LoadingOption v-if="isListingLoading" />
      </TransitionFade>
    </template>
    <template #tagRender="{ label }">
      <ATag color="blue">{{ label }}</ATag>
    </template>
    <template #maxTagPlaceholder="omitedVals">
      <ATag color="blue" :style="{ marginRight: 0 }">+{{ omitedVals.length }}</ATag>
    </template>
  </ASelect>
</template>

<style scoped>
.operation-category-select:deep(
    .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest
  ):deep(.ant-select-selection-item-content) {
  margin: 0;
}
.operation-category-select:deep(
    .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest
      .ant-select-selection-item
  ) {
  background: none;
  border: none;
  margin: 0;
  width: auto;
  height: auto;
  padding: 0;
}
</style>
