<script setup lang="ts">
import { injectProfile, injectProfileLoading } from "@/modules/profile"

import ProfileWrapper from "../ProfileWrapper/ProfileWrapper.vue"
import { useProfileView } from "../ProfileDescription/use-profile-view"

const profile = injectProfile()
const isLoading = injectProfileLoading()
const profileView = useProfileView(profile)
</script>

<template>
  <ASpin :spinning="isLoading">
    <ProfileWrapper title="Занято места" class="content">
      <ATypographyText class="description">{{
        `${profileView?.storage?.usage_mb} МБ`
      }}</ATypographyText>
    </ProfileWrapper>
  </ASpin>
</template>

<style scoped>
.description {
  font-size: 28px;
}

.content {
  min-height: 166px;
}
</style>
