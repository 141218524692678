<script setup lang="ts">
import { Divider, Form, type FormInstance, theme } from "ant-design-vue"
import { get } from "@vueuse/core"
import { computed, h, ref, watch } from "vue"
import { QuestionCircleOutlined } from "@ant-design/icons-vue"

import { BankAccountSelect } from "@/modules/bank-account"
import { ProjectSelect } from "@/modules/project"
import { OperationCategoryTreeSelect } from "@/modules/operation-category"
import { CounterpartiesSelect } from "@/modules/counterparty"
import { RepeatPeriodSelect } from "@/components"
import { AButton, usePopupContainerGetter } from "@/package/ui-kit"
import { useForm } from "@/package/hooks"
import { isValidateError } from "@/package/util"

import OperationTypeSelect from "../../OperationTypeSelect.vue"
import { OperationAction } from "../../../interface"
import { useAmountSplit, useOutgoingFormStateInject, useOutgoingRuleset } from "../hooks"
import { showRepeatPeriodModal } from "../util"

import OperationSplit from "./OperationSplit.vue"
import OperationAttachmentUploadInput from "./OperationAttachmentUploadInput.vue"

const props = defineProps({
  disableSplit: {
    type: Boolean,
    default: false
  },

  validateImmediate: {
    type: Boolean,
    default: false
  },

  cleanFilesOnChange: {
    type: Boolean,
    default: false
  },

  repeatDisabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(["submit"])

const { formState, formData, defaultTransaction, isFileUploadingDisabled } =
  useOutgoingFormStateInject(props)
const { isSplited } = useAmountSplit(formState)

const ruleset = useOutgoingRuleset(formState)
const { validate, validateInfos } = useForm(formState, ruleset, {
  immediate: get(props, "validateImmediate")
})

const outgoingFormRef = ref<FormInstance | null>(null)
watch(
  () => formState.is_repeat,
  (is_repeat) => {
    if (is_repeat) {
      setTimeout(
        () =>
          outgoingFormRef.value?.scrollToField("repeat_finish_at", {
            behavior: "smooth",
            block: "start"
          }),
        0
      )
    }
  }
)
const submitAction = () =>
  validate()
    .then(() => emit("submit", formData))
    .catch((err) => {
      if (isValidateError(err)) {
        outgoingFormRef.value?.scrollToField(err.errorFields[0].name, {
          block: "start",
          boundary: (parent: HTMLElement) => {
            parent.focus()
            return parent.classList.contains("ant-row")
          }
        })
      }
    })
defineExpose({ submitAction })

const { token } = theme.useToken()
const popupContainerGetter = usePopupContainerGetter()
const isDisabledUpload = computed(() => formState.is_repeat || isFileUploadingDisabled.value)
</script>

<template>
  <Form
    ref="outgoingFormRef"
    class="outgoing-form"
    name="outgoing-operation"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    scroll-to-first-error
    :style="{ paddingBottom: '16px' }"
    @submit.prevent="submitAction"
  >
    <AFormItem
      class="outgoing-form__item"
      label="Сумма"
      name="amount"
      v-bind="validateInfos?.amount"
    >
      <AInputPrice
        v-model="formState.amount"
        :disabled="isSplited"
        size="large"
        placeholder="Введите сумму"
      />
    </AFormItem>
    <template v-if="!disableSplit">
      <OperationSplit
        v-bind="validateInfos?.transactions"
        v-model="formState.transactions"
        class="outgoing-form__item"
        :operation-action="OperationAction.outgoing"
        :amount="formState.amount"
      />
      <Divider class="outgoing-form__divider" :style="{ margin: '0 0 16px' }" />
    </template>
    <AFormItem class="outgoing-form__item" label="Дата" name="date" v-bind="validateInfos?.date">
      <ADatePicker
        v-model="formState.date"
        size="large"
        :style="{ width: '100%' }"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AFormItem
      class="outgoing-form__item"
      label="Расчетный счет"
      name="bank_account_from_id"
      v-bind="validateInfos?.bank_account_from_id"
    >
      <BankAccountSelect
        v-model="formState.bank_account_from_id"
        size="large"
        placeholder="Выберите счет"
        :get-popup-container="popupContainerGetter"
        filter-active
      />
    </AFormItem>
    <AFormItem
      class="outgoing-form__item"
      label="Вид операции"
      name="type"
      v-bind="validateInfos?.type"
    >
      <OperationTypeSelect
        v-model:value="formState.type"
        size="large"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AFormItem class="outgoing-form__item" label="Cтатья" name="operation_category_id">
      <OperationCategoryTreeSelect
        v-model:value="defaultTransaction.outcome_operation_category_id"
        :multiple="false"
        action-type="expense"
        :disabled="isSplited"
        size="large"
        placeholder="Выберите статью"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AFormItem class="outgoing-form__item" label="Проект" name="project_id">
      <ProjectSelect
        v-model="defaultTransaction.project_id"
        :disabled="isSplited"
        size="large"
        placeholder="Выберите проект"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AFormItem class="outgoing-form__item" label="Контрагент" name="counterparty_id">
      <CounterpartiesSelect
        v-model:selectedId="defaultTransaction.counterparty_id"
        :disabled="isSplited"
        size="large"
        placeholder="Выберите контрагента"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AFormItem class="outgoing-form__item" label="Теги" name="tags" v-bind="validateInfos?.tags">
      <ASelect
        v-model:value="formState.tags"
        mode="tags"
        placeholder="Введите теги"
        size="large"
        :open="false"
        class="tag-select"
        max-tag-count="responsive"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AFormItem
      class="outgoing-form__item"
      label="Назначение платежа"
      name="purpose"
      v-bind="validateInfos?.purpose"
    >
      <ATextarea
        v-model:value="formState.purpose"
        mode="tags"
        placeholder="Введите назначение платежа"
        size="large"
        allow-clear
      />
    </AFormItem>
    <Divider class="outgoing-form__divider" :style="{ margin: '16px 0' }" />
    <AFlex class="outgoing-form__item" justify="space-between">
      <ACheckbox v-model:checked="formState.is_repeat" :disabled="repeatDisabled">
        Повторять операцию
      </ACheckbox>
      <AButton
        size="small"
        type="text"
        :style="{ color: token.colorPrimary }"
        :icon="h(QuestionCircleOutlined)"
        @click="showRepeatPeriodModal"
      />
    </AFlex>
    <template v-if="formState.is_repeat">
      <AFormItem
        :style="{ marginTop: '16px' }"
        class="outgoing-form__item"
        label="Периодичность повтора"
        name="repeat_period"
        v-bind="validateInfos?.repeat_period"
      >
        <RepeatPeriodSelect
          v-model:value="formState.repeat_period"
          :disabled="repeatDisabled"
          placeholder="Выберите период"
          size="large"
          :get-popup-container="popupContainerGetter"
        />
      </AFormItem>
      <AFormItem
        class="outgoing-form__item"
        label="Повторять операцию до"
        name="repeat_finish_at"
        v-bind="validateInfos?.repeat_finish_at"
      >
        <ADatePicker
          v-model="formState.repeat_finish_at"
          :disabled="repeatDisabled"
          size="large"
          :style="{ width: '100%' }"
          :get-popup-container="popupContainerGetter"
        />
      </AFormItem>
    </template>
    <AFormItem
      class="outgoing-form__item"
      name="files"
      v-bind="validateInfos?.files"
      style="margin-top: 16px"
    >
      <div v-if="formState.is_repeat" style="margin-bottom: 10px">
        Вложение недоступно для операций с повтором
      </div>
      <OperationAttachmentUploadInput v-model="formState.files" :disabled="isDisabledUpload" />
    </AFormItem>
  </Form>
</template>

<style scoped>
.outgoing-form {
  display: grid;
  grid-template-columns: 24px calc(100% - 48px) 24px;
}

.outgoing-form__divider {
  grid-column: 1/-1;
}

.outgoing-form__item {
  grid-column: 2/3;
}
</style>

<style>
.tag-select > .ant-select-selector > .ant-select-selection-overflow {
  flex-wrap: wrap !important;
}
</style>
