import { useAxios } from "@vueuse/integrations/useAxios"
import { computed } from "vue"
import { get } from "@vueuse/core"

import { client, useApiError } from "@/package/api-client"
import type { BankAccountSelectVariant } from "@/modules/bank-account"

interface Response {
  success: boolean
  data: Array<BankAccountSelectVariant>
}

export const useBankAccountVariantsTree = () => {
  const { execute, isLoading, error, data } = useAxios<Response>(
    "/bank-accounts/variants/as-tree",
    { method: "GET" },
    client,
    { immediate: false }
  )

  const requestListing = (params?: {
    offset?: number
    limit?: number
    query?: string
    action_type?: string
    operation_category_id?: number | string
  }) => execute({ params })

  const listing = computed(() => get(data)?.data ?? [])

  const idList = computed(() =>
    listing.value.reduce((bankAccountsIds: Array<number>, bankAccount) => {
      const newIds = []
      newIds.push(bankAccount.id)
      bankAccount.children?.forEach((child) => newIds.push(child.id))
      return bankAccountsIds.concat(newIds)
    }, [])
  )
  const unwrapChildrens = (
    arr: Array<BankAccountSelectVariant>
  ): Array<Pick<BankAccountSelectVariant, "id" | "name">> =>
    arr.flatMap(({ children, id, name }) => [{ id, name }].concat(unwrapChildrens(children ?? [])))
  const flatList = computed(() =>
    unwrapChildrens(get(listing)).reduce((res, v) => {
      res.set(v.id, v)
      return res
    }, new Map())
  )

  return {
    listing,
    idList,
    flatList,
    requestListing,
    isListingLoading: isLoading,
    error: useApiError(error)
  }
}
