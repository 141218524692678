<script setup lang="ts">
import type { VueNode } from "ant-design-vue/lib/_util/type"
import type { TableRowSelection } from "ant-design-vue/es/table/interface"

import { computed, ref } from "vue"
import { get } from "@vueuse/core"
import { storeToRefs } from "pinia"

import { type GeneralListingElement, ListingElementType, TextOrTagCell } from "@/modules/operation"
import { useAdoptable } from "@/package/ui-kit"
import { useOperationDuplicateStore } from "@/modules/operation-duplicate"
import { useBufferSelection } from "@/package/hooks"

import { useDuplicateOperationsListView, useOpertaionIcons, useColumns } from "../hooks"

const { columns, handleResizeColumn } = useColumns()
const store = useOperationDuplicateStore()
const { operations, operationsIdList, getOperationsLength } = storeToRefs(store)
const {
  isAllSelected,
  selectedCount,
  selectedIdList,
  onSelect,
  onSelectAll,
  toggleAllSelected,
  unselectAllAction,
  excludeKeysMode,
  currentModeKeys
} = useBufferSelection(operationsIdList, getOperationsLength)

const selectedOperationsSum = computed(() => {
  return operations.value.reduce((accum: number, current) => {
    if (selectedIdList.value.includes(current.id)) {
      return current.action === "incoming" || current.action === "transfer"
        ? accum + +current.amount
        : accum - +current.amount
    } else {
      return accum
    }
  }, 0)
})

const rowSelectConfig = computed(
  (): TableRowSelection => ({
    columnWidth: 35,
    onChange: (keys) => {
      const selecetableKeys = (keys as Array<number>).filter(
        (el) => el && store.operationsIdList.includes(el)
      )
      onSelect(selecetableKeys)
    },
    onSelectAll: () => {
      onSelectAll(!get(isAllSelected))
    },
    preserveSelectedRowKeys: true,
    selectedRowKeys: get(selectedIdList),
    renderCell: (
      value: boolean,
      record: GeneralListingElement,
      index: number,
      originNode: VueNode
    ) => {
      if (record.elementType === ListingElementType.operation) {
        return originNode
      }
      return null
    }
  })
)

defineExpose({
  isAllSelected,
  selectedCount,
  toggleAllSelected,
  unselectAllAction,
  excludeKeysMode,
  currentModeKeys,
  selectedOperationsSum
})

const isAdoptable = useAdoptable()
const operationCellMap = useOpertaionIcons()

const tableWrapper = ref<HTMLElement | null>(null)

const formattedList = computed(() => useDuplicateOperationsListView(operations.value).value ?? [])
</script>

<template>
  <div ref="tableWrapper" :style="{ overflow: 'auto', height: '100%' }">
    <ATable
      size="middle"
      :sticky="{ offsetHeader: 0, offsetScroll: 0, getContainer: () => tableWrapper }"
      class="custom-table"
      :columns="columns"
      :data-source="formattedList"
      :show-header="!isAdoptable"
      :pagination="false"
      row-key="id"
      :row-selection="rowSelectConfig"
      @resizeColumn="handleResizeColumn"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'date' && record.elementType === ListingElementType.divider">
          <span class="divider-cell-date">{{ record.date }}</span>
        </template>
        <template v-if="column.key === 'action'">
          <component
            :is="operationCellMap[record.action as keyof typeof operationCellMap]"
            :class="`operation-amount--${record.action}`"
          />
        </template>
        <template v-if="column.key === 'amount'">
          <AText :class="`operation-amount--${record.action}`">
            {{ record.amount }}
          </AText>
        </template>
        <template
          v-if="
            column.key === 'counterparty_name' &&
            record.elementType === ListingElementType.operation
          "
        >
          <TextOrTagCell :count="record.counterparty?.count" :name="record.counterparty?.name" />
        </template>
        <template
          v-if="
            column.key === 'category_name' && record.elementType === ListingElementType.operation
          "
        >
          <TextOrTagCell :count="record.category?.count" :name="record.category?.name" />
        </template>
      </template>
    </ATable>
  </div>
</template>

<style scoped>
.custom-table {
  max-width: calc(100% - 2px);
}

.custom-table:deep(.ant-table-wrapper),
.custom-table:deep(.ant-spin-container),
.custom-table:deep(.ant-table-container),
.custom-table:deep(.ant-spin-nested-loading),
.custom-table:deep(.ant-table-content table) {
  height: 100%;
}

.custom-table:deep(.ant-spin-container) {
  display: flex;
  flex-direction: column;
}
.custom-table:deep(.ant-table-container)::after,
.custom-table:deep(.ant-table-container)::before {
  content: none !important;
}

:deep(.ant-table-wrapper .ant-table-row-expand-icon) {
  border: 1px solid black;
  border-radius: 0;
}

:deep(.ant-table-wrapper .ant-table-row-expand-icon:before),
:deep(.ant-table-wrapper .ant-table-row-expand-icon:after) {
  color: black;
}

.divider-cell-date {
  white-space: nowrap;
  font-size: 16px;
  font-weight: 700;
  display: block;
}

:deep(.operation-amount--incoming) {
  color: var(--incoming-operation-color);
}

:deep(.operation-amount--outgoing) {
  color: var(--outgoing-operation-color);
}

:deep(.operation-amount--transfer) {
  color: var(--transfer-operation-color);
}
</style>
