<script setup lang="ts">
import { useRouter } from "vue-router"
import { useToggle } from "@vueuse/core"
import { h, ref } from "vue"
import { CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons-vue"
import { theme } from "ant-design-vue"
import { OverlayScrollbarsComponent } from "overlayscrollbars-vue"

import { FinanceOperationsDetailRouteName, FinanceOperationsRouteName } from "@/router"
import { useOperationImportStore, OperationsImportForm } from "@/modules/operation"
import {
  OperationsImportDuplicateModal,
  useOperationDuplicateStore
} from "@/modules/operation-duplicate"
import { AButton, InfoModal } from "@/package/ui-kit"
import { useChannelErrorBus, useImportBus, useOperationsDuplicateBus } from "@/modules/centrifugo"

const operationDuplicateStore = useOperationDuplicateStore()

const router = useRouter()
const openListingAction = () => router.push({ name: FinanceOperationsRouteName })

const store = useOperationImportStore()
const isModalOpened = ref(false)

const importBus = useImportBus()
const channelErrorBus = useChannelErrorBus()
const operationsDuplicateBus = useOperationsDuplicateBus()
channelErrorBus.on(() => {
  store.toggleImportStatus(false)
})
importBus.on((event) => {
  store.toggleImportStatus(false)
  if (event === "success" && !isModalOpened.value) {
    openListingAction()
  }
})
operationsDuplicateBus.on((event, params) => {
  if (event === "success") {
    isModalOpened.value = false
    openListingAction()
  } else if (event === "error") {
    if (params) {
      const operations = JSON.parse(params?.message)
      operationDuplicateStore.addDuplicateOperations(operations)
      if (operations) {
        isModalOpened.value = true
      }
    }
  }
})

const showAnnotationModal = () =>
  InfoModal({ content: "Загрузите файл в формате .xlsx, .xls", title: "Импорт файла" })

const [isOpened, toggleOpened] = useToggle(true)
const closeAction = () => toggleOpened(false)
const closeDuplicateAction = () => {
  operationDuplicateStore.$reset()
  isModalOpened.value = false
  openListingAction()
}

const { token } = theme.useToken()
const formRef = ref<InstanceType<typeof OperationsImportForm> | null>(null)

const openDetailPage = (id: number | string) => {
  const url = router.resolve({ name: FinanceOperationsDetailRouteName, params: { id } }).href
  window.open(url, "_blank")
}
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    :closeable="false"
    custom-scroll-body
    :body-style="{
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      '--body-color': token.colorBgBase
    }"
    @onClose="openListingAction"
  >
    <AFlex
      :style="{
        padding: '24px 24px 8px',
        position: 'sticky',
        top: 0,
        left: 0,
        backgroundColor: token.colorBgBase,
        zIndex: 1000
      }"
    >
      <ATypographyTitle :level="4" :style="{ margin: 0 }">Импорт</ATypographyTitle>
      <AButton
        :style="{ position: 'absolute', right: '20px', top: '24px' }"
        type="link"
        class="ant-drawer-close"
        :icon="h(CloseOutlined)"
        @click="closeAction"
      />
    </AFlex>
    <AFlex
      :style="{
        padding: '0 24px',
        display: 'flex',
        justifyContent: 'space-between',
        top: 0,
        left: 0,
        backgroundColor: token.colorBgBase
      }"
    >
      <ATypographyTitle :level="5" :style="{ margin: 0 }">Загрузка файла</ATypographyTitle>
      <AButton
        size="small"
        type="link"
        :icon="h(QuestionCircleOutlined)"
        :style="{ color: token.colorPrimary }"
        @click="showAnnotationModal"
      />
    </AFlex>
    <OverlayScrollbarsComponent :style="{ maxHeight: '100%' }">
      <OperationsImportForm ref="formRef" />
    </OverlayScrollbarsComponent>
    <template #footer>
      <AButton
        block
        type="primary"
        size="large"
        :loading="store.isFileImporting"
        @click="formRef?.submitAction"
        >Импортировать</AButton
      >
    </template>
    <OperationsImportDuplicateModal
      v-model:isModalOpened="isModalOpened"
      @onClose="closeDuplicateAction"
      @openDetail="openDetailPage"
    />
  </APageDrawer>
</template>

<!--suppress CssUnresolvedCustomProperty -->
<style scoped>
.operation-type-tabs:deep(.ant-tabs-tab:first-child) {
  margin-left: 24px;
}

.operation-type-tabs:deep(.ant-tabs-nav) {
  position: sticky;
  top: 64px;
  left: 0;
  z-index: 1000;
  background-color: var(--body-color);
}

.operation-table {
  max-height: 450px;
  overflow: auto;
}
</style>
