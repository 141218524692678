import { useAxios } from "@vueuse/integrations/useAxios"
import { computed, h, watch } from "vue"

import { ApiBadReqResponse, client, useApiError } from "@/package/api-client"
import { InfoModal } from "@/package/ui-kit"

export const useFileImport = () => {
  const { data, error, execute } = useAxios(
    "/operations/import-excel-data",
    { method: "POST" },
    client,
    {
      immediate: false
    }
  )

  const errorArray = computed(() => {
    let result = null
    if (error.value) {
      if (error.value instanceof ApiBadReqResponse) {
        result = error.value.errorArray
      }
    }
    return result
  })

  watch(errorArray, () => {
    if (errorArray.value) {
      InfoModal({
        content: errorArray.value.map((message) => h("p", message)),
        title: "Ошибка загрузки",
        okText: "Ок"
      })
    }
  })
  const fileImport = async (id: number, requestData: any) => {
    await execute({ params: { id }, data: requestData })
  }

  const apiError = useApiError(error)
  return {
    importedFileData: data,
    errorArray,
    fileImport,
    error: apiError
  }
}
