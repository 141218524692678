import { defineStore } from "pinia"
import { computed, ref } from "vue"
import { get, set } from "@vueuse/core"

import type { OperationListElementDTO } from "@/modules/operation"

export const useOperationDuplicateStore = defineStore("OperationDuplicate", () => {
  const operations = ref<OperationListElementDTO[]>([])
  const addDuplicateOperations = (newDuplicates: OperationListElementDTO[]) => {
    operations.value = [...operations.value, ...newDuplicates]
  }

  const getOperationsLength = computed(() => operations.value.length)

  const operationsIdList = computed(() => get(operations.value).map(({ id }) => id))

  const countTrashOperations = computed(() => {
    return operations.value.filter((item) => item.is_trash).length ?? 0
  })

  const removeDuplicateOperationsByIds = (ids: number[]) => {
    operations.value = operations.value.filter((operation) => !ids.includes(operation.id))
  }

  const $reset = () => {
    set(operations, [])
  }

  return {
    operations,
    addDuplicateOperations,

    getOperationsLength,
    operationsIdList,
    countTrashOperations,
    removeDuplicateOperationsByIds,

    $reset
  }
})
