<script setup lang="ts">
import { ref, computed, onMounted } from "vue"
import dayjs from "dayjs"

import { numWord } from "@/package/util"
import { injectProfile } from "@/modules/profile"

defineProps({
  always: {
    type: Boolean,
    default: false
  }
})

const SECONDS_IN_TWO_DAYS = 172800

const profile = injectProfile()

const daysLeft = computed(() => {
  if (profile.value?.days_left === 0) {
    return "сегодня"
  } else if (profile.value?.days_left === 1) {
    return "завтра"
  }
  return `через ${profile.value?.days_left} ${dayWordEnding.value}`
})

const lastClosed = ref(localStorage.getItem("lastClosedSubscriptionReminder") || null)

const currentTime = dayjs()
const showSubscriptionReminder = ref(false)

onMounted(() => {
  if (profile.value?.email_verified_at) {
    const verificationTime = dayjs(profile.value?.email_verified_at)
    const secondsSinceLastClosed = lastClosed.value
      ? currentTime.diff(dayjs(lastClosed.value), "seconds")
      : null

    if (profile.value?.is_trial && profile.value?.days_left) {
      const intervalsPassed = currentTime.diff(verificationTime, "day") / 2

      if (
        Number.isInteger(intervalsPassed) &&
        (!secondsSinceLastClosed || secondsSinceLastClosed > SECONDS_IN_TWO_DAYS)
      ) {
        showSubscriptionReminder.value = true
      }
    }
  }
})

function closeReminder() {
  showSubscriptionReminder.value = false
  localStorage.setItem("lastClosedSubscriptionReminder", dayjs().toISOString())
}

const dayWordEnding = computed(() =>
  numWord(profile.value?.days_left || 0, ["день", "дня", "дней"])
)
</script>

<template>
  <AAlert
    v-if="always ? profile?.is_trial : showSubscriptionReminder"
    :style="{ marginBottom: '20px', alignItems: 'center' }"
    type="error"
    :closable="!always"
    @close="closeReminder"
  >
    <template #message>
      Тестовая подписка "Про" закончится {{ daysLeft }}.
      <RouterLink to="/subscription">Оформите подписку</RouterLink> и используйте все функции
    </template>
  </AAlert>
</template>

<style scoped>
.subscription-reminder {
  background-color: #ffcccc;
  color: red;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
  border: 1px solid red;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
</style>
