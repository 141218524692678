import { computed, readonly, ref } from "vue"
import { get, set } from "@vueuse/core"

import { type OffsetPaginationDefaultDto, PAGINATION_DEFAULT_STATE } from "@/interfaces"

export const usePagination = () => {
  const pagination = ref(PAGINATION_DEFAULT_STATE)
  const paginationTotal = computed(() => get(pagination).total ?? 0)
  const paginationTotalAll = computed(() => get(pagination).total_all_count ?? 0)
  const currentOffset = computed(() => get(pagination).offset ?? 0)

  const setPagination = (state?: OffsetPaginationDefaultDto) => {
    if (state === undefined) {
      set(pagination, PAGINATION_DEFAULT_STATE)
    } else {
      set(pagination, state)
    }
  }
  const decrementTotal = () => {
    const pgntn = get(pagination)
    if (pgntn.total) {
      pgntn.total -= 1
      set(pagination, pgntn)
    }
  }
  return {
    pagination: readonly(pagination),
    paginationTotal,
    paginationTotalAll,
    currentOffset,
    setPagination,
    decrementTotal
  }
}
