import locale from "ant-design-vue/es/date-picker/locale/ru_RU"

export const gridGutter = [24, 0]
export const datepickerLocale = (() => {
  const result = locale
  result.monthFormat = "MMM"
  result.lang.monthFormat = "MMM"
  return result
})()
export const PAGE_DRAWER_DEFAULT_WIDTH = 572

export const MAX_Z_INDEX = 100000000000
export const DATE_MASK = "DD-MM-YYYY"
export const DATETIME_MASK = "DD-MM-YYYY HH:mm"
export const DATETIME_DOTS_MASK = "DD.MM.YYYY HH:mm"

export const APHEX_CHART_LOCALIZATION = {
  defaultLocale: "ru",
  locales: [
    {
      name: "ru",
      options: {
        months: [
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь"
        ],
        shortMonths: [
          "Янв",
          "Фев",
          "Мар",
          "Апр",
          "Май",
          "Июн",
          "Июл",
          "Авг",
          "Сен",
          "Окт",
          "Ноя",
          "Дек"
        ],
        days: ["Понидельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресение"],
        shortDays: ["Пн", "Вт", "Ср", "Чn", "Пт", "Сб", "Вс"],
        toolbar: {
          download: "Скачать",
          selection: "Выбор",
          selectionZoom: "Увеличить выбранное",
          zoomIn: "Увличить",
          zoomOut: "Отдалить",
          pan: "Панорамирование",
          reset: "Сбросить увеличение",
          exportToSVG: "Скачать SVG",
          exportToPNG: "Скачать PNG",
          exportToCSV: "Скачать CSV"
        }
      }
    }
  ]
}
