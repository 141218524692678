import type { TableProps } from "ant-design-vue"
import type { ColumnsType } from "ant-design-vue/es/table"

import { computed, type Ref, h, ref, watch } from "vue"
import { Flex, Tag, TypographyText } from "ant-design-vue"

import {
  type PaymentListingData,
  usePaymentStore,
  PaymentStatus,
  PaymentStatusDict
} from "@/modules/payment"
import { ALink, AText, renderPlaceholder, useAdoptable, viewValueOrDefault } from "@/package/ui-kit"
import { SortOrder, SortOrderInternal } from "@/interfaces"

const defaultSizes = {}
const adoptedColumns: ColumnsType<PaymentListingData> = [
  {
    key: "last_name",
    customRender: ({ record }) =>
      h(Flex, { vertical: true }, () => [
        h(
          Flex,
          {
            style: { width: "100%", fontSize: "12px" },
            justify: "space-between",
            gap: 8
          },
          () => [
            h(ALink, { to: `/admin/payment/${record.user_id}` }, () => [
              record.last_name,
              " ",
              record.first_name,
              " "
            ]),
            h(TypographyText, { strong: false }, () => [record.amount, " ", "руб."]),
            h(
              Tag,
              {
                color: record.status === PaymentStatus.active ? "success" : "error",
                style: { height: "fit-content" }
              },
              () => viewValueOrDefault(PaymentStatusDict[record.status as PaymentStatus])
            )
          ]
        ),
        h(AText, { type: "secondary", style: { marginTop: "8px" }, content: record.middle_name })
      ])
  }
]
const defaultColumns = (): ColumnsType<PaymentListingData> => {
  const store = usePaymentStore()

  return [
    {
      title: "GUID",
      key: "id",
      dataIndex: "user_id",
      ellipsis: { showTitle: true },
      customRender: renderPlaceholder,
      sorter: true,
      sortOrder: computed(() => {
        if (!store.sortOrder || store.sortBy !== "user_id") {
          return null
        }
        return store.sortOrder === SortOrder.ASC
          ? SortOrderInternal.ASCEND
          : SortOrderInternal.DESCEND
      }) as unknown as SortOrderInternal | null
    },
    {
      title: "Фамилия",
      dataIndex: "last_name",
      ellipsis: { showTitle: true },
      sorter: true,
      sortOrder: computed(() => {
        if (!store.sortOrder || store.sortBy !== "last_name") {
          return null
        }
        return store.sortOrder === SortOrder.ASC
          ? SortOrderInternal.ASCEND
          : SortOrderInternal.DESCEND
      }) as unknown as SortOrderInternal | null
    },
    {
      title: "Имя",
      dataIndex: "first_name",
      ellipsis: {
        showTitle: true
      },
      customRender: renderPlaceholder,
      sorter: true,
      sortOrder: computed(() => {
        if (!store.sortOrder || store.sortBy !== "first_name") {
          return null
        }
        return store.sortOrder === SortOrder.ASC
          ? SortOrderInternal.ASCEND
          : SortOrderInternal.DESCEND
      }) as unknown as SortOrderInternal | null
    },
    {
      title: "Отчество",
      dataIndex: "middle_name",
      ellipsis: {
        showTitle: true
      },
      customRender: renderPlaceholder,
      sorter: true,
      sortOrder: computed(() => {
        if (!store.sortOrder || store.sortBy !== "middle_name") {
          return null
        }
        return store.sortOrder === SortOrder.ASC
          ? SortOrderInternal.ASCEND
          : SortOrderInternal.DESCEND
      }) as unknown as SortOrderInternal | null
    },
    {
      title: "Сумма, руб",
      dataIndex: "amount",
      ellipsis: {
        showTitle: true
      },
      customRender: ({ record }) => {
        return record?.amount ?? "—"
      },
      sorter: true,
      sortOrder: computed(() => {
        if (!store.sortOrder || store.sortBy !== "amount") {
          return null
        }
        return store.sortOrder === SortOrder.ASC
          ? SortOrderInternal.ASCEND
          : SortOrderInternal.DESCEND
      }) as unknown as SortOrderInternal | null
    },
    {
      title: "Дата и время",
      dataIndex: "datetime",
      ellipsis: {
        showTitle: true
      },
      customRender: renderPlaceholder,
      sorter: true,
      sortOrder: computed(() => {
        if (!store.sortOrder || store.sortBy !== "datetime") {
          return null
        }
        return store.sortOrder === SortOrder.ASC
          ? SortOrderInternal.ASCEND
          : SortOrderInternal.DESCEND
      }) as unknown as SortOrderInternal | null
    },
    {
      title: "Статус",
      dataIndex: "status",
      ellipsis: {
        showTitle: true
      },
      customRender: ({ value }) =>
        h(Tag, { color: value === PaymentStatus.active ? "success" : "error" }, () =>
          viewValueOrDefault(PaymentStatusDict[value as PaymentStatus])
        )
    }
  ]
}

export const useColumns = () => {
  const columns: Ref<TableProps["columns"]> = ref()

  const handleResizeColumn: TableProps["onResizeColumn"] = (w, col) => {
    col.width = w
  }
  const resetColSizesAction = () => {
    if (columns.value) {
      for (const col of columns.value) {
        const key = col.key
        if (typeof key === "string" && key in defaultSizes) {
          col.width = defaultSizes[key as keyof typeof defaultSizes]
        }
      }
    }
  }
  const isAdoptable = useAdoptable()
  watch(
    isAdoptable,
    (adopted) => {
      if (adopted) {
        columns.value = adoptedColumns
      } else {
        columns.value = defaultColumns()
      }
    },
    { immediate: true }
  )

  return {
    resetColSizesAction,
    handleResizeColumn,
    columns
  }
}
