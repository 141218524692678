<script setup lang="ts">
import { useRouter } from "vue-router"
import { ref, h, computed, watch } from "vue"
import {
  PlusOutlined,
  ExpandAltOutlined,
  ShrinkOutlined,
  DeleteOutlined
} from "@ant-design/icons-vue"
import { TransitionSlide } from "@morev/vue-transitions"
import { noop, watchThrottled } from "@vueuse/core"
import { storeToRefs } from "pinia"
import { push } from "notivue"

import {
  OperationsCategoriesCreateRouteName,
  OperationsCategoriesCreateTemplateRouteName,
  OperationsCategoriesDetailRouteName,
  OperationsCategoriesEditRouteName
} from "@/router"
import { DeleteConfirm, useAdoptable } from "@/package/ui-kit"
import {
  useOperationCategoriesStore,
  OperationCategoryListing,
  ActionTypeRadioList,
  useCategoryActionTypes
} from "@/modules/operation-category"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { ApiNotFoundResponse } from "@/package/api-client"

const tableRef = ref<null | InstanceType<typeof OperationCategoryListing>>(null)
const searchQuery = ref("")
watchThrottled(
  searchQuery,
  (value) => {
    store.searchListing(value)
  },
  { throttle: 1000, leading: false }
)

const store = useOperationCategoriesStore()

const { paginationTotalAll, categoriesDeleteListError } = storeToRefs(store)

const actionType = computed({
  get: () => store.actionType,
  set: (actionType: string) => {
    searchQuery.value = ""
    store.changeActionType(actionType)
  }
})
const changeActionTypeAction = () => {
  tableRef.value?.shrinkAll()
  tableRef.value?.toggleAllSelected(false)
}

const { loading: categoryActionTypesLoading } = useCategoryActionTypes()

const router = useRouter()
const openCreateAction = () => router.push({ name: OperationsCategoriesCreateRouteName })

const openCreateFromTemplateAction = () =>
  router.push({ name: OperationsCategoriesCreateTemplateRouteName })
const openDetailAction = (id: string | number) =>
  router.push({ name: OperationsCategoriesDetailRouteName, params: { id } })
const openEditAction = (id: string | number) =>
  router.push({ name: OperationsCategoriesEditRouteName, params: { id } })

watch(categoriesDeleteListError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const isAdoptable = useAdoptable()

const allChecked = computed({
  get: () => tableRef.value?.isAllSelected,
  set: (value) => {
    tableRef.value?.toggleAllSelected(value)
  }
})
const summary = computed(() => {
  let result = null
  if (
    tableRef.value &&
    tableRef.value.selectedCount > 0 &&
    !(store.listingApiError?.status === ApiNotFoundResponse.STATUS)
  ) {
    result = `Выбрано ${tableRef.value.selectedCount} из ${paginationTotalAll.value}`
  }
  return result
})
const deleteAction = () => {
  DeleteConfirm({
    content: "Вы действительно хотите безвозвратно удалить выбранные записи?",
    onOk: () => {
      if (tableRef.value !== null) {
        const keys = tableRef.value.currentModeKeys
        let promise = Promise.resolve()
        if (tableRef.value) {
          if (tableRef.value.excludeKeysMode) {
            promise = store.deleteByExcludedIdList(keys)
          } else {
            promise = store.deleteBySelectedIdList(keys)
          }
        }
        return promise
          .then(() => {
            push.success({ message: "Удалено" })
            tableRef.value?.unselectAllAction()
          })
          .catch(noop)
      }
    }
  })
}
</script>

<template>
  <div
    class="radio-list-wrapper"
    :style="{ marginTop: '32px', minHeight: isAdoptable ? '80px' : '40px' }"
  >
    <TransitionSlide>
      <ActionTypeRadioList
        v-if="!categoryActionTypesLoading"
        v-model:value="actionType"
        :size="isAdoptable ? 'normal' : 'large'"
        :class="isAdoptable ? 'radio-list--adopted' : null"
        @change="changeActionTypeAction"
      />
    </TransitionSlide>
    <AButton
      size="large"
      :style="{
        marginLeft: isAdoptable ? 0 : '8px',
        marginTop: isAdoptable ? '8px' : 0,
        width: isAdoptable ? '100%' : 'auto'
      }"
      @click="openCreateFromTemplateAction"
      >Создать справочник по шаблону</AButton
    >
  </div>
  <ARow
    justify="space-between"
    :gutter="[16, 0]"
    :style="{ marginTop: isAdoptable ? '8px' : '32px' }"
  >
    <ACol :xs="isAdoptable ? 0 : undefined">
      <ASpace>
        <AButton default-styling size="large" type="primary" @click="openCreateAction">
          Добавить
        </AButton>
        <AButton
          :icon="h(tableRef?.isExpanded ? ShrinkOutlined : ExpandAltOutlined)"
          default-styling
          size="large"
          type="default"
          @click="tableRef?.expandAllToggle"
        >
          {{ tableRef?.isExpanded ? "Скрыть" : "Показать" }}
        </AButton>
      </ASpace>
    </ACol>
    <ACol :xs="24" :md="12" :lg="8">
      <AInputSearch
        v-model:value="searchQuery"
        placeholder="Название"
        size="large"
        :loading="searchQuery.length > 0 && store.isListingLoading"
        @search="store.searchListing"
      />
    </ACol>
  </ARow>
  <ARow
    :gutter="[16, 0]"
    :style="{
      minHeight: '32px',
      marginTop: '16px',
      justifyContent: 'space-between'
    }"
  >
    <ACol v-if="tableRef && tableRef.selectedCount > 0" :xs="0" :md="24">
      <AFlex justify="space-between">
        <TransitionFade>
          <ASpace v-if="summary !== null" :size="16">
            <ATypographyText :content="summary" ellipsis />

            <AButton
              type="text"
              danger
              :icon="h(DeleteOutlined)"
              :style="{ padding: 0 }"
              @click="deleteAction"
            >
              Удалить
            </AButton>
          </ASpace>
        </TransitionFade>
      </AFlex>
    </ACol>
    <ACol :xs="24" :md="0">
      <AFlex :gap="16" justify="space-between">
        <ACheckbox
          v-model:checked="allChecked"
          :indeterminate="tableRef && tableRef.selectedCount > 1 && !allChecked"
        >
          Выбрать все
        </ACheckbox>

        <div v-if="summary !== null">{{ summary }}</div>
      </AFlex>
    </ACol>
  </ARow>
  <OperationCategoryListing
    ref="tableRef"
    :style="{ marginTop: '16px', flexGrow: 1 }"
    @openDetail="openDetailAction"
    @openCreate="openCreateAction"
    @openEdit="openEditAction"
  />
  <RouterView />
  <AListingControlPanel v-if="isAdoptable">
    <AButton size="large" :icon="h(PlusOutlined)" type="primary" @click="openCreateAction" />
    <AButton
      :icon="h(tableRef?.isExpanded ? ShrinkOutlined : ExpandAltOutlined)"
      default-styling
      size="large"
      type="default"
      @click="() => tableRef?.expandAllToggle()"
    />
    <AButton
      v-if="tableRef && tableRef.selectedCount"
      type="default"
      size="large"
      :icon="h(DeleteOutlined)"
      danger
      @click="deleteAction"
    >
      Удалить
    </AButton>
  </AListingControlPanel>
</template>

<style scoped lang="scss">
.radio-list-wrapper {
  width: 100%;
}

.radio-list--adopted {
  width: 100%;
  display: flex;

  :deep(.ant-radio-button-wrapper) {
    width: 50%;
    text-align: center;
  }
}
</style>
