<script setup lang="ts">
import { storeToRefs } from "pinia"
import { computed, h, ref, watch } from "vue"
import { theme } from "ant-design-vue"
import { DeleteOutlined } from "@ant-design/icons-vue"
import { push } from "notivue"

import { useOperationStore, OperationListing } from "@/modules/operation"
import {
  OperationDuplicateListing,
  useOperationDuplicateStore,
  useOperationsDuplicate
} from "@/modules/operation-duplicate"
import { formatThousands } from "@/package/util"
import { useAdoptable } from "@/package/ui-kit"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"

defineProps({
  isModalOpened: {
    type: Boolean,
    required: true
  }
})

const emit = defineEmits(["update:isModalOpened", "onClose", "openDetail"])

const isAdoptable = useAdoptable()
const token = theme.useToken()
const operationStore = useOperationStore()
const operationDuplicateStore = useOperationDuplicateStore()
const { operations, operationsIdList, getOperationsLength, countTrashOperations } =
  storeToRefs(operationDuplicateStore)
const { isDuplicating, duplicateOperations, duplicateError, isDuplicateFinished } =
  useOperationsDuplicate()

const stopWatch = watch(
  operationsIdList,
  (newVal) => {
    operationStore.addExtraParams({ ids: newVal })
    stopWatch()
  },
  { deep: true, immediate: false }
)

const isAllTrashOperations = computed(
  () => countTrashOperations?.value > 0 && countTrashOperations?.value === operations.value?.length
)

const onDuplicate = async () => {
  try {
    await duplicateOperations(deletedOperationIds.value)
    push.success({
      message: "Дублирование завершено"
    })
    await deleteAction()
  } catch (err) {
    push.error({ message: "Не удалось дублировать операции" })
  }
}
watch([duplicateError, isDuplicateFinished], ([err, finishedStatus]) => {
  if (err && finishedStatus) {
    isDuplicateFinished.value = false
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})
const openDetailPage = (id: number | string) => {
  emit("openDetail", id)
}

const deletedOperationIds = computed(() => {
  if (listingRef.value?.excludeKeysMode) {
    return operationsIdList.value.filter(
      (operation) => !listingRef.value?.currentModeKeys?.includes(operation)
    )
  }
  return listingRef.value?.currentModeKeys || []
})

const deleteAction = async () => {
  operationDuplicateStore.removeDuplicateOperationsByIds(deletedOperationIds.value)
  listingRef.value?.unselectAllAction()
  if (operationsIdList.value.length === 0) {
    emit("onClose")
  }
}

const openTrashPage = () => {
  window.open("/finance/restore-operations", "_blank")
}

const listingRef = ref<InstanceType<typeof OperationDuplicateListing> | null>(null)
const allChecked = computed({
  get: () => listingRef.value?.isAllSelected,
  set: (value) => {
    listingRef.value?.toggleAllSelected(value)
  }
})
const summary = computed(() => {
  let result = null
  if (listingRef.value && listingRef.value?.selectedCount > 0) {
    result = `Выбрано ${listingRef.value?.selectedCount} из ${getOperationsLength.value} на сумму ${formatThousands(listingRef.value?.selectedOperationsSum)}`
  }
  return result
})
</script>

<template>
  <AModal
    :open="isModalOpened"
    width="95%"
    @cancel="emit('onClose')"
    @update:open="(value: boolean) => emit('update:isModalOpened', value)"
  >
    <template #title>
      <ATypographyTitle :style="{ marginBottom: '20px' }" :level="3">
        При импорте были обнаружены дубли операций, которые уже есть в системе
      </ATypographyTitle>
    </template>
    <template #footer>
      <AFlex justify="flex-end" :style="{ marginTop: '16px' }">
        <ACol :xs="24" :sm="6">
          <AButton
            block
            type="primary"
            size="large"
            :disabled="!listingRef?.selectedCount"
            :loading="isDuplicating"
            @click.prevent="onDuplicate"
            >Дублировать</AButton
          >
        </ACol>
      </AFlex>
    </template>
    <ARow :gutter="[16, 16]" justify="space-between" :style="{ marginTop: '32px' }">
      <ACol :xs="24" :sm="12">
        <template v-if="!isAllTrashOperations">
          <ATypographyTitle :style="{ marginBottom: '10px' }" :level="5">
            Операции в системе:
          </ATypographyTitle>
          <div
            class="operation-table"
            :style="{
              '--border-color': token.token.value.colorBorder,
              marginBottom: countTrashOperations ? '16px' : 0
            }"
          >
            <OperationListing is-duplicate @openDetail="openDetailPage" />
          </div>
        </template>
        <AFlex
          v-if="countTrashOperations"
          vertical
          align="center"
          justify="center"
          :style="{ height: isAllTrashOperations ? '100%' : 'auto' }"
        >
          <ATypographyTitle :style="{ marginBottom: '8px' }" :level="5">
            Некоторые операции находятся в корзине
          </ATypographyTitle>
          <AButton
            block
            type="primary"
            size="large"
            :style="{ maxWidth: '250px' }"
            @click="openTrashPage"
            >Открыть корзину</AButton
          >
        </AFlex>
      </ACol>
      <ACol :xs="24" :sm="12">
        <ATypographyTitle :style="{ marginBottom: '10px' }" :level="5">
          Дубликаты операций:
        </ATypographyTitle>
        <div class="operation-table" :style="{ '--border-color': token.token.value.colorBorder }">
          <OperationDuplicateListing ref="listingRef" />
        </div>
        <AFlex
          vertical
          align="flex-start"
          :gap="8"
          :xs="isAdoptable ? 0 : undefined"
          :style="{ marginTop: '8px' }"
        >
          <ACheckbox v-if="isAdoptable" v-model:checked="allChecked">Выбрать все</ACheckbox>
          <AFlex v-if="summary !== null" align="center" :gap="16" class="operation-summary">
            <ATypographyText :content="summary" ellipsis />
            <AButton
              type="text"
              danger
              :icon="h(DeleteOutlined)"
              :style="{ padding: 0 }"
              @click="deleteAction"
            >
              Удалить
            </AButton>
          </AFlex>
        </AFlex>
      </ACol>
    </ARow>
  </AModal>
</template>

<style scoped>
.operation-table {
  height: 400px;
  border: 1px solid var(--border-color);
  overflow: auto;
}
.operation-summary {
  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 8px !important;
  }
}
</style>
