<script setup lang="ts">
import type { Form } from "ant-design-vue"

import { computed, h, onMounted, onUnmounted, type PropType, ref, watch } from "vue"
import { push } from "notivue"
import { storeToRefs } from "pinia"
import { useToggle } from "@vueuse/core"
import dayjs from "dayjs"
import { ExclamationCircleOutlined } from "@ant-design/icons-vue"
import { theme } from "ant-design-vue"

import { isValidateError } from "@/package/util"
import { useOperationCategoriesStore } from "@/modules/operation-category"
import { AButton } from "@/package/ui-kit"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { useForm } from "@/package/hooks"
import { useOperationStore } from "@/modules/operation"
import type { AvailableBankAccounts } from "@/modules/operation"

import BankFormSwitch from "../BankFormSwtich/BankFormSwitch.vue"
import { useBankIntegration } from "../../../../hooks/use-bank-integration"
import { useBankIntegrationPatch } from "../../../../hooks/use-bank-integration-patch"

import { useFormState } from "./hooks/use-form-state"
import { useRuleset } from "./hooks/use-ruleset"

const props = defineProps({
  getContainer: {
    type: [Function, undefined] as PropType<() => HTMLElement | null>,
    default: undefined
  },

  actionType: {
    type: String,
    default: undefined
  },

  authType: {
    type: String,
    default: ""
  },

  integrationId: {
    type: Number,
    default: 0
  }
})

const emit = defineEmits(["success"])

const { formState } = useFormState()
const ruleset = useRuleset()
const { validate, validateInfos, isValidating } = useForm(formState, ruleset)
const operationsStore = useOperationCategoriesStore()
const store = useOperationStore()
const { createError, isCreating, isCreateFinished } = storeToRefs(operationsStore)

watch([createError, isCreateFinished], ([err, finishedStatus]) => {
  if (err && finishedStatus) {
    isCreateFinished.value = false
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const syncFormRef = ref<typeof Form | null>(null)

const submitAction = () =>
  validate()
    // .then(() => operationsStore.createOperationCategories(requestData.value))
    .then((id: number) => {
      push.success({
        message: "Статья создана"
      })
      emit("success", id)
      operationsStore.updateBufferAfterCreate(id)
    })
    .catch((err) => {
      if (isValidateError(err)) {
        syncFormRef.value?.scrollToField(err.errorFields[0].name, {
          block: "start",
          boundary: (parent: HTMLElement) => {
            parent.focus()
            return parent.classList.contains("ant-row")
          }
        })
      }
    })

defineExpose({
  submitAction,
  isCreating: computed(() => isCreating.value || isCreateFinished.value || isValidating.value)
})

onUnmounted(() => {
  isCreateFinished.value = false
})

const { token } = theme.useToken()

const [isFormOpen, toggleFormOpen] = useToggle(false)

const openForm = () => {
  toggleFormOpen(true)
}

const closeForm = () => {
  toggleFormOpen(false)
}

const { patchBankIntegration, patchBankDataError } = useBankIntegrationPatch()

const connect = async (id: string, state: boolean) => {
  if (
    bankIntegrationData.value?.data?.available_bank_accounts.filter((el) => el.number === id)[0]
      .is_connected !== state
  ) {
    await patchBankIntegration(
      props.integrationId as number,
      bankIntegrationData?.value?.data?.filter_date,
      [{ number: id, is_connected: state }]
    )
    await store.updateBuffer()
    await getBankIntegration(props.integrationId as number)
    bankList.value =
      (bankIntegrationData.value?.data?.available_bank_accounts as AvailableBankAccounts[]) ?? []
    convertDate(bankIntegrationData.value?.data.last_sync_date as number)
  }
}

watch(patchBankDataError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const bankList = ref<AvailableBankAccounts[]>([])
const lastSyncDate = ref<any>(null)

const convertDate = (timestamp: number) => {
  lastSyncDate.value = dayjs.unix(timestamp).format("DD.MM.YYYY")
}

const { bankIntegrationData, getBankIntegration, BankIntegrationPending, BankIntegrationError } =
  useBankIntegration()

watch(BankIntegrationError, (err) => {
  if (err?.message) {
    push.error({ message: err.message })
    closeForm()
  }
})

onMounted(async () => {
  await getBankIntegration(props.integrationId as number)

  bankList.value =
    (bankIntegrationData.value?.data?.available_bank_accounts as AvailableBankAccounts[]) ?? []
  convertDate(bankIntegrationData.value?.data.last_sync_date as number)
})
</script>

<template>
  <ASkeleton v-if="BankIntegrationPending" active />
  <template v-else>
    <ATypographyParagraph :style="{ fontSize: '16px', marginBottom: '16px', textAlign: 'center' }"
      >Синхронизация: каждый день в 00:00</ATypographyParagraph
    >
    <AFlex justify="center" align="center" :style="{ marginBottom: '24px' }">
      <ATypographyText type="secondary"
        >САМО импортирует все операции по выбранным счетам с
        {{ dayjs(bankIntegrationData?.data?.filter_date).format("DD.MM.YYYY") }}</ATypographyText
      >
      <APopover placement="bottomRight" :show-cancel="false">
        <template #content>
          <ATypographyText :style="{ margin: 0, maxWidth: '300px', display: 'inline-block' }">
            Если вам необходимо изменить дату, с которой начинается синхронизация операций,
            отключите интеграцию и подключите повторно
          </ATypographyText>
        </template>
        <AButton
          size="small"
          type="ghost"
          :icon="h(ExclamationCircleOutlined)"
          :style="{ color: token.colorPrimary, marginLeft: '8px' }"
        />
      </APopover>
    </AFlex>
    <AButton
      v-if="props.authType !== 'url'"
      default-styling
      type="primary"
      block
      size="large"
      :style="{ marginBottom: '32px' }"
      @click="openForm"
      >Учетные данные</AButton
    >
    <ATypographyParagraph :style="{ fontSize: '16px', marginBottom: '16px' }"
      >Счета банка клиента</ATypographyParagraph
    >
    <AFlex
      v-for="(account, id) in bankIntegrationData?.data
        ?.available_bank_accounts as AvailableBankAccounts[]"
      :key="id"
      justify="space-between"
      :style="{ marginBottom: '16px', fontSize: '16px' }"
    >
      {{ account?.name }} {{ account?.number }}
      <BankFormSwitch
        :id="account?.number as string"
        :state="account?.is_connected"
        :on-checked-change="connect"
      />
    </AFlex>
    <APageDrawer
      v-if="isFormOpen"
      v-model:open="isFormOpen"
      title="Учетные данные"
      class-name="bank-account-drawer"
    >
      <OverlayScrollbarsComponent :style="{ maxHeight: '100%' }">
        <AForm
          ref="syncFormRef"
          name="create-operation-categories-form"
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          autocomplete="off"
          :colon="false"
          scroll-to-first-error
          @submit.prevent="submitAction"
          @keydown.enter.prevent
        >
          <AFormItem label="Логин" name="login" v-bind="validateInfos?.login">
            <AInput v-model="formState.login" size="large" placeholder="Введите логин" />
          </AFormItem>
          <AFormItem
            label="Пароль"
            name="password"
            v-bind="validateInfos?.password"
            :style="{ marginTop: '44px' }"
          >
            <AInput v-model="formState.password" size="large" placeholder="Введите пароль" />
          </AFormItem>
        </AForm>
      </OverlayScrollbarsComponent>

      <template #footer>
        <ARow :gutter="[8, 8]">
          <ACol :sm="24" :md="12" flex="1 1 50%">
            <AButton size="large" block @click="closeForm">Отмена</AButton>
          </ACol>
          <ACol :sm="24" :md="12" flex="1 1 50%">
            <AButton size="large" block type="primary" @click="submitAction">Сохранить</AButton>
          </ACol>
        </ARow>
      </template>
    </APageDrawer>
  </template>
</template>
