<script setup lang="ts">
import { computed, onMounted, type PropType } from "vue"
import { Divider } from "ant-design-vue"
import { useRouteParams } from "@vueuse/router"
import dayjs from "dayjs"

import { viewValueOrDefault, DATETIME_MASK } from "@/package/ui-kit"
import { useGetSubscriptionTypes } from "@/modules/subscription"

import {
  type UserDetail,
  UserPasswordStatus,
  UserPasswordStatusDict,
  UserEmailStatus,
  UserEmailStatusDict,
  UserStatus,
  UserStatusDict
} from "../interface"
import { useUserRoles } from "../hooks/use-user-roles"

const props = defineProps({
  user: {
    type: [Object, undefined] as PropType<UserDetail | undefined>,
    default: () => undefined
  }
})
const userId = useRouteParams<string>("id")

const { userRolesResponse } = useUserRoles()
const role = computed(() => {
  return userRolesResponse.value?.find((roleOption) => roleOption.id === props.user?.role)?.name
})

const { subscriptionsData, getSubscriptions } = useGetSubscriptionTypes()

onMounted(async () => {
  await getSubscriptions()
})

const tariff = computed(() => {
  if (props.user?.subscription?.trial_period) {
    return "PRO"
  }
  if (subscriptionsData) {
    return subscriptionsData?.value?.data
      ?.map((product) =>
        product.tariffs.map((tariff) => ({
          name: product.name,
          id: tariff.id
        }))
      )
      .reverse()
      .flat()
      ?.find((item) => item.id === props.user?.subscription?.tariff)?.name
  }
  return ""
})

const isHasNextPayment = computed(() => {
  if (tariff.value === "FREE") {
    return false
  }

  if (tariff.value === "PRO") {
    const { trial_period, last_payment, tariff_finish_at, is_recurrent } =
      props.user?.subscription || {}
    if (!is_recurrent) {
      return false
    }
    const endDate = dayjs(tariff_finish_at, DATETIME_MASK)

    if (!dayjs().isBefore(endDate)) {
      return false
    }
    return !(trial_period || last_payment?.admin_change)
  }
  return false
})

const usageMb = computed(() => {
  if (props.user === undefined || !props.user?.storage) {
    return "—"
  } else {
    return props.user?.storage?.usage_mb + " МБ"
  }
})
</script>

<template>
  <ADescriptions title="Персональные данные" layout="vertical" :column="12">
    <ADescriptionsItem :span="4" label="Фамилия">{{
      viewValueOrDefault(user?.last_name)
    }}</ADescriptionsItem>
    <ADescriptionsItem :span="4" label="Имя">{{
      viewValueOrDefault(user?.first_name)
    }}</ADescriptionsItem>
    <ADescriptionsItem :span="4" label="Отчество">{{
      viewValueOrDefault(user?.middle_name)
    }}</ADescriptionsItem>
    <ADescriptionsItem :span="4" label="E-mail">{{
      viewValueOrDefault(user?.email)
    }}</ADescriptionsItem>
  </ADescriptions>
  <Divider :style="{ marginBottom: '16px', borderBlockStartWidth: '2px' }" />
  <ADescriptions title="Общее" layout="vertical" :column="24">
    <ADescriptionsItem :span="24" label="GUID">{{ viewValueOrDefault(userId) }}</ADescriptionsItem>
  </ADescriptions>
  <Divider :style="{ marginBottom: '16px', borderBlockStartWidth: '2px' }" />
  <ADescriptions title="Данные пользователя" layout="vertical" :column="24">
    <ADescriptionsItem :span="24" label="Роль">{{ viewValueOrDefault(role) }}</ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Статус">
      <template v-if="user">
        <ATag :color="user.status === UserStatus.active ? 'success' : 'error'">{{
          viewValueOrDefault(UserStatusDict[user.status])
        }}</ATag>
      </template>
      <template v-else>{{ viewValueOrDefault(undefined) }}</template>
    </ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Статус подтверждения почты">
      <template v-if="user">
        <ATag :color="user.status_email === UserEmailStatus.active ? 'success' : 'error'">{{
          viewValueOrDefault(UserEmailStatusDict[user.status_email])
        }}</ATag>
      </template>
      <template v-else>{{ viewValueOrDefault(undefined) }}</template>
    </ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Статус блокировки пароля">
      <template v-if="user">
        <ATag :color="user.status_pass === UserPasswordStatus.active ? 'success' : 'error'">{{
          viewValueOrDefault(UserPasswordStatusDict[user.status_pass])
        }}</ATag>
      </template>
      <template v-else>{{ viewValueOrDefault(undefined) }}</template>
    </ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Занято дискового пространства">{{
      usageMb
    }}</ADescriptionsItem>
  </ADescriptions>
  <Divider :style="{ marginBottom: '16px', borderBlockStartWidth: '2px' }" />
  <ADescriptions title="Данные подписки" layout="vertical" :column="24">
    <ADescriptionsItem :span="24" label="Тариф"
      ><AFlex class="badge" :class="{ blue: tariff === 'PRO' }" justify="center" align="center">
        {{ tariff === "PRO" ? "Про" : "Фри" }}
      </AFlex></ADescriptionsItem
    >
    <ADescriptionsItem :span="12" label="Дата последнего платежа">{{
      viewValueOrDefault(user?.subscription?.tariff_start_at)
    }}</ADescriptionsItem>
    <ADescriptionsItem
      :span="12"
      :label="isHasNextPayment ? 'Дата следующего платежа' : 'Дата окончания подписки'"
      >{{ viewValueOrDefault(user?.subscription?.tariff_finish_at) }}</ADescriptionsItem
    >
    <ADescriptionsItem :span="24" label="Пробный период">
      <template v-if="user">
        <ATag :color="user.subscription?.trial_period ? 'success' : 'error'">{{
          user.subscription?.trial_period ? "Активен" : "Неактивен"
        }}</ATag>
      </template>
      <template v-else>{{ viewValueOrDefault(undefined) }}</template></ADescriptionsItem
    >
  </ADescriptions>
</template>

<style scoped>
.badge {
  border: 1px solid var(--badge-default);
  color: var(--badge-default);
  height: 18px;
  width: 39px;
  border-radius: 4px;
  font-size: 12px;
  line-height: normal;

  &.blue {
    border: 1px solid var(--transfer-operation-color);
    color: var(--transfer-operation-color);
  }
}
</style>
