<script setup lang="ts">
import { onBeforeMount, h } from "vue"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons-vue"
import { useToggle } from "@vueuse/core"
import { push } from "notivue"

import { DeleteConfirm, viewValueOrDefault } from "@/package/ui-kit"
import { formatThousands } from "@/package/util"
import { useLegalEntityStore } from "@/modules/legal-entity"

const emit = defineEmits(["openDetail", "openEdit"])

const store = useLegalEntityStore()

onBeforeMount(async () => {
  await store.getLegalEntitySorting()
})

const [isDeleting, toggleDeleting] = useToggle(false)

const deleteAction = (legalEntityId: string | number) => {
  DeleteConfirm({
    content:
      "Вы действительно хотите безвозвратно удалить запись? Также будет удален счет данной организации",
    onOk: async () => {
      toggleDeleting(true)
      try {
        await store.deleteOne(legalEntityId)
        push.success({ message: "Удалено" })
      } catch (e) {
        /* empty */
      }
      toggleDeleting(false)
    }
  })
}

const loadMore = async () => {
  try {
    if (!store.isLoadedFull && store.listingApiError === null && !store.isSortingLoading) {
      await store.loadBufferNextPage()
    }
  } catch (e) {
    push.error({
      message:
        "Не удалось загрузить список Юр. лиц. Пожалуйста повторите позже или свяжитесь с поддержкой"
    })
  }
}
</script>

<template>
  <ARow :gutter="[16, 16]" :style="{ marginTop: '16px' }">
    <ACol v-for="item in store.listing" :key="item.id" :md="12" :lg="8" :xl="6">
      <ACard :title="item.organization_name" @click="emit('openDetail', item.id)">
        <template #extra>
          <AFlex :gap="8" style>
            <AButton
              block
              type="default"
              class="custom-button"
              size="default"
              :icon="h(EditOutlined)"
              @click.stop="emit('openEdit', item.id)"
            />
            <AButton
              block
              type="default"
              class="custom-button"
              size="default"
              :disabled="isDeleting"
              :icon="h(DeleteOutlined)"
              @click.stop="deleteAction(item.id)"
            />
          </AFlex>
        </template>
        <ADescriptions layout="vertical" :column="24">
          <ADescriptionsItem :span="24" label="ИНН">{{
            viewValueOrDefault(item?.itn)
          }}</ADescriptionsItem>
          <ADescriptionsItem :span="24" label="Расчетный счет">{{
            viewValueOrDefault(item?.account_number)
          }}</ADescriptionsItem>
          <ADescriptionsItem :span="24">
            <ADivider style="background-color: #d5d5d5; height: auto" />
          </ADescriptionsItem>
          <ADescriptionsItem :span="24" label="Доход (за год)">{{
            formatThousands(item?.incoming) + " руб."
          }}</ADescriptionsItem>
          <ADescriptionsItem :span="24" label="Расход (за год)">{{
            formatThousands(item?.outgoing) + " руб."
          }}</ADescriptionsItem>
          <ADescriptionsItem :span="24" label="Прибыль (за календарный год)">{{
            formatThousands(item?.profit) + " руб."
          }}</ADescriptionsItem>
        </ADescriptions>
      </ACard>
    </ACol>
  </ARow>
  <AButton
    v-if="store.listing.length && !store.isLoadedFull"
    block
    type="primary"
    size="large"
    :style="{ marginTop: '16px' }"
    @click="loadMore"
  >
    Показать больше
  </AButton>
</template>

<style scoped>
.custom-button {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
