<script setup lang="ts">
import { ref } from "vue"

import { PaymentListing, PaymentFilter } from "@/modules/payment"

const listingRef = ref<InstanceType<typeof PaymentListing> | null>(null)
</script>

<template>
  <PaymentFilter />
  <PaymentListing ref="listingRef" :style="{ marginTop: '16px', flexGrow: 1 }" />
  <RouterView />
</template>
