<script setup lang="ts">
import { PaymentStatus, PaymentStatusDict } from "@/modules/payment"

const options = (Object.keys(PaymentStatus) as Array<keyof typeof PaymentStatus>).map((key) => ({
  value: PaymentStatus[key],
  label: PaymentStatusDict[PaymentStatus[key]]
}))
</script>

<template>
  <ASelect allow-clear :options="options" placeholder="Статус" />
</template>
