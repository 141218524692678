import type { Dayjs } from "dayjs"
import type { FromField } from "./interfaces"

import { defineStore } from "pinia"
import { computed, reactive, ref } from "vue"

export const useOperationListEditStore = defineStore("operationListEdit", () => {
  const formFields = reactive<Array<FromField>>([{ id: 1, operationKey: null, value: null }])

  const selectOptionsList = ref([
    { label: "Вид операции", value: "type" },
    { label: "Дата платежа", value: "date" },
    { label: "Контрагент", value: "counterparty_id" },
    { label: "Назначение платежа", value: "purpose" },
    { label: "Проект", value: "project_id" },
    { label: "Статья дохода/расхода", value: "operation_category_id" },
    { label: "Счет", value: "bank_account_id" },
    { label: "Тип", value: "action" }
  ])

  const hasTransfer = ref(false)

  const setHasTransfer = (flag: boolean) => {
    hasTransfer.value = flag
  }

  const filteredOptions = computed(() => {
    const baseOptions = hasTransfer.value
      ? selectOptionsList.value.filter((option) => ["date", "purpose"].includes(option.value))
      : selectOptionsList.value

    return baseOptions.filter(
      (option) => !formFields.find((data) => data.operationKey === option.value)
    )
  })

  const deleteField = (idToDelete: number) => {
    formFields.splice(0, formFields.length, ...formFields.filter(({ id }) => id !== idToDelete))
  }

  const addField = () => {
    formFields.push({
      id: formFields!.at(-1)!.id + 1,
      operationKey: null,
      value: ""
    })
  }

  const requestData = computed(() => {
    const payload: Record<string, string | number> = {}

    formFields.forEach((field) => {
      if (field.operationKey === "date") {
        payload[field.operationKey] = (field.value as Dayjs).format("DD-MM-YYYY")
      } else {
        payload[field.operationKey!] = field.value as string | number
      }
    })

    return payload
  })

  const $reset = () => {
    formFields.splice(0, formFields.length, { id: 1, operationKey: null, value: null })
  }

  return {
    formFields,
    filteredOptions,
    deleteField,
    addField,
    requestData,
    $reset,
    hasTransfer,
    setHasTransfer
  }
})
