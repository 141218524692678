<script setup lang="ts">
import { useRouter } from "vue-router"
import { onMounted, watch, ref, computed } from "vue"
import { useToggle } from "@vueuse/core"
import { push } from "notivue"
import { theme } from "ant-design-vue"

import { formattedDate } from "@/package/util"
import { PhysProfileRouteName } from "@/router"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { injectProfile, injectProfileRefresh, SubscriptionBadge } from "@/modules/profile"
import {
  SubscriptionPeriod,
  useGetCurrentSubscription,
  useCancelSubscription,
  usePaySubscription
} from "@/modules/subscription"
import SbpIcon from "@/assets/icons/sbp.svg"
import { usePaymentSubscriptionsBus } from "@/modules/centrifugo"

const { token } = theme.useToken()
const profile = injectProfile()
const refreshAsync = injectProfileRefresh()

const router = useRouter()

const [isOpened, toggleOpened] = useToggle(true)

const {
  subscriptionCurrentData,
  getCurrentSubscription,
  SubscriptionCurrentPending,
  SubscriptionCurrentError
} = useGetCurrentSubscription()

const {
  paymentLink,
  getSubscriptionPaymentLink,
  closeAfterApprove,
  paymentLinkPending,
  SubscriptionPaymentError
} = usePaySubscription()

const { cancelSubscription, CancelSubscriptionError } = useCancelSubscription()

onMounted(async () => {
  await getCurrentSubscription()
})

watch(SubscriptionCurrentError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

watch(CancelSubscriptionError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})
watch(SubscriptionPaymentError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

let paymentWindow: Window | null = null
const paymentSubscriptionsBus = usePaymentSubscriptionsBus()
paymentSubscriptionsBus.on(async (event) => {
  if (event === "success") {
    await getCurrentSubscription()

    if (paymentWindow) {
      paymentWindow.close()
      paymentWindow = null
    }
    closeAfterApprove(openChanging)
    await refreshAsync()
  }
})

const getSubscriptionPeriod = (period: SubscriptionPeriod) => {
  switch (period) {
    case SubscriptionPeriod.Trial:
      return "14 дней"
    case SubscriptionPeriod.OneMonth:
      return "1 месяц"
    case SubscriptionPeriod.ThreeMonths:
      return "3 месяца"
    case SubscriptionPeriod.SixMonths:
      return "6 месяцев"
    case SubscriptionPeriod.TwelveMonths:
      return "12 месяцев"
    default:
      return "Безлимитный период"
  }
}
const openConfirm = ref(false)
const openChanging = ref(false)
const openConfirmModal = () => {
  openConfirm.value = true
}
const openChangingModal = () => {
  openChanging.value = true
}

const cancelSubscriptionHandler = async () => {
  await cancelSubscription()
  openConfirm.value = false
  toggleOpened(false)
  await refreshAsync()
  push.success({ message: "Подписка отменена" })
}

const isRecurrent = ref(true)
const changePayment = async (sbp = false) => {
  const width = 850
  const height = 700

  const left = window.screenX + (window.outerWidth - width) / 2
  const top = window.screenY + (window.outerHeight - height) / 2

  paymentWindow = window.open(
    "",
    "change-payment-modal",
    `width=${width},height=${height},left=${left},top=${top}`
  ) as Window | null

  if (!paymentWindow) {
    return
  }

  try {
    if (subscriptionCurrentData?.value?.data?.tariff?.id) {
      await getSubscriptionPaymentLink(
        subscriptionCurrentData?.value?.data?.tariff?.id,
        isRecurrent.value,
        sbp,
        true
      )

      paymentWindow.location.href = paymentLink.value?.data?.card as string
    }
  } catch (error) {
    paymentWindow.close()
    paymentWindow = null
  }
}

const isFinish = computed(() => !profile?.value?.is_recurrent)

const SubscriptionFinishText = computed(() => {
  if (isFinish.value) {
    return "Закончится"
  }
  return "Будет продлена"
})
const SubscriptionFinish = computed(() => {
  if (isFinish.value) {
    return formattedDate(subscriptionCurrentData?.value?.data?.subscription?.finish_at)
  }
  return formattedDate(subscriptionCurrentData?.value?.data?.payment?.next_payment_date)
})
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    title="Управление подпиской"
    @onClose="() => router.push({ name: PhysProfileRouteName })"
    ><ASkeleton v-if="SubscriptionCurrentPending" active :paragraph="{ rows: 8 }" />
    <template v-else>
      <AFlex class="details-row" justify="space-between" align="center">
        <ATypographyText>Текущая подписка</ATypographyText
        ><AFlex align="center"
          ><SubscriptionBadge
            v-if="subscriptionCurrentData?.data?.product?.name === 'PRO'"
            :style="{ marginRight: '8px' }"
            :subscription="subscriptionCurrentData?.data?.product?.name"
          /><ATypographyText class="details-info">{{
            getSubscriptionPeriod(
              subscriptionCurrentData?.data?.tariff?.period as SubscriptionPeriod
            )
          }}</ATypographyText></AFlex
        >
      </AFlex>
      <AFlex class="details-row" justify="space-between" align="center">
        <ATypographyText>{{ SubscriptionFinishText }}</ATypographyText
        ><AFlex align="center"
          ><ATypographyText class="details-info"> {{ SubscriptionFinish }}</ATypographyText></AFlex
        >
      </AFlex>
      <AFlex class="details-row" justify="space-between" align="center">
        <ATypographyText>Сумма списания</ATypographyText
        ><AFlex align="center"
          ><ATypographyText class="details-info">{{
            subscriptionCurrentData?.data?.tariff?.cost
              ? Number(subscriptionCurrentData?.data?.tariff?.cost) / 100
              : "—"
          }}</ATypographyText></AFlex
        >
      </AFlex>
      <div class="divider" />
      <AFlex class="details-row" justify="space-between" align="center">
        <ATypographyText>Способ оплаты</ATypographyText
        ><AFlex align="center"
          ><ATypographyText class="details-info">{{
            subscriptionCurrentData?.data?.card_number ?? "—"
          }}</ATypographyText></AFlex
        >
      </AFlex>
      <AButton
        v-if="!profile?.is_trial"
        :style="{ marginBottom: '24px' }"
        size="large"
        type="default"
        block
        :disabled="isFinish"
        @click="openChangingModal"
        >Сменить способ оплаты</AButton
      >
      <template v-if="!isFinish">
        <div class="divider" />
        <AButton class="cancel-button" type="link" danger @click="openConfirmModal"
          >Отменить подписку</AButton
        >
        <ATypographyText type="secondary"
          >При отмене подписки ПРО, подписка ФРИ будет подключена автоматически</ATypographyText
        >
      </template>
    </template>
  </APageDrawer>
  <AModal v-model:open="openChanging" :footer="null">
    <ATypographyTitle :style="{ margin: 0 }" :level="3">
      Выберите вариант смены оплаты
    </ATypographyTitle>
    <AFlex>
      <ACheckbox v-model:checked="isRecurrent" :style="{ marginTop: '32px' }"
        >Автоматически продлевать подписку</ACheckbox
      >
    </AFlex>
    <AButton
      block
      size="large"
      :style="{
        width: '100%',
        marginTop: '16px',
        padding: 0,
        borderWidth: '2px',
        borderColor: token.colorPrimary
      }"
      :loading="paymentLinkPending"
      @click="() => changePayment(true)"
    >
      <div style="display: inline-flex; align-items: center; gap: 8px">
        <span>Оплата c </span><SbpIcon :height="24" />
      </div>
    </AButton>
    <AButton
      block
      type="primary"
      size="large"
      :style="{ marginTop: '8px' }"
      :loading="paymentLinkPending"
      @click="() => changePayment()"
    >
      Оплата картой
    </AButton>
    <ATypographyText :style="{ display: 'inline-block', fontSize: '12px', textAlign: 'center' }"
      >При оплате через SberPay автоматическое продление подписки не активируется</ATypographyText
    >
  </AModal>
  <AModal v-model:open="openConfirm" :footer="null">
    <ATypographyTitle :style="{ margin: 0 }" :level="3">
      Вы уверены, что хотите отключить подписку ПРО?
    </ATypographyTitle>
    <ATypographyTitle :style="{ marginTop: '16px' }" :level="5">
      Функции ПРО будут доступны по
      {{ formattedDate(subscriptionCurrentData?.data?.subscription?.finish_at) }}
    </ATypographyTitle>
    <ARow :gutter="[24, 24]">
      <ACol :xs="12" :xl="12">
        <AButton
          block
          type="primary"
          size="large"
          :style="{ marginTop: '20px' }"
          @click="cancelSubscriptionHandler"
        >
          Да
        </AButton>
      </ACol>
      <ACol :xs="12" :xl="12">
        <AButton block size="large" :style="{ marginTop: '20px' }" @click="openConfirm = false">
          Отмена
        </AButton>
      </ACol>
    </ARow>
  </AModal>
</template>

<style scoped>
.details-row {
  margin-bottom: 16px;
}

.details-info {
  font-size: 16px;
}

.divider {
  height: 1px;
  background: #f0f0f0;
  width: 100%;
  margin-bottom: 16px;
}

.cancel-button {
  display: block;
  position: relative;
  margin: 0 auto 12px;
}

.cancel-button:after {
  content: "";
  width: calc(100% - 30px);
  height: 1px;
  background: #ff7875;
  position: absolute;
  bottom: 9px;
  left: 15px;
}
</style>
