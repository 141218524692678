<script setup lang="ts">
import { useRouter } from "vue-router"
import { computed, h, watch } from "vue"
import { noop, useToggle } from "@vueuse/core"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons-vue"
import { useRouteParams } from "@vueuse/router"
import { push } from "notivue"

import {
  OperationsCategoriesDetailRouteName,
  OperationsCategoriesEditRouteName,
  ReferencesOperationsCategoriesRouteName
} from "@/router"
import { DeleteConfirm } from "@/package/ui-kit"
import {
  useOperationCategoriesStore,
  OperationCategoryDescription,
  PageNamesMap,
  DETAIL_REQUEST_ERROR_MESSAGE,
  useOperationCategoryDetailView
} from "@/modules/operation-category"

const router = useRouter()
const openListingAction = () => router.push({ name: ReferencesOperationsCategoriesRouteName })
const openEditAction = () =>
  router.push({
    name: OperationsCategoriesEditRouteName,
    params: { id: categoryId.value }
  })

const categoryId = useRouteParams<string>("id")
const [isOpened, toggleOpened] = useToggle(true)

const { error, detailView, isLoading } = useOperationCategoryDetailView(categoryId)
watch(
  error,
  async (err) => {
    if (err) {
      push.warning({ message: err.message ?? DETAIL_REQUEST_ERROR_MESSAGE })
      await openListingAction()
    }
  },
  { immediate: true }
)

const store = useOperationCategoriesStore()
const deleteAction = () => {
  DeleteConfirm({
    content: "Вы действительно хотите безвозвратно удалить выбранные записи?",
    centered: true,
    onOk: () => {
      if (detailView.value) {
        store
          .deleteOperationCategories({
            id: +categoryId.value,
            parent_id: detailView.value.parent_id
          })
          .then(() => {
            push.success({ message: "Удалено" })
            toggleOpened(false)
          })
          .catch(noop)
      } else {
        throw new Error("logic error")
      }
    }
  })
}
const title = computed(() => PageNamesMap[OperationsCategoriesDetailRouteName][store.actionType])
</script>

<template>
  <APageDrawer v-model:open="isOpened" :title="title" @onClose="openListingAction">
    <ASpin :spinning="store.isListDeleting || isLoading">
      <OperationCategoryDescription :operation="detailView" />
    </ASpin>
    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton :icon="h(DeleteOutlined)" block size="large" danger @click="deleteAction">
            Удалить
          </AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            :icon="h(EditOutlined)"
            block
            type="primary"
            size="large"
            @click="openEditAction"
          >
            Редактировать
          </AButton>
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
