import { useAxios } from "@vueuse/integrations/useAxios"

import type { SelectListAction } from "@/interfaces"
import { client, useApiError } from "@/package/api-client"

export const UseOperationCategoriesListDelete = () => {
  const { execute, isLoading, error } = useAxios<
    { success: string },
    { ids: string; action_type: string }
  >("/operation-categories", { method: "DELETE" }, client, {
    immediate: false
  })

  const requestListDelete = async (
    data: SelectListAction<string | number, { action_type: string; query?: string }>
  ) => await execute({ data })

  return {
    requestListDelete,
    isListDeleting: isLoading,
    categoriesDeleteListError: useApiError(error)
  }
}
