export enum RepeatPeriod {
  daily = "P1D",
  weekly = "P7D",
  every2weeks = "P14D",
  monthly = "P1M",
  every4months = "P4M",
  semiannual = "P6M",
  annual = "P1Y",
  every2years = "P2Y",
  every5years = "P5Y"
}

export const RepeatPeriodNaming: Record<keyof typeof RepeatPeriod, string> = {
  daily: "Раз в день",
  weekly: "Раз в неделю",
  every2weeks: "Раз в две недели",
  monthly: "Раз в месяц",
  every4months: "Раз в четыре месяца",
  semiannual: "Раз в шесть месяцев",
  annual: "Раз в год",
  every2years: "Раз в два года",
  every5years: "Раз в пять лет"
}

export const RepeatPeriodCodeMap: Record<RepeatPeriod, string> = {
  P1D: "Раз в день",
  P7D: "Раз в неделю",
  P14D: "Раз в две недели",
  P1M: "Раз в месяц",
  P4M: "Раз в четыре месяца",
  P6M: "Раз в шесть месяцев",
  P1Y: "Раз в год",
  P2Y: "Раз в два года",
  P5Y: "Раз в пять лет"
}
