import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError, useApiResponseStatus } from "@/package/api-client"
import type {
  OperationFilterParams,
  OperationExtraParams,
  OperationListElementDTO
} from "@/modules/operation"

interface OperationListResponse {
  success: boolean
  pagination: {
    total: number
    count: number
    limit: number
    offset: number
  }
  data: OperationListElementDTO[]
}

export const useOperationList = () => {
  const { execute, isLoading, error, data, response } = useAxios<OperationListResponse>(
    "/operations/list",
    {
      method: "POST"
    },
    client,
    {
      immediate: false,
      resetOnExecute: true
    }
  )

  const listingApiError = useApiError(error)
  const responseStatus = useApiResponseStatus(response, error)

  const requestListing = (
    data: { offset?: number; limit?: number; query?: string } & OperationFilterParams &
      OperationExtraParams
  ) => execute({ data })

  return {
    responseStatus,
    listingApiError,
    requestListing,
    isListingLoading: isLoading,
    operationListingResponse: data
  }
}
