<script setup lang="ts">
import type { LegalEntityCreate } from "../../interfaces"
import type { Form } from "ant-design-vue"
import type { Dayjs } from "dayjs"

import dayjs from "dayjs"
import { InfoCircleOutlined } from "@ant-design/icons-vue"
import { onMounted, type PropType, ref, toRefs, watch } from "vue"
import { useDebounceFn } from "@vueuse/core"

import { usePopupContainerGetter } from "@/package/ui-kit"
import { useForm } from "@/package/hooks"
import { isValidateError } from "@/package/util"
import { BankAccountSelect, useBankAutocomplete, useBankAccountStore } from "@/modules/bank-account"

import {
  autocompletePlaceholder,
  useLegalEntityAutocomplete
} from "../../hooks/use-legal-entity-autocomplete"
import TurnoverSelect from "../TurnoverSelect.vue"
import LegalEntitySearch from "../LegalEntitySearch.vue"
import OkvedSearch from "../OkvedSearch.vue"
import GenderSelect from "../../../legal-entity/components/GenderSelect.vue"
import CitizenshipSelect from "../../../legal-entity/components/CitizenshipSelect.vue"
import DocumentSelect from "../../../legal-entity/components/DocumentSelect.vue"
import TaxpayerSelect from "../../../legal-entity/components/TaxpayerSelect.vue"

import { useFormState } from "./use-form-state"
import { searchConnectedFields, useRuleset } from "./use-ruleset"

const props = defineProps({
  profile: {
    type: [Object, undefined] as PropType<LegalEntityCreate | undefined>,
    default: () => undefined
  },

  type: {
    type: String,
    default: () => undefined
  },

  getContainer: {
    type: [Function, undefined] as PropType<() => HTMLElement | null>,
    default: undefined
  }
})

const emit = defineEmits(["submit"])

const store = useBankAccountStore()

const { getContainer } = toRefs(props)

const { formState, requestData } = useFormState()
const ruleset = useRuleset(formState)

const resetAction = useDebounceFn(() => {
  if (props.profile) {
    const { birth_date, ...rest } = props.profile || {}
    const formattedFormState = {
      ...rest,
      birth_date: ref<Dayjs | null>(birth_date ? dayjs(birth_date, "YYYY-MM-DD") : null)
    }
    Object.assign(formState, formattedFormState)
  }
}, 100)
watch(() => props.profile, resetAction)
onMounted(resetAction)
const { validate, validateInfos } = useForm(formState, ruleset)
const { selectedEntity, disabledFields } = useLegalEntityAutocomplete(formState, false, validate)
const formRef = ref<typeof Form | null>(null)

const { bankDataRequestErrorMessage } = useBankAutocomplete(formState, validate)
watch(bankDataRequestErrorMessage, (err) => {
  if (err) {
    validateInfos.bic.help = [err]
    validateInfos.bic.validateStatus = "error"
  }
})

const submitAction = () =>
  validate()
    .then(() => emit("submit", requestData.value))
    .catch((err) => {
      if (isValidateError(err)) {
        const isSearchError = searchConnectedFields.includes(
          err.errorFields[0].name as unknown as string
        )
        const search = document.getElementById("legal-entity-search")
        if (isSearchError) {
          search?.focus()
        }

        formRef.value?.scrollToField(isSearchError ? "search" : err.errorFields[0].name, {
          block: "start",
          boundary: (parent: HTMLElement) => {
            if (isSearchError) {
              return true
            } else {
              parent.focus()
              return parent.classList.contains("ant-row")
            }
          }
        })
      }
    })

defineExpose({ submitAction, resetAction })
const popupContainerGetter = usePopupContainerGetter(getContainer?.value)

const getBankInfo = (e: number) => {
  const bankAccountInfo = store.listing.find((item) => item.id === e)
  formState.bic = bankAccountInfo?.bic as string
  formState.account_number = bankAccountInfo?.account_number as string
}
</script>

<template>
  <AForm
    ref="formRef"
    name="edit-legal-entity-form"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    hide-required-mark
    :style="{ marginTop: '12px' }"
    scroll-to-first-error
    @submit.prevent="submitAction"
  >
    <template v-if="props.type !== 'edit'">
      <AFormItem label="Поиск компании">
        <LegalEntitySearch
          v-model="selectedEntity"
          :get-popup-container="popupContainerGetter"
          :itn="formState.itn"
          :is-search-disabled="!!profile"
        />
      </AFormItem>
      <ADivider :style="{ marginTop: '6px' }" />
      <ATypographyParagraph type="secondary" :style="{ textAlign: 'center', marginTop: '16px' }">
        Данные ниже будут заполнены автоматически, после указания основной информации о юридическом
        лице
      </ATypographyParagraph>
    </template>
    <AFormItem label="ИНН" name="itn" v-bind="validateInfos?.itn">
      <AInput
        v-model.numbered="formState.itn"
        :placeholder="autocompletePlaceholder"
        size="large"
        disabled
      />
    </AFormItem>
    <AFormItem
      label="Название организации"
      name="organization_name"
      v-bind="validateInfos?.organization_name"
    >
      <AInput
        v-model="formState.organization_name"
        size="large"
        disabled
        :placeholder="autocompletePlaceholder"
      />
    </AFormItem>
    <AFormItem label="ФИО руководителя" name="head_fullname" v-bind="validateInfos?.head_fullname">
      <AInput
        v-model="formState.head_fullname"
        size="large"
        :disabled="disabledFields.head_fullname"
        :placeholder="
          disabledFields.head_fullname ? autocompletePlaceholder : 'Введите ФИО руководителя'
        "
      />
    </AFormItem>
    <AFormItem
      label="Основной вид деятельности (ОКВЭД)"
      name="okved_id"
      v-bind="validateInfos?.okved_id"
    >
      <OkvedSearch
        v-model:okved_code="formState.okved_code"
        v-model:okved_id="formState.okved_id"
        :get-popup-container="popupContainerGetter"
        size="large"
        :disabled="disabledFields.okved_id"
        :placeholder="
          disabledFields.okved_id ? autocompletePlaceholder : 'Поиск по коду или наименованию ОКВЭД'
        "
      />
    </AFormItem>
    <AFormItem label="Юридический адрес" name="legal_address" v-bind="validateInfos?.legal_address">
      <AInput
        v-model="formState.legal_address"
        size="large"
        :disabled="disabledFields.legal_address"
        :placeholder="
          disabledFields.legal_address
            ? autocompletePlaceholder
            : 'Укажите индекс, город, улица, дом'
        "
      />
    </AFormItem>
    <AFormItem
      label="Размер ежемесячного оборота, руб"
      name="monthly_turnover_rate"
      v-bind="validateInfos?.monthly_turnover_rate"
    >
      <TurnoverSelect
        v-model:value="formState.monthly_turnover_rate"
        :get-popup-container="popupContainerGetter"
        size="large"
      />
    </AFormItem>
    <AFormItem
      class="income-form__item"
      label="Счет"
      name="bank_account_id"
      v-bind="validateInfos?.bank_account_id"
    >
      <BankAccountSelect
        v-model="formState.bank_account_id"
        size="large"
        placeholder="Выберите счет"
        :disabled="profile"
        :get-popup-container="popupContainerGetter"
        filter-bic-account-number
        hide-link
        is-legal-form
        @change="getBankInfo"
        @selectAccount="getBankInfo"
      />
    </AFormItem>
    <AFormItem label="БИК" name="bic" v-bind="validateInfos?.bic">
      <AInput
        v-model.numbered="formState.bic"
        disabled
        size="large"
        placeholder="Введите БИК"
        :maxlength="9"
      />
    </AFormItem>
    <AFormItem label="Название банка" name="bank_name" v-bind="validateInfos?.bank_name">
      <AInput
        v-model="formState.bank_name"
        disabled
        size="large"
        :placeholder="autocompletePlaceholder"
      />
    </AFormItem>
    <AFormItem
      label="Корреспондентский счет"
      name="correspondent_account_number"
      v-bind="validateInfos?.correspondent_account_number"
    >
      <AInput
        v-model.numbered="formState.correspondent_account_number"
        disabled
        size="large"
        :placeholder="autocompletePlaceholder"
      />
    </AFormItem>
    <AFormItem label="Расчетный счет" name="account_number" v-bind="validateInfos?.account_number">
      <AInput
        v-model.numbered="formState.account_number"
        size="large"
        disabled
        :placeholder="autocompletePlaceholder"
        :maxlength="20"
      />
    </AFormItem>
    <ATypographyTitle :level="5" :style="{ margin: 0, marginBottom: '10px' }"
      >Данные для строгой отчетности</ATypographyTitle
    >
    <ATypographyText type="secondary"
      ><InfoCircleOutlined :style="{ marginRight: '8px' }" />САМО использует информацию из этого
      блока для автоматического формирования бухгалтерских отчетов для ИП. Если поля оставить
      пустыми - Вам необходимо будет указывать информацию в отчетах вручную</ATypographyText
    >
    <ADivider :style="{ width: '100%', marginTop: '16px', marginBottom: '16px' }" />
    <AFormItem label="Дата рождения" name="birth_date">
      <ADatePicker v-model:value="formState.birth_date" size="large" :style="{ width: '100%' }" />
    </AFormItem>
    <AFormItem label="Пол" name="gender">
      <GenderSelect
        v-model:value="formState.gender"
        size="large"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AFormItem label="Гражданство" name="citizenship_code">
      <CitizenshipSelect
        v-model:value="formState.citizenship_code"
        size="large"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AFormItem label="Тип документа" name="document_type_code">
      <DocumentSelect
        v-model:value="formState.document_type_code"
        size="large"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AInputGroup size="large">
      <ARow :gutter="8">
        <ACol :span="8">
          <AFormItem label="Серия документа" name="document_series">
            <AInputGroup size="large" compact>
              <AInput v-model.trim="formState.document_series" size="large" placeholder="Серия" />
            </AInputGroup>
          </AFormItem>
        </ACol>
        <ACol :span="16">
          <AFormItem label="Номер документа" name="document_number">
            <AInputGroup size="large" compact>
              <AInput v-model.trim="formState.document_number" size="large" placeholder="Номер" />
            </AInputGroup>
          </AFormItem>
        </ACol>
      </ARow>
    </AInputGroup>
    <ADivider :style="{ width: '100%', marginTop: 0, marginBottom: '24px' }" />
    <AFormItem label="Код категории налогоплательщика" name="taxpayer_category_code">
      <TaxpayerSelect
        v-model:value="formState.taxpayer_category_code"
        size="large"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AFormItem label="КПП" name="kpp" v-bind="validateInfos?.kpp">
      <AInput v-model.trim="formState.kpp" size="large" placeholder="Введите КПП" />
    </AFormItem>
    <AFormItem label="ОКТМО" name="oktmo">
      <AInputGroup size="large" compact>
        <AInput v-model.trim="formState.oktmo" size="large" placeholder="Введите ОКТМО" />
      </AInputGroup>
    </AFormItem>
    <AFormItem label="ОГРНИП" name="ogrnip" v-bind="validateInfos?.ogrnip">
      <AInput v-model.trim="formState.ogrnip" size="large" placeholder="Введите ОГРНИП" />
    </AFormItem>
    <AFormItem label="КБК" name="kbk" v-bind="validateInfos?.kbk">
      <AInput v-model.trim="formState.kbk" size="large" placeholder="Введите КБК" />
    </AFormItem>
  </AForm>
</template>
