<script setup lang="ts">
import { useRouter } from "vue-router"
import { onMounted, watch } from "vue"
import { push } from "notivue"

import { ProfileDescription, EntrepreneurBlock, injectProfileRefresh } from "@/modules/profile"
import { PhysEntrepreneurRouteName, PhysProfileEditRouteName } from "@/router"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { SubscriptionReminder } from "@/modules/subscription-reminder"

import { useGetCurrentSubscription } from "../../../modules/subscription"
import SubscribtionInfo from "../../../modules/profile/components/SubscribtionInfo/SubscribtionInfo.vue"
import SubscriptionDescription from "../../../modules/profile/components/SubscriptionDescription/SubscriptionDescription.vue"
import SecurityBlock from "../../../modules/profile/components/SecurityBlock/SecurityBlock.vue"
import StorageBlock from "../../../modules/profile/components/StorageBlock/StorageBlock.vue"
import OperationsBlock from "../../../modules/profile/components/OperationsBlock/OperationsBlock.vue"
import BudgetBlock from "../../../modules/profile/components/BudgetBlock/BudgetBlock.vue"

const router = useRouter()

const {
  subscriptionCurrentData,
  getCurrentSubscription,
  SubscriptionCurrentPending,
  SubscriptionCurrentError
} = useGetCurrentSubscription()

const refreshAsync = injectProfileRefresh()
onMounted(async () => {
  await getCurrentSubscription()
  await refreshAsync()
})

watch(SubscriptionCurrentError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})
</script>

<template>
  <SubscriptionReminder :style="{ marginTop: '32px', marginBottom: '0' }" />

  <ARow :gutter="[24, 24]">
    <ACol class="gutter-row" :xs="24" :lg="14" :style="{ marginTop: '24px' }">
      <ARow :gutter="[24, 24]" align="stretch">
        <ACol class="gutter-row" :xs="24" :lg="12">
          <ProfileDescription
            :subscription-loading="SubscriptionCurrentPending"
            :subscription="subscriptionCurrentData?.data?.product?.name"
            @editProfile="() => router.push({ name: PhysProfileEditRouteName })"
        /></ACol>
        <ACol class="gutter-row" :xs="24" :lg="12">
          <EntrepreneurBlock
            :subscription-loading="SubscriptionCurrentPending"
            @editProfile="() => router.push({ name: PhysEntrepreneurRouteName })"
        /></ACol>
      </ARow>
      <ARow :gutter="[24, 24]">
        <ACol class="gutter-row" :xs="24" :lg="24" :style="{ marginTop: '24px' }">
          <SecurityBlock />
        </ACol>
      </ARow>
      <ARow :gutter="[24, 24]" :style="{ marginTop: '24px', alignItems: 'stretch' }">
        <ACol class="gutter-row" :xs="24" :lg="7"><StorageBlock /></ACol
        ><ACol class="gutter-row" :xs="24" :lg="9"><OperationsBlock /></ACol
        ><ACol class="gutter-row" :xs="24" :lg="8"><BudgetBlock /></ACol
      ></ARow>
    </ACol>
    <ACol class="gutter-row" :xs="24" :lg="10" :style="{ marginTop: '24px' }">
      <ARow :gutter="[24, 24]">
        <ACol :span="24">
          <SubscribtionInfo
            :subscription-loading="SubscriptionCurrentPending"
            :subscription="subscriptionCurrentData?.data"
        /></ACol>
        <ACol :span="24">
          <SubscriptionDescription
            :subscription-loading="SubscriptionCurrentPending"
            :subscription="subscriptionCurrentData?.data?.product?.name"
        /></ACol>
      </ARow>
    </ACol>
  </ARow>

  <RouterView />
</template>
