import { useAxios } from "@vueuse/integrations/useAxios"
import { computed, type MaybeRef } from "vue"
import { get } from "@vueuse/core"

import type { PaymentListingData } from "@/modules/payment"
import { ApiBadReqResponse, client } from "@/package/api-client"
import type { OffsetPaginationDto } from "@/interfaces"

interface PaymentListRespone {
  success: boolean
  pagination: OffsetPaginationDto
  data: PaymentListingData[]
}

export const usePaymentList = () => {
  const { execute, isLoading, error, data } = useAxios<PaymentListRespone>(
    "/payment/list",
    { method: "GET" },
    client,
    { immediate: false }
  )

  const errorMessage = computed(() => {
    let result = null
    if (error.value) {
      if (error.value instanceof ApiBadReqResponse) {
        result = error.value.message
      }
    }
    return result
  })

  const requestPaymentList = (
    params: MaybeRef<{
      limit: number
      offset: number
      query?: string
      order?: string
      orderBy?: string
    }>
  ) =>
    execute({
      params: get(params)
    })

  return {
    paymentListingResponse: data,
    requestPaymentList,
    isListLoading: isLoading,
    listingLoadingErrorMessage: errorMessage,
    listingErrorMessage: error
  }
}
