import { ref } from "vue"

export const useRuleset = () => {
  const ruleset: any = ref({
    name: [
      {
        required: true,
        message: "Пожалуйста, введите название",
        whitespace: true
      }
    ]
  })

  return ruleset
}
