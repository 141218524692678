<script setup lang="ts">
import { computed, type PropType } from "vue"
import { DatePicker } from "ant-design-vue"
import dayjs, { type Dayjs } from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import { useToggle } from "@vueuse/core"
import IMask from "imask"

import { DATE_MASK, DATETIME_MASK, datepickerLocale } from "../constants"

import { type MaskOpts, vInputMask } from "./directive/MaskInput"

const props = defineProps({
  showTime: {
    type: Boolean,
    default: false
  },

  disableAfter: {
    type: Boolean,
    default: false
  },

  disableBefore: {
    type: Boolean,
    default: false
  },

  format: {
    type: String,
    default: DATE_MASK
  },

  placeholder: {
    type: String,
    default: "Выберите дату"
  },

  excludeCurrent: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(["openChange"])
const date = defineModel({
  type: [Object, undefined] as PropType<Dayjs | undefined>,
  default: undefined
})

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const currentDate = dayjs()
const disabledFilter = (dpDate: Dayjs) => {
  let result = false
  if (props.disableAfter) {
    result = !currentDate.isSameOrAfter(dpDate, "date")
  }
  if (props.disableBefore) {
    result = !currentDate.isSameOrBefore(dpDate, "date")
  }
  const same = currentDate.isSame(dpDate, "date")
  if (props.excludeCurrent && same) {
    result = same
  }

  return result
}

const [isOpened, toggleOpened] = useToggle()
const placeholder = computed(() => (isOpened.value ? "ДД-ММ-ГГГГ" : props.placeholder))
const openChangeAction = (state: boolean) => {
  toggleOpened(state)
  emit("openChange", state)
}

const mask = computed(() => (props.showTime ? DATETIME_MASK : DATE_MASK))

const opts: MaskOpts = {
  elGetter: (el: HTMLElement) => el.querySelector("input"),
  mask: Date,
  pattern: mask.value,
  format: (date: any) => dayjs(date).format(mask.value),
  parse: (str: any) => {
    const date = dayjs(str, mask.value)
    let result = null
    if (!disabledFilter(date)) {
      result = date.toDate()
    }
    return result
  },
  onComplete(v) {
    if (!isOpened.value) {
      date.value = dayjs(v.value, mask.value)
    }
  },
  blocks: {
    YYYY: {
      mask: IMask.MaskedRange,
      from: 1970,
      to: 2030
    },
    MM: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12
    },
    DD: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31
    },
    HH: { mask: IMask.MaskedRange, from: 0, to: 23 },
    mm: { mask: IMask.MaskedRange, from: 0, to: 59 }
  }
}
</script>

<template>
  <DatePicker
    v-model:value="date"
    v-input-mask="opts"
    :show-time="showTime"
    :format="format"
    :placeholder="placeholder"
    :disabled-date="disabledFilter"
    :locale="datepickerLocale"
    popup-class-name="custom-date-picker"
    @openChange="openChangeAction"
  />
</template>
