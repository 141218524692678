import type { Dayjs } from "dayjs"

export interface ReportListElement {
  name: string
  type: string
}

export interface SaveReportsFilters {
  date_to?: string | null
  date_from?: string | null
  project_ids?: Array<number>
  legal_entity_id?: number | null
  operation_categories?: Array<number>
}

export interface ReportsFiltersData {
  dateFilter: Array<Dayjs>
  projectsFilter: Array<number> | null
  legalEntityFilter: null | number
  operationFilter: Array<number> | null
}

export enum ReportType {
  rsv = "rsv",
  ndfl_3 = "ndfl-3",
  ndfl_6 = "ndfl-6",
  usn = "usn"
}

export interface ReportDownloadData {
  type: ReportType
  date_from?: string
  date_to?: string
  project_ids?: Array<number> | null
  legal_entity_id?: number | null
  operation_categories?: Array<number> | null
  tax?: number | null
  year?: string
  nineMonths?: string
  halfYear?: string
  quarter?: string
}

export enum TaxRate {
  active = 1,
  inactive = 2
}

export const TaxRateDict: Record<TaxRate, string> = {
  1: "1 - налоговая ставка в размере 6%, установленная пунктом 1 статьи 346.20 Налогового кодекса Российской Федерации, или налоговая ставка, установленная законом субъекта Российской Федерации, применяется в течение налогового периода",
  2: "2 - налоговая ставка в размере 8%, установленная пунктом 1.1 статьи 346.20 Налогового кодекса Российской Федерации, применяется начиная с квартала, по итогам которого доходы превысили 150 млн. рублей, но не превысили 200 млн. рублей и (или) в течение которого средняя численность работников превысила 100 человек, но не превысила 130 человек"
}
