export const appRoot = "app-root"

export const AdminRouteName = "AppAdmin"
export const AdminUserRouteName = "AdminUserRouteName"
export const AdminUserDetailRouteName = "AdminUserDetailRouteName"
export const AdminUserEditRouteName = "AdminUserEditRouteName"
export const AdminReferencesRouteName = "AdminReferencesRouteName"
export const AdminPaymentsRouteName = "AdminPaymentsRouteName"
export const AdminPaymentsDetailRouteName = "AdminPaymentsDetailRouteName"
export const AdminPaymentsEditRouteName = "AdminPaymentsEditRouteName"
export const AdminLogsRouteName = "AdminLogsRouteName"
export const isAdminRouteName = (name: unknown) =>
  typeof name === "string" &&
  [
    AdminRouteName,
    AdminUserRouteName,
    AdminUserDetailRouteName,
    AdminUserEditRouteName,
    AdminReferencesRouteName,
    AdminPaymentsRouteName,
    AdminLogsRouteName,
    AdminPaymentsDetailRouteName,
    AdminPaymentsEditRouteName
  ].includes(name)

export const ProfileRouteName = "ProfileSection"
export const PhysProfileRouteName = "PhysicalProfile"
export const PhysProfileEditRouteName = "PhysicalProfileEdit"
export const PhysEntrepreneurRouteName = "PhysicalEntrepreneur"
export const PhysSecurityRouteName = "PhysicalSecurity"
export const PhysSubscriptionRouteName = "PhysicalSubscription"
export const LegalProfileRouteName = "LegalProfile"
export const LegalProfileCreateRouteName = "LegalProfileCreateRouteName"
export const LegalProfileDetailRouteName = "LegalProfileDetailRouteName"
export const LegalProfileEditRouteName = "LegalProfileEditRouteName"

export const ReferencesRouteName = "ReferencesSection"

export const CounterpartiesReferencesRouteName = "ReferencesCounterparties"
export const CounterpartiesReferencesDetailRouteName = "CounterpartiesReferencesDetailRouteName"
export const CounterpartiesReferencesCreateRouteName = "CounterpartiesReferencesCreateRouteName"
export const CounterpartiesReferencesEditRouteName = "CounterpartiesReferencesEditRouteName"

export const BankAccountRouteName = "BankAccount"
export const BankAccountDetailRouteName = "BankAccountDetailRouteName"
export const BankAccountCreateRouteName = "BankAccountCreateRouteName"
export const BankAccountEditRouteName = "BankAccountEditRouteName"

export const ReferencesProjectsRouteName = "ReferencesProjects"
export const ProjectEditRouteName = "ProjectEditRouteName"
export const ProjectDetailRouteName = "ProjectDetailRouteName"
export const ProjectCreateRouteName = "ProjectCreateRouteName"

export const ReferencesProductsRouteName = "ReferencesProducts"
export const ProductsEditRouteName = "ProductsEditRouteName"
export const ProductsDetailRouteName = "ProductsDetailRouteName"
export const ProductsCreateRouteName = "ProductsCreateRouteName"

export const ReferencesOperationsCategoriesRouteName = "ReferencesOperationsCategories"
export const OperationsCategoriesEditRouteName = "OperationsCategoriesEditRouteName"
export const OperationsCategoriesDetailRouteName = "OperationsCategoriesDetailRouteName"
export const OperationsCategoriesCreateRouteName = "OperationsCategoriesCreateRouteName"
export const OperationsCategoriesCreateTemplateRouteName =
  "OperationsCategoriesCreateTemplateRouteName"

export const FinanceRouteName = "FinanceSection"
export const FinanceOperationsRouteName = "FinanceOperationsSection"
export const FinanceOperationsFilterRouteName = "FinanceOperationsSectionFilter"
export const FinanceOperationsCreateRouteName = "FinanceOperationsCreatePage"
export const FinanceOperationsImportRouteName = "FinanceOperationsImport"

export const FinanceOperationsBanksRouteName = "FinanceOperationsBanks"
export const FinanceOperationsBankConnectionRouteName = "FinanceOperationsBankConnection"

export const FinanceOperationsRestoreRouteName = "FinanceOperationsRestorePage"
export const FinanceOperationsEditRouteName = "FinanceOperationsEditPage"

export const FinanceOperationsDetailRouteName = "FinanceOperationsDetailPage"

export const FinanceOperationsEditListRouteName = "FinanceOperationsEditListPage"

export const FinancePlanningRouteName = "FinancePlanningSection"

export const FinancePlanningEditRouteName = "FinancePlanningEditPage"
export const FinancePlanningCreateRouteName = "FinancePlanningCreatePage"

export const FinancePlanningDetailRouteName = "FinancePlanningDetailPage"

export const FinanceStatistics = "FinanceStatistics"

export const SupportSection = "SupportSection"

export const Subscription = "Subscription"

export const BankRedirect = "BankRedirect"

export const FinanceReportsPage = "FinanceReportsPage"
