<script setup lang="ts">
import { ref } from "vue"
import { Form } from "ant-design-vue"
import { InfoCircleOutlined } from "@ant-design/icons-vue"
import { get } from "@vueuse/core"

import {
  ReportType,
  TaxRateSelect,
  useReportsStore,
  type ReportDownloadData
} from "@/modules/reports"
import { useForm } from "@/package/hooks"

import { useRuleset } from "./use-ruleset"

defineProps({
  open: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(["close"])
const store = useReportsStore()

const formState = ref<Partial<ReportDownloadData>>({
  type: ReportType.usn,
  tax: null,
  quarter: "",
  year: "",
  nineMonths: "",
  halfYear: ""
})

const ruleset = useRuleset()

const { validate, validateInfos, clearValidate, resetFields } = useForm(formState, ruleset)

const loadingState = ref<string>("")

const downloadReportUsn = () => {
  const type: ReportType = formState.value.type as ReportType
  loadingState.value = type
  clearValidate()
  return validate()
    .then(() => {
      store.downloadReportUsn({ type, ...get(formState) }).finally(() => {
        resetFields()
        emit("close")
        loadingState.value = ""
      })
    })
    .catch(() => (loadingState.value = ""))
}

const close = () => {
  clearValidate()
  resetFields()
  emit("close")
}
</script>

<template>
  <APageDrawer :open="open" title="Данные для отчета УСН (доходы)" @close="close">
    <Form
      name="usn-report-form"
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      autocomplete="off"
      :colon="false"
      :style="{ marginTop: '12px' }"
      scroll-to-first-error
    >
      <ATypographyText type="secondary"
        ><InfoCircleOutlined :style="{ marginRight: '8px' }" />Отчет УСН можно сформировать только
        за календарный год<br />
        Стандартная процентная ставка - 6%.<br />
        Повышенная процентная ставка - 8%, если ваш доход больше 150 млн. рублей, но меньше 200 млн.
        рублей и (или) ваша средняя численность работников больше 100 человек, но меньше 130 человек
      </ATypographyText>
      <ADivider :style="{ marginTop: '24px', marginBottom: '28px' }" />
      <AFormItem
        label="Код признака применения налоговой ставки"
        name="tax"
        v-bind="validateInfos?.tax"
      >
        <TaxRateSelect v-model:value="formState.tax" size="large" />
      </AFormItem>
      <AText sm :style="{ fontWeight: 700, marginBottom: '16px' }">Налоговая ставка (%)</AText>
      <AFormItem
        label="За первый квартал"
        name="quarter"
        :style="{ marginTop: '16px' }"
        v-bind="validateInfos?.quarter"
      >
        <AInput v-model="formState.quarter" size="large" placeholder="Введите налоговую ставку" />
      </AFormItem>
      <AFormItem label="За полугодие" name="halfYear" v-bind="validateInfos?.halfYear">
        <AInput v-model="formState.halfYear" size="large" placeholder="Введите налоговую ставку" />
      </AFormItem>
      <AFormItem label="За девять месяцев" name="nineMonths" v-bind="validateInfos?.nineMonths">
        <AInput
          v-model="formState.nineMonths"
          size="large"
          placeholder="Введите налоговую ставку"
        />
      </AFormItem>
      <AFormItem label="За налоговый период" name="year" v-bind="validateInfos?.year">
        <AInput v-model="formState.year" size="large" placeholder="Введите налоговую ставку" />
      </AFormItem>
    </Form>
    <template #footer>
      <AButton
        :loading="loadingState === formState.type"
        block
        type="primary"
        size="large"
        @click="downloadReportUsn"
        >Скачать отчёт</AButton
      >
    </template>
  </APageDrawer>
</template>
