import { computed } from "vue"
import plural from "plural-ru"
import { get } from "@vueuse/core"

import { useAdoptable } from "@/package/ui-kit"
import {
  type OperationListElementDTO,
  ListingElementType,
  OperationTypeDict
} from "@/modules/operation"
import { formatThousands } from "@/package/util"

export const useDuplicateOperationsListView = (
  duplicateOperations: OperationListElementDTO[] | undefined
) => {
  const isAdoptable = useAdoptable()

  return computed(() => {
    if (!duplicateOperations) return null

    const today = new Date()
    today.setHours(3, 0, 0, 0)

    return get(duplicateOperations).flatMap((entry) => {
      const children =
        entry.transactions.length > 1 && !isAdoptable.value
          ? entry.transactions.map((child) => ({
              ...child,
              date: null,
              bank_account_name: null,
              type: null,
              amount: formatThousands(child.amount),
              key: child.id,
              action: entry.action,
              elementType: ListingElementType.transaction,
              category_name: child.category_name ?? "—",
              counterparty_name: child.counterparty_name ?? "—"
            }))
          : null

      return {
        ...entry,
        date: entry.date,
        category: entry.category
          ? {
              name:
                entry.category.count > 1
                  ? plural(entry.category.count, "статья", "статьи", "статей")
                  : entry.category.name,
              count: entry.category.count
            }
          : null,
        counterparty: entry.counterparty
          ? {
              name:
                entry.counterparty.count > 1
                  ? plural(entry.counterparty.count, "контрагент", "контрагента", "контрагентов")
                  : entry.counterparty.name,
              count: entry.counterparty.count
            }
          : "-",
        bank_account_name: entry.bank_account_name,
        amount: +entry.amount === 0 ? "0" : formatThousands(entry.amount),
        type: OperationTypeDict[entry.type],
        elementType: ListingElementType.operation,
        children
      }
    })
  })
}
