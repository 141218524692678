import type { RouteRecordRaw } from "vue-router"

import SubscriptionPage from "@/views/SubscriptionPage.vue"

import { Subscription } from "./constants"

export const SubscriptionRoute: RouteRecordRaw[] = [
  {
    path: "/subscription",
    name: Subscription,
    component: SubscriptionPage,
    meta: {
      breadcrumb: {
        disabled: true,
        name: "Страница подписки"
      },
      contentLayout: true
    }
  }
]
