import type { RouteRecordRaw } from "vue-router"

import UserPage from "@/views/admin/user/UserPage.vue"
import UserEditPage from "@/views/admin/user/UserEditPage.vue"
import UserDetailPage from "@/views/admin/user/UserDetailPage.vue"
import PaymentPage from "@/views/admin/payment/PaymentPage.vue"
import PaymentUserEditPage from "@/views/admin/payment/PaymentUserEditPage.vue"
import PaymentUserDetailPage from "@/views/admin/payment/PaymentUserDetailPage.vue"

import {
  AdminLogsRouteName,
  AdminReferencesRouteName,
  AdminRouteName,
  AdminUserRouteName,
  AdminUserDetailRouteName,
  AdminUserEditRouteName,
  AdminPaymentsRouteName,
  AdminPaymentsDetailRouteName,
  AdminPaymentsEditRouteName
} from "./constants"

export const AdminRoute: RouteRecordRaw[] = [
  {
    path: "/admin",
    name: AdminRouteName,
    redirect: { name: AdminUserRouteName },
    meta: {
      breadcrumb: {
        disabled: true,
        name: "Административная панель"
      }
    },
    children: [
      {
        path: "user",
        name: AdminUserRouteName,
        component: UserPage,
        meta: {
          breadcrumb: {
            name: "Пользователи"
          }
        },
        children: [
          {
            meta: {
              breadcrumb: undefined
            },
            path: ":id",
            name: AdminUserDetailRouteName,
            component: UserDetailPage
          },
          {
            meta: {
              breadcrumb: undefined
            },
            path: ":id/edit",
            name: AdminUserEditRouteName,
            component: UserEditPage
          }
        ]
      },
      {
        path: "payment",
        name: AdminPaymentsRouteName,
        component: PaymentPage,
        meta: {
          breadcrumb: {
            disabled: false,
            name: "Платежи"
          }
        },
        children: [
          {
            path: ":id",
            name: AdminPaymentsDetailRouteName,
            component: PaymentUserDetailPage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            meta: {
              breadcrumb: undefined
            },
            path: ":id/edit",
            name: AdminPaymentsEditRouteName,
            component: PaymentUserEditPage
          }
        ]
      },
      {
        path: "references",
        name: AdminReferencesRouteName,
        redirect: { name: AdminUserRouteName },
        meta: {
          breadcrumb: {
            disabled: true,
            name: "Административные справочники"
          }
        }
      },
      {
        path: "log",
        name: AdminLogsRouteName,
        redirect: { name: AdminUserRouteName },
        meta: {
          breadcrumb: {
            disabled: true,
            name: "Лог"
          }
        }
      }
    ]
  }
]
