<script setup lang="ts">
import { useRouter } from "vue-router"
import { get, useToggle } from "@vueuse/core"
import { computed, onUnmounted, type Ref, ref, watch } from "vue"
import { useRouteParams } from "@vueuse/router"
import dayjs from "dayjs"
import { push } from "notivue"
import { storeToRefs } from "pinia"

import type { BudgetFormInterface } from "@/modules/budget"
import { FinancePlanningRouteName } from "@/router"
import {
  BudgetForm,
  useBudgetStore,
  useBudgetDetail,
  DETAIL_REQUEST_ERROR_MESSAGE
} from "@/modules/budget"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"

const router = useRouter()
const openListingAction = () => router.push({ name: FinancePlanningRouteName })

const [isOpened, toggleOpened] = useToggle(true)

const store = useBudgetStore()
const planningId = useRouteParams<string>("id")

const { editError, isEditing, isEditingFinished } = storeToRefs(store)

watch([editError, isEditingFinished], ([err, finishedStatus]) => {
  if (err && finishedStatus) {
    isEditingFinished.value = false
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const formRef = ref<InstanceType<typeof BudgetForm> | null>(null)
const { data, error } = useBudgetDetail(planningId)
watch(
  error,
  async (err) => {
    if (err) {
      push.warning({ message: err.message ?? DETAIL_REQUEST_ERROR_MESSAGE })
      await openListingAction()
    }
  },
  { immediate: true }
)
const transformedPlanning = computed(() => {
  const planning = get(data)
  let result: BudgetFormInterface | undefined = undefined
  if (planning) {
    result = {
      id: planning.id,
      name: planning.name,
      legalEntityId: planning.legal_entity.id,
      projectId: planning.project.id,
      period: [dayjs(planning.from, "DD-MM-YYYY"), dayjs(planning.to, "DD-MM-YYYY")],
      comment: planning.comment,
      category_operation_ids: (planning.operation_categories ?? []).map(({ id }) => id),
      bank_account_id: planning?.bank_account?.[0]?.id || undefined
    }
  }
  return result
})
const submitAction = () => formRef.value?.submitAction()

const editAction = async (data: Ref<BudgetFormInterface>) => {
  await store.editBudget(data.value)
  toggleOpened(false)
  await store.updateBufferAfterEdit(data.value)
}
const isEditingButtonDisabled = computed(() => isEditing.value || isEditingFinished.value)

onUnmounted(() => {
  isEditingFinished.value = false
})
</script>

<template>
  <APageDrawer v-model:open="isOpened" title="Редактирование бюджета" @onClose="openListingAction">
    <BudgetForm ref="formRef" :planning="transformedPlanning" @submit="editAction" />

    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton block size="large" @click="() => toggleOpened(false)">Отмена</AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            block
            type="primary"
            size="large"
            :loading="isEditingButtonDisabled"
            @click="submitAction"
          >
            Сохранить
          </AButton>
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
