<script setup lang="ts">
import { computed, onMounted, type PropType, ref, watch } from "vue"
import { useToggle } from "@vueuse/core"
import { TreeSelect } from "ant-design-vue"
import { push } from "notivue"
import { isArray } from "lodash-es"

import { usePopupContainerGetter } from "@/package/ui-kit"
import { ApiNotFoundResponse } from "@/package/api-client"
import type { BankAccountSelectVariant } from "@/modules/bank-account"
import {
  CreateBankAccountForm,
  useBankAccountStore,
  useBankAccountVariantsTree
} from "@/modules/bank-account"

const props = defineProps({
  id: { type: Number, default: null },
  hasNoneOption: { type: Boolean, default: false },

  hideLink: {
    type: Boolean,
    default: false
  },

  excludedList: {
    type: Array as PropType<Array<number | string>>,
    default: () => []
  },

  excludedParent: {
    type: [String, Number] as PropType<number | string>,
    default: () => undefined
  },

  actionType: {
    type: String,
    default: undefined
  },

  disabled: {
    type: Boolean,
    default: false
  }
})

const selectedId = defineModel({
  type: [Number, Array] as PropType<Array<number> | number | null>
})
const { isListingLoading, requestListing, listing, idList } = useBankAccountVariantsTree()
const [isFormOpen, toggleFormOpen] = useToggle(false)
const [isSelectOpen, toggleSelectOpen] = useToggle(false)
const formRef = ref<InstanceType<typeof CreateBankAccountForm> | null>(null)

const store = useBankAccountStore()

const filteredOptions = computed(() =>
  listing.value.filter((bankAccount) => {
    if (props.excludedList.includes(bankAccount.id)) {
      return false
    }
    const hasExcludedChild = checkChildren(bankAccount.children)
    return !hasExcludedChild
  })
)
const checkChildren = (children: Array<BankAccountSelectVariant> | undefined) => {
  if (!children) return false
  for (const child of children) {
    if (props.excludedList.includes(child.id)) {
      return true
    }
    if (checkChildren(child.children)) {
      return true
    }
  }
  return false
}

interface MappedOption {
  name: string
  id: number
  selectable?: boolean
  children?: Array<MappedOption>
}

const options = computed(() => {
  if (isListingLoading && listing.value.length === 0) {
    return [{ label: "Загрузка...", value: -1 }]
  }

  const mapToOption = (item: BankAccountSelectVariant): MappedOption => ({
    name: item.name,
    id: item.id
  })

  const filteredListing = [...filteredOptions.value.filter((item) => item.active).map(mapToOption)]
  const unactiveAccounts = filteredOptions.value
    .filter((item) => item.active === false)
    .map(mapToOption)
  if (unactiveAccounts?.length > 0) {
    filteredListing.push({
      id: -2,
      name: "Неактивные",
      selectable: false,
      children: unactiveAccounts
    })
  }
  return props.hideLink
    ? filteredListing
    : [{ id: -1, name: "Создать новый счет" }, ...filteredListing]
})

const selectBankAccount = (id: number | Array<number>) => {
  if (id !== -1) {
    selectedId.value = id
    toggleSelectOpen(false)
    return
  }
  selectedId.value = null
}

watch(
  [selectedId, listing],
  async ([id]) => {
    if (isArray(id)) {
      return
    }

    if (id && id > -1 && !idList.value.includes(id) && listing.value.length) {
      try {
        await store.loadOne(id)
      } catch (e) {
        if (e instanceof ApiNotFoundResponse) {
          selectedId.value = undefined
        } else {
          push.error({ message: "Не удалось загрузить информацию о счету" })
        }
      }
    }
  },
  { deep: true, immediate: false }
)

const clearBankAccount = () => {
  selectedId.value = null
}

const getContainer = (): HTMLElement | null =>
  document.querySelector(
    ".bank-account-drawer .ant-drawer-body div[data-overlayscrollbars-contents]"
  )

const selectNewLegalBankAccount = async (newId: number, newName: string) => {
  listing.value.unshift({ id: newId, name: newName, active: true })
  selectedId.value = newId
  toggleFormOpen(false)
}

const createBankAccount = async () => {
  await formRef?.value?.submitAction()
}

const cancelCreateForm = () => {
  toggleFormOpen(false)
}

const openForm = () => {
  toggleSelectOpen(false)
  toggleFormOpen(true)
}

onMounted(requestListing)

defineOptions({
  inheritAttrs: false
})

const popupContainerGetter = usePopupContainerGetter()
const treeNodeFilter = (query: string, data: { id: number; name: string }) => {
  const trimmedQuery = query.trimEnd()
  const trimmedName = data.name.trimEnd()
  return trimmedName.toLowerCase().includes(trimmedQuery.toLowerCase())
}
</script>

<template>
  <AFlex :gap="8">
    <ATreeSelect
      v-bind="$attrs"
      v-model:open="isSelectOpen"
      :value="selectedId"
      popup-class-name="operation-category-tree-select"
      :tree-line="{ showLeafIcon: false }"
      allow-clear
      placeholder="Выберите счет"
      size="large"
      show-search
      tree-node-filter-prop="name"
      virtual
      :field-names="{ children: 'children', label: 'name', value: 'id' }"
      :filter-option="false"
      :tree-data="options"
      :loading="isListingLoading"
      tree-data-simple-mode
      :filter-tree-node="treeNodeFilter"
      :show-checked-strategy="TreeSelect.SHOW_ALL"
      :get-popup-container="popupContainerGetter"
      :disabled="props.disabled"
      @dropdownVisibleChange="toggleSelectOpen"
      @select="selectBankAccount"
      @clear="clearBankAccount"
    >
      <template #title="{ id: optionId, name }">
        <AButton
          v-if="!props.hideLink && optionId === -1"
          type="link"
          style="padding: 0; left: 0"
          @click="openForm"
          >Создать новый счет</AButton
        >
        <span v-else>{{ name }}</span>
      </template>
    </ATreeSelect>
    <APageDrawer
      v-if="isFormOpen"
      v-model:open="isFormOpen"
      title="Создание счета"
      class-name="bank-account-drawer"
    >
      <CreateBankAccountForm
        ref="formRef"
        :get-container="getContainer"
        @success="selectNewLegalBankAccount"
      />
      <template #footer>
        <ARow :gutter="[8, 8]">
          <ACol :sm="24" :md="12" flex="1 1 50%">
            <AButton size="large" block @click="cancelCreateForm">Отмена</AButton>
          </ACol>
          <ACol :sm="24" :md="12" flex="1 1 50%">
            <AButton
              size="large"
              block
              type="primary"
              :loading="formRef?.isCreating"
              @click="createBankAccount"
              >Создать</AButton
            >
          </ACol>
        </ARow>
      </template>
    </APageDrawer>
  </AFlex>
</template>

<style>
.operation-category-tree-select .ant-select-tree .ant-select-tree-indent-unit,
.operation-category-tree-select .ant-select-tree .ant-select-tree-switcher-leaf-line {
  opacity: 0;
}
</style>
