import type { SubscriptionEditLink } from "../interface"
import type { Ref } from "vue"

import { push } from "notivue"
import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const usePaySubscription = () => {
  const { execute, data, isLoading, error } = useAxios<SubscriptionEditLink>(
    "/subscription/url",
    { method: "GET" },
    client,
    {
      immediate: false
    }
  )

  const getSubscriptionPaymentLink = async (
    tariffId: number,
    isRecurrent: boolean,
    sbp: boolean,
    changePayment = false
  ) => {
    return await execute({
      url: "/subscription/url",
      params: {
        tariff_id: tariffId,
        is_recurrent: isRecurrent,
        sbp: sbp,
        change_payment: changePayment
      }
    })
  }
  const closeAfterApprove = async (open: Ref<boolean>) => {
    push.success({
      message: "Подписка успешно оплачена"
    })

    open.value = false
  }
  return {
    paymentLink: data,
    getSubscriptionPaymentLink,
    paymentLinkPending: isLoading,
    SubscriptionPaymentError: useApiError(error),
    closeAfterApprove
  }
}
