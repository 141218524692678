import { computed, type Ref, watch } from "vue"

import type { LegalEntityCreate, LegalEntityDetailView } from "@/modules/legal-entity"

import { useTurnover } from "./use-turnover"
import { useOkvedDetail } from "./use-okved-detail"
import { useCitizenshipCode } from "./use-citizenship-code"
import { useDocumentCode } from "./use-document-code"
import { useTaxpayerCategoryCode } from "./use-taxpayer-category-code"

export const useLegalEntityDetailView = (legalEntity: Ref<LegalEntityCreate | undefined>) => {
  const { data: turnoverData } = useTurnover()
  const { getDetail, okvedDetail } = useOkvedDetail()
  const { citizenshipCode, getCitizenshipCode } = useCitizenshipCode()
  const { documentCode, getDocumentCode } = useDocumentCode()

  const { taxpayerCategoryCode, getTaxpayerCategoryCode } = useTaxpayerCategoryCode()

  const legalEntityView = computed<LegalEntityDetailView | undefined>(() => {
    let result = undefined
    if (legalEntity.value && turnoverData.value) {
      const {
        monthly_turnover_rate,
        citizenship_code,
        document_type_code,
        taxpayer_category_code,
        ...profile
      } = legalEntity.value

      const citizenship = citizenshipCode?.value?.data.find(({ code }) => code === citizenship_code)

      const document = documentCode?.value?.data.find(({ code }) => code === document_type_code)

      const taxpayerCategory = taxpayerCategoryCode?.value?.data.find(
        ({ code }) => code === taxpayer_category_code
      )
      result = {
        ...profile,
        monthly_turnover_rate: turnoverData.value?.find(({ id }) => id === monthly_turnover_rate)
          ?.name,
        okved: okvedDetail.value
          ? `${okvedDetail.value.code} ${okvedDetail.value.name}`
          : undefined,
        citizenship_code: citizenship ? `${citizenship.code}. ${citizenship.name}` : undefined,
        document_type_code: document ? `${document.code}. ${document.description}` : undefined,
        taxpayer_category_code: taxpayerCategory
          ? `${taxpayerCategory.code}. ${taxpayerCategory.description}`
          : undefined
      }
    }
    return result
  })

  watch(legalEntity, (legalEntity) => {
    if (legalEntity) {
      if (!legalEntity.okved_id) {
        throw new Error("Ошибка логики у юр. лица нет оквэд-id" + JSON.stringify(legalEntity))
      }
      getDetail(legalEntity.okved_id)
      getCitizenshipCode()
      getDocumentCode()
      getTaxpayerCategoryCode()
    }
  })

  return { legalEntityView }
}
