<script setup lang="ts">
import { h, onMounted, onUnmounted, ref, watch } from "vue"
import { DownloadOutlined } from "@ant-design/icons-vue"
import { watchThrottled } from "@vueuse/core"

import { PaymentStatusSelect, usePaymentStore } from "@/modules/payment"
import { useAdoptable } from "@/package/ui-kit"

const exportData = () => {
  store.exportDownload()
}

const isAdoptable = useAdoptable()

const store = usePaymentStore()

watch(
  () => store.filters,
  () => {
    if (store.isFilteredReceived && !store.isGettingFilters) {
      store.saveFilters()
      store.applyFilters(store.filters)
    }
  },
  { deep: true, immediate: false }
)

onUnmounted(store.$reset)

const searchQuery = ref<string>("")

watchThrottled(
  searchQuery,
  (value) => {
    store.requestSearch(value)
  },
  { throttle: 1000, leading: false }
)

onMounted(store.getSavedData)
</script>

<template>
  <AFlex
    :gap="16"
    justify="space-between"
    align="flex-end"
    wrap="wrap"
    :style="{ marginTop: '32px' }"
  >
    <AFlex
      v-if="isAdoptable"
      vertical="false"
      :gap="16"
      justify="space-between"
      wrap="wrap"
      :style="{ width: '100%' }"
    >
      <AButton
        size="large"
        type="default"
        :icon="h(DownloadOutlined)"
        :style="{ flexGrow: 1, flexBasis: 'calc(50% - 16px)' }"
        @click="exportData"
      >
        Экспорт
      </AButton>
      <ACol>
        <PaymentStatusSelect
          v-model:value.null-on-empty="store.filters.status"
          :style="{ width: '100%' }"
          size="large"
        />
      </ACol>
    </AFlex>
    <AFlex
      v-else
      :gap="16"
      justify="space-between"
      wrap="wrap"
      :style="{ width: '100%', maxWidth: '400px' }"
    >
      <AButton AButton size="large" type="default" :icon="h(DownloadOutlined)" @click="exportData">
        Экспорт
      </AButton>
      <ACol>
        <PaymentStatusSelect
          v-model:value.null-on-empty="store.filters.status"
          :style="{ width: '200px' }"
          size="large"
        />
      </ACol>
    </AFlex>
    <AInputSearch
      v-model:value="searchQuery"
      size="large"
      placeholder="GUID, фамилия, имя, сумма платежа"
      :loading="store.isListLoading && searchQuery.length > 0"
      :style="{ width: '100%', maxWidth: '400px' }"
      @search="store.requestSearch"
    />
  </AFlex>
</template>
