import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const useExportDownload = () => {
  const { execute, isLoading, error } = useAxios(
    "/payment/export",
    { method: "POST", responseType: "blob" },
    client,
    {
      immediate: false
    }
  )

  const executeDownload = async () => {
    const response = await execute("/payment/export")
    const url = URL.createObjectURL(response.data.value)
    const filename =
      response.response.value?.headers?.["content-disposition"]
        .split('filename="')[1]
        .split('"')[0] || "file.xlsx"

    const link = document.createElement("a")
    link.href = url
    link.download = filename
    link.style.visibility = "hidden"
    link.style.position = "absolute"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return {
    executeDownload,
    isLoadingFile: isLoading,
    downloadReportError: useApiError(error)
  }
}
