import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const useOperationsDuplicate = () => {
  const { execute, isLoading, error, isFinished } = useAxios<{ success: boolean }>(
    "/operations/duplicate",
    { method: "POST" },
    client,
    { immediate: false }
  )

  const duplicateOperations = async (ids: Array<number>) => {
    const payload = {
      ids: ids
    }
    await execute("/operations/duplicate", { data: payload })
  }

  return {
    duplicateOperations,
    isDuplicating: isLoading,
    duplicateError: useApiError(error),
    isDuplicateFinished: isFinished
  }
}
