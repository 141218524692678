import { Modal } from "ant-design-vue"
import { noop } from "@vueuse/core"

interface Props
  extends Pick<
    Parameters<typeof Modal.confirm>[0],
    "content" | "okText" | "cancelText" | "onOk" | "centered" | "icon" | "title"
  > {}

export const SaveConfirm = ({
  content = "Вы действительно хотите внести изменения?",
  okText = "Да",
  cancelText = "Отмена",
  title = "Сохранить изменения",
  onOk = noop,
  centered = true,
  icon = null
}: Props = {}) => {
  const modal = Modal.confirm({
    autoFocusButton: null,
    class: "ant-modal-confirm--custom",
    maskClosable: true,
    title,
    icon,
    content,
    centered,

    onOk: async (...args) => {
      modal.update({
        keyboard: false,
        maskClosable: false,
        cancelButtonProps: { block: true, disabled: true }
      })
      await onOk(...args)
    },
    okText,
    okButtonProps: { block: true },

    cancelText,
    cancelButtonProps: { block: true }
  })
}
