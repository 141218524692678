export enum PaymentStatus {
  active = "active",
  inactive = "inactive"
}

export const PaymentStatusDict: Record<PaymentStatus, string> = {
  active: "Подтвержден",
  inactive: "Не подтвержден"
}

export interface PaymentListingData {
  id: number
  user_id: number
  first_name: string
  last_name: string
  middle_name: string
  amount: number
  status: PaymentStatus
  datetime: string
}

export interface PaymentFilters {
  status?: PaymentStatus | null
}
