<script setup lang="ts">
import { useToggle } from "@vueuse/core"

import { OperationActionInOut, OperationActionDict } from "@/modules/operation"

const [isOpened, toggleOpen] = useToggle(false)

const options = (
  Array.from(Object.keys(OperationActionInOut)) as Array<keyof typeof OperationActionInOut>
).map((key) => ({
  label: OperationActionDict[key],
  value: OperationActionInOut[key]
}))
</script>

<template>
  <ASelect
    v-model:open="isOpened"
    :options="options"
    placeholder="Выберите тип операции"
    @dropdownVisibleChange="toggleOpen"
    @focus="() => toggleOpen(true)"
  />
</template>
