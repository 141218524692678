<script setup lang="ts">
import type { SorterResult } from "ant-design-vue/es/table/interface"

import { ref } from "vue"
import { useInfiniteScroll } from "@vueuse/core"
import { storeToRefs } from "pinia"

import { useAdoptable } from "@/package/ui-kit"
import { usePaymentStore } from "@/modules/payment"
import { AdminPaymentsDetailRouteName } from "@/router"
import { useBufferSelection } from "@/package/hooks"

import { useColumns } from "./hooks/use-columns"

const isAdoptable = useAdoptable()
const { columns } = useColumns()
const store = usePaymentStore()
const { idList, paginationTotal } = storeToRefs(store)

const {
  isAllSelected,
  selectedCount,

  toggleAllSelected,
  unselectAllAction,
  currentModeKeys,
  excludeKeysMode
} = useBufferSelection(idList, paginationTotal)

const tableWrapper = ref<HTMLElement | null>(null)
const stickyConfig = {
  offsetHeader: 0,
  offsetScroll: 0,
  getContainer: () => tableWrapper.value
}
useInfiniteScroll(tableWrapper, store.loadBufferNextPage, {
  distance: 1000,
  interval: 2000,
  canLoadMore: () => !store.isLoadedFull && !store.isSortingLoading && store.isFilteredReceived
})

defineExpose({
  unselectAllAction,
  selectedCount,
  isAllSelected,
  toggleAllSelected,
  currentModeKeys,
  excludeKeysMode
})
</script>

<template>
  <div ref="tableWrapper" :style="{ overflow: 'auto', height: '100%' }">
    <ATable
      :size="isAdoptable ? 'middle' : 'large'"
      :sticky="stickyConfig"
      class="custom-table"
      :loading="store.isListLoading"
      :columns="columns"
      :data-source="store.listing"
      :show-header="!isAdoptable"
      :pagination="false"
      row-key="id"
      @change="
        async (pagination: {}, filters: {}, sorter: SorterResult) => {
          await store.setSortOrder(sorter)
          await store.savePaymentSorting()
        }
      "
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'last_name'">
          <ALink :to="{ name: AdminPaymentsDetailRouteName, params: { id: record.user_id } }">
            {{ record.last_name }}
          </ALink>
        </template>
      </template>
      <template #emptyText>
        <AEmptyListingPlaceholder />
      </template>
    </ATable>
  </div>
</template>

<style scoped>
.custom-table {
  max-width: calc(100% - 2px);
}

.custom-table:deep(.ant-table-wrapper),
.custom-table:deep(.ant-spin-container),
.custom-table:deep(.ant-table-container),
.custom-table:deep(.ant-spin-nested-loading),
.custom-table:deep(.ant-table-content table) {
  height: 100%;
}

.custom-table:deep(.ant-spin-container) {
  display: flex;
  flex-direction: column;
}
</style>
