<script setup lang="ts">
import { UserPasswordStatus, UserPasswordStatusDict } from "@/modules/admin"

const options = (
  Object.keys(UserPasswordStatus).filter(
    (k) => typeof UserPasswordStatus[k as any] === "number"
  ) as Array<keyof typeof UserPasswordStatus>
).map((key) => ({
  value: UserPasswordStatus[key],
  label: UserPasswordStatusDict[UserPasswordStatus[key]]
}))
</script>

<template>
  <ASelect :options="options" placeholder="Выберите статус пользователя" />
</template>
