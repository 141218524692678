export interface OffsetPaginationDto {
  limit: number
  offset: number
  count: number
  total: number
}

export interface OffsetPaginationDefaultDto {
  limit: number
  offset: number
  count: number | null
  total: number | null
  total_all_count?: number
}

export const PER_PAGE_DEFAULT = 100

export const PAGINATION_DEFAULT_STATE: OffsetPaginationDefaultDto = {
  limit: PER_PAGE_DEFAULT,
  offset: 0,
  total: null,
  count: null
}

export const createPaginationDefaultState = () => Object.assign({}, PAGINATION_DEFAULT_STATE)

export enum SortOrder {
  ASC = "asc",
  DESC = "desc"
}
export enum SortOrderInternal {
  ASCEND = "ascend",
  DESCEND = "descend"
}
