<script setup lang="ts">
import { PlusOutlined } from "@ant-design/icons-vue"
import { useRouter } from "vue-router"
import { computed, h, onUnmounted, ref, watch } from "vue"
import { push } from "notivue"
import { storeToRefs } from "pinia"

import { SubscriptionReminder } from "@/modules/subscription-reminder"
import {
  LegalProfileCreateRouteName,
  LegalProfileDetailRouteName,
  LegalProfileEditRouteName
} from "@/router"
import { LegalEntityListing, useLegalEntityStore } from "@/modules/legal-entity"
import { useAdoptable } from "@/package/ui-kit"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { injectProfile, useProfileSubscription } from "@/modules/profile"

const profile = injectProfile()
const router = useRouter()
const listingRef = ref<InstanceType<typeof LegalEntityListing> | null>(null)
const store = useLegalEntityStore()
const { legalEntityListDeleteError, isListingLoading, legalEntityDeleteError } = storeToRefs(store)

watch(legalEntityListDeleteError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

watch(legalEntityDeleteError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const openCreatePage = () => router.push({ name: LegalProfileCreateRouteName })
onUnmounted(store.$reset)
const isAdoptable = useAdoptable()

const isSubscriptionExpired = computed(() => useProfileSubscription(profile?.value).value)
</script>

<template>
  <SubscriptionReminder :style="{ marginTop: '32px', marginBottom: '0' }" />
  <ARow justify="space-between" :style="{ marginTop: '32px' }">
    <ACol :xs="24" :md="12">
      <AFlex :gap="16" justify="space-between" :style="{ width: '100%' }">
        <template v-if="!isAdoptable">
          <AButton
            default-styling
            size="large"
            type="primary"
            :disabled="isSubscriptionExpired"
            @click="openCreatePage"
          >
            Создать
          </AButton>
        </template>
      </AFlex>
    </ACol>
  </ARow>
  <ASpin :spinning="isListingLoading">
    <LegalEntityListing
      ref="listingRef"
      :style="{ flexGrow: 1, marginTop: '16px' }"
      :disabled="isSubscriptionExpired"
      @openDetail="(id) => router.push({ name: LegalProfileDetailRouteName, params: { id } })"
      @openEdit="(id) => router.push({ name: LegalProfileEditRouteName, params: { id } })"
      @openCreate="() => router.push({ name: LegalProfileCreateRouteName })"
    />
  </ASpin>
  <AListingControlPanel v-if="isAdoptable">
    <AButton size="large" :icon="h(PlusOutlined)" type="primary" @click="openCreatePage" />
  </AListingControlPanel>
  <RouterView />
</template>
