import type { SorterResult } from "ant-design-vue/es/table/interface"

import { defineStore } from "pinia"
import { computed, type MaybeRef, reactive, readonly, ref, watch } from "vue"
import { get, set, useDebounceFn, useToggle } from "@vueuse/core"

import type { PaymentListingData, PaymentFilters } from "@/modules/payment"
import {
  createPaginationDefaultState,
  type OffsetPaginationDefaultDto,
  PAGINATION_DEFAULT_STATE,
  PER_PAGE_DEFAULT,
  SortOrder
} from "@/interfaces"
import { useListBufferApi, useListingSort } from "@/package/hooks"
import { getOrderBySorter } from "@/package/ui-kit"

import { usePaymentList } from "../hooks/use-payment-list"
import { useSaveFilters } from "../hooks/use-save-filters"
import { useGetFilters } from "../hooks/use-get-filters"
import { useGetPaymentSorting } from "../hooks/use-get-payment-sorting"
import { useSavePaymentSorting } from "../hooks/use-save-payment-sorting"
import { useExportDownload } from "../hooks/use-export-download"

export const usePaymentStore = defineStore("PaymentAdminStore", () => {
  const buffer = ref<PaymentListingData[]>([])
  const idList = computed(() => buffer.value.map(({ id }) => id))
  const filters = ref<PaymentFilters>({})
  const isFilterExists = computed(() => Object.keys(get(filters)).length > 0)

  const pagination = reactive<OffsetPaginationDefaultDto>(createPaginationDefaultState())
  const searchQuery = ref<string>()
  const searchQueryRO = readonly(searchQuery)
  const { sortBy, sortOrder, setSort, resetSort } = useListingSort()

  const currentOffset = computed(() => pagination.offset ?? 0)
  const paginationTotal = computed(() => pagination.total ?? 0)
  const isLoadedFull = computed(
    () => pagination.total !== null && pagination.total <= buffer.value.length
  )

  const { bufferRO, setUpdateFn, bufferConcat, bufferRewrite, updateBufferFn } =
    useListBufferApi(buffer)

  const {
    isListLoading,
    requestPaymentList,
    paymentListingResponse,
    listingLoadingErrorMessage,
    listingErrorMessage
  } = usePaymentList()

  watch(paymentListingResponse, (data) => {
    if (data) {
      updateBufferFn(data.data)
      Object.assign(pagination, data.pagination)
    }
  })

  watch(listingErrorMessage, (errorMessage) => {
    const errorWithStatus = errorMessage as { STATUS: number }
    if (errorWithStatus?.STATUS === 404) {
      updateBufferFn([])
    }
  })

  const reloadBuffer = async () => {
    setUpdateFn(bufferRewrite)
    await requestPaymentList({
      offset: 0,
      limit: buffer.value.length,
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy),
      ...get(filters)
    })
  }

  const loadBufferNextPage = useDebounceFn(async () => {
    setUpdateFn(bufferConcat)
    await requestPaymentList({
      offset: buffer.value.length,
      limit: PER_PAGE_DEFAULT,
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy),
      ...get(filters)
    })
  }, 100)

  const requestSearch = async (requestedQuery: MaybeRef<string>) => {
    Object.assign(pagination, PAGINATION_DEFAULT_STATE)
    set(searchQuery, get(requestedQuery))
    setUpdateFn(bufferRewrite)
    await requestPaymentList({
      offset: pagination.offset,
      limit: pagination.limit,
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy),
      ...get(filters)
    })
  }

  const applyFilters = async (filersData: MaybeRef<PaymentFilters>) => {
    set(filters, get(filersData))
    setUpdateFn(bufferRewrite)
    await requestPaymentList({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy),
      ...get(filters)
    })
  }

  const { executeSave: executeSaveFilters, isFiltersSaved } = useSaveFilters()

  const saveFilters = async () => {
    await executeSaveFilters(filters.value)
  }

  const { getFilters, isFilteredReceived, isGettingFilters } = useGetFilters()

  const getSavedFilters = async () => {
    const response = await getFilters()
    set(filters, get(response.data.value?.data ? response.data.value?.data : {}))
  }

  const setSortOrder = async (sorter: SorterResult) => {
    const orderBy = getOrderBySorter(sorter)
    if (orderBy !== null && typeof sorter.field === "string") {
      setSort(sorter.field, orderBy)
    } else {
      resetSort()
    }
    setUpdateFn(bufferRewrite)
    await requestPaymentList({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy),
      ...get(filters)
    })
  }

  const { getSorting, isSortingLoading } = useGetPaymentSorting()

  const getPaymentsSorting = async () => {
    const response = await getSorting()
    if (response?.sortBy && typeof response?.sortOrder === "string") {
      setSort(response?.sortBy, response?.sortOrder as SortOrder)
    } else {
      resetSort()
    }
  }

  const getSavedData = async () => {
    await getPaymentsSorting()
    await getSavedFilters()
  }

  const { executeSave: executeSaveSorting } = useSavePaymentSorting()

  const savePaymentSorting = async () => {
    await executeSaveSorting({ sortBy: sortBy.value, sortOrder: sortOrder.value })
  }

  const $reset = () => {
    Object.assign(pagination, PAGINATION_DEFAULT_STATE)
    searchQuery.value = undefined
    buffer.value = []
    set(filters, {})
    resetSort()
  }

  const [isFileImporting, toggleImportStatus] = useToggle(false)
  const { executeDownload, downloadReportError, isLoadingFile } = useExportDownload()
  const exportDownload = async () => {
    await executeDownload()
  }
  return {
    idList,
    listing: bufferRO,
    isLoadedFull,
    downloadReportError,
    isLoadingFile,
    paginationTotal,
    currentOffset,

    searchQuery: searchQueryRO,
    requestSearch,
    loadBufferNextPage,
    listingLoadingErrorMessage,
    isListLoading,

    filters,
    isFilterExists,
    isFiltersSaved,
    isFilteredReceived,
    isGettingFilters,
    saveFilters,
    applyFilters,
    getSavedFilters,
    getSavedData,
    getPaymentsSorting,
    sortOrder,
    sortBy,
    isSortingLoading,
    savePaymentSorting,
    setSortOrder,
    isFileImporting,
    toggleImportStatus,
    reloadBuffer,
    $reset,
    exportDownload
  }
})
