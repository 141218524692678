import type { AliasToken } from "ant-design-vue/es/theme/internal"

import { theme } from "ant-design-vue"

export const themingDefaults: Partial<AliasToken> = {
  fontFamily: `Roboto, ${theme.defaultConfig.token.fontFamily}`,

  colorTextSecondary: "rgba(22, 22, 21, 0.60)",
  colorPrimary: "#0057ff",
  colorBorder: "#ececec",
  colorInfo: "#0057ff",
  colorLink: "#0057ff",
  fontSizeHeading4: 18,

  screenXXL: 1920,
  screenXXLMin: 1920,
  screenXL: 1440,
  screenXLMax: 1919,
  screenXLMin: 1440,
  screenLG: 1240,
  screenLGMax: 1439,
  screenLGMin: 1240,
  screenMD: 1024,
  screenMDMin: 1024,
  screenMDMax: 1239,
  screenSM: 768,
  screenSMMin: 768,
  screenSMMax: 1023,
  screenXS: 435,
  screenXSMax: 767,
  screenXSMin: 435
}
