<script setup lang="ts">
import type { SorterResult } from "ant-design-vue/es/table/interface"

import { h, onBeforeMount, onUnmounted, ref } from "vue"
import { DashOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons-vue"
import { noop, useInfiniteScroll } from "@vueuse/core"
import { type TableProps } from "ant-design-vue"
import { push } from "notivue"
import { storeToRefs } from "pinia"

import type { Counterparty } from "@/modules/counterparty"
import { DeleteConfirm, useAdoptable } from "@/package/ui-kit"
import { useProjectsStore } from "@/modules/project"
import { useBufferSelection } from "@/package/hooks"

import { useColumns } from "./use-columns"

const emit = defineEmits(["openDetail", "openEdit", "openCreate"])
const { columns } = useColumns()
const isAdoptable = useAdoptable()
const store = useProjectsStore()
const { idList, paginationTotal } = storeToRefs(store)

const {
  isAllSelected,
  selectedCount,
  selectedIdList,
  onSelect,
  onSelectAll,
  toggleAllSelected,
  unselectAllAction,
  currentModeKeys,
  excludeKeysMode
} = useBufferSelection(idList, paginationTotal)

const rowSelectConfig = {
  columnWidth: 32,
  onChange: onSelect,
  preserveSelectedRowKeys: true,
  selectedRowKeys: selectedIdList,
  onSelectAll
}

const customRow: TableProps["customRow"] = (data: Counterparty) => ({
  onClick: () => {
    emit("openDetail", data.id)
  }
})

const tableWrapper = ref<HTMLElement | null>(null)
const stickyConfig = {
  offsetHeader: 0,
  offsetScroll: 0,
  getContainer: () => tableWrapper.value
}
useInfiniteScroll(tableWrapper, () => store.loadNextPart(), {
  distance: 1000,
  interval: 2000,
  canLoadMore: () =>
    store.listingApiError === null && !store.isLoadedFull && !store.isSortingLoading
})

const deleteAction = (id: number) => {
  DeleteConfirm({
    content: "Вы действительно хотите безвозвратно удалить запись?",
    centered: true,
    onOk: () => {
      store
        .deleteProject(id)
        .then(() => {
          push.success({ message: "Удалено" })
        })
        .catch(noop)
    }
  })
}

onBeforeMount(async () => {
  await store.getProjectSorting()
})

defineExpose({
  isAllSelected,
  selectedCount,
  toggleAllSelected,
  unselectAllAction,
  currentModeKeys,
  excludeKeysMode
})

onUnmounted(store.$reset)
</script>

<template>
  <div ref="tableWrapper" :style="{ overflow: 'auto', height: '100%', scrollbarGutter: 'stable' }">
    <ATable
      :size="isAdoptable ? 'middle' : 'large'"
      :sticky="stickyConfig"
      class="custom-table"
      :loading="store.isListingLoading"
      :columns="columns"
      :data-source="store.listingBuffer"
      :show-header="!isAdoptable"
      :row-selection="rowSelectConfig"
      :pagination="false"
      row-key="id"
      :custom-row="customRow"
      :show-sorter-tooltip="false"
      @change="
        async (pagination: {}, filters: {}, sorter: SorterResult) => {
          await store.setSortOrder(sorter)
          await store.saveProjectSorting()
        }
      "
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'action'">
          <ADropdown
            :overlay-style="{ zIndex: 100 }"
            placement="bottomRight"
            arrow
            :trigger="['hover']"
          >
            <AButton :icon="h(DashOutlined)" type="text" @click.stop />
            <template #overlay>
              <AMenu>
                <AMenuItem
                  :icon="h(EditOutlined)"
                  @click="() => emit('openEdit', (record as Counterparty).id)"
                >
                  Редактировать
                </AMenuItem>
                <AMenuItem
                  danger
                  :disabled="store.isDeleting"
                  :icon="h(DeleteOutlined)"
                  @click="() => deleteAction((record as Counterparty).id)"
                >
                  Удалить
                </AMenuItem>
              </AMenu>
            </template>
          </ADropdown>
        </template>
      </template>
      <template #emptyText>
        <AEmptyListingPlaceholder
          create-text="Создать проект"
          :show-create-button="store.isResourceEmpty || !store.pagination.total"
          @openCreate="() => emit('openCreate')"
        />
      </template>
    </ATable>
  </div>
</template>

<style scoped>
.custom-table {
  max-width: calc(100% - 2px);
}

.custom-table:deep(.ant-table-wrapper),
.custom-table:deep(.ant-spin-container),
.custom-table:deep(.ant-table-container),
.custom-table:deep(.ant-spin-nested-loading),
.custom-table:deep(.ant-table-content table) {
  height: 100%;
}

.custom-table:deep(.ant-spin-container) {
  display: flex;
  flex-direction: column;
}
.custom-table:deep(.ant-table-container)::after,
.custom-table:deep(.ant-table-container)::before {
  content: none !important;
}
</style>
